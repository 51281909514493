import XLSX from "xlsx";
import {
  sortName,
  convertUTCDateTo24HLocalDate,
  CalculateUTCTzToUserTz,
} from "../../../utils/globalMethods";

import {
  monthsOfOperation,
  daysOfOperation,
} from "../../../constants/constants";
import moment from "moment";

const dateFormat = (timestamp) => {
  return timestamp ? convertUTCDateTo24HLocalDate(timestamp) : "";
};
const filterDay = (dayValue) => {
  let tempData = daysOfOperation.filter((data) => data.weekDay === dayValue);

  if (tempData.length > 0) {
    return tempData[0].values;
  } else {
    return [];
  }
};

export const ExportExcel = (csvData, fileName, columnWidth = []) => {
  const wb = XLSX.utils.book_new();
  csvData.forEach((data) => {
    let ws = XLSX.utils.aoa_to_sheet(data.data);
    if (columnWidth.length) {
      ws["!cols"] = columnWidth;
    }
    XLSX.utils.book_append_sheet(wb, ws, data.sheetName);
  });

  XLSX.writeFile(wb, fileName + ".xlsx");
};

export const ExportFarmersMarket = (farmersMarket) => {
  let tdata = [
    [
      "Id",
      "Market Name",
      "Address",
      "City",
      "State",
      "Zipcode",
      "Phone Number",
      "Website",
      "Description",
      "Created Time",
      "Last Updated Time",
    ],
  ];
  sortName(farmersMarket, "name").forEach((ele) => {
    tdata.push([
      ele.id,
      ele.name,
      ele.addressDetail.addressLine1,
      ele.addressDetail.city,
      ele.addressDetail.state,
      ele.addressDetail.zipcode,
      ele.phone,
      ele.webAddress,
      ele.description,
      dateFormat(ele.createTs),
      dateFormat(ele.lastUpdtTs),
    ]);
  });

  const tableData = [
    {
      sheetName: "FarmersMarket",
      data: tdata,
    },
  ];
  const filename = "Farmers Market";
  const columnWidth = [
    { wch: 5 },
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 10 },
    { wch: 10 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};

export const ExportVendorReport = (vendorReport) => {
  let tdata = [
    [
      "Farm Name",
      "First/Last Name",
      "Federal IDr",
      "Primary Phone",
      "Primary Email",
      "FEP Organization ID",
      "Additional Contact Info",
      "Public Phone",
      "Registered Date/Time",
      "Vendor Last Updated Time",
      "Staff Last Updated Date/Time",
      "Farmers' Market Booth",
      "Farmers' Market",
      "CSA Program",
      "Farm Stand",
      "Mobile Market Stop",
    ],
  ];
  sortName(vendorReport, "vendorName").forEach((ele) => {
    tdata.push([
      ele.vendorName,
      ele.firstName + " " + ele.lastName,
      ele.fnsNum,
      ele.primaryMobile,
      ele.primaryEmail,
      ele.vendorHipId,
      ele.additionalContactInfo,
      ele.publicMobile,
      dateFormat(ele.createTs),
      dateFormat(ele.lastUpdtTs),
      dateFormat(ele.adminLastUpdtTs),
      ele.FarmersMarketBooth,
      ele.FarmersMarket,
      ele.csaProgram,
      ele.FarmStand,
      ele.MobileMarketStop,
    ]);
  });

  const tableData = [
    {
      sheetName: "Verified Vendor(s)",
      data: tdata,
    },
  ];
  const filename = "Verified Vendor(s)";
  const columnWidth = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};

export const ExportVendorLastUpdateReport = (vendorLastUpdateReport) => {
  let tdata = [
    [
      "Farm Name",
      "First/Last Name",
      "Federal ID",
      "Primary Phone",
      "Primary Email",
      "FEP Organization ID",
      "Additional Contact Info",
      "Public Phone",
      "Registered Date/Time",
      "Vendor Last Updated Time",
      "Farmers' Market Booth",
      "Farmers' Market",
      "CSA Program",
      "Farm Stand",
      "Mobile Market Stop",
    ],
  ];
  sortName(vendorLastUpdateReport, "vendorName").forEach((ele) => {
    tdata.push([
      ele.vendorName,
      ele.firstName + " " + ele.lastName,
      ele.fnsNum,
      ele.primaryMobile,
      ele.primaryEmail,
      ele.vendorHipId,
      ele.additionalContactInfo,
      ele.publicMobile,
      dateFormat(ele.createTs),
      dateFormat(ele.lastUpdtTs),
      ele.FarmersMarketBooth,
      ele.FarmersMarket,
      ele.csaProgram,
      ele.FarmStand,
      ele.MobileMarketStop,
    ]);
  });

  const tableData = [
    {
      sheetName: "VendosLastUpdatedTime90Days",
      data: tdata,
    },
  ];
  const filename = "Vendor(s) Last Updated Time(90 Days)";
  const columnWidth = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};

export const ExportVendorLoginReport = (vendorLoginReport) => {
  let tdata = [
    [
      "First Name",
      "Last Name",
      "E-Mail",
      "HIP Organization ID",
      "Vendor Last Login Time",
    ],
  ];
  sortName(vendorLoginReport, "firstName").forEach((ele) => {
    tdata.push([
      ele.firstName,
      ele.lastName,
      ele.primaryEmailAddr,
      ele.vendorHipId,
      dateFormat(ele.lastUpdtTs),
    ]);
  });

  const tableData = [
    {
      sheetName: "Vendor(s) Last Login (30 days)",
      data: tdata,
    },
  ];
  const filename = "Vendor(s) Last Login (30 days)";
  const columnWidth = [
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
    { wch: 15 },
    { wch: 25 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};

export const ExportStoresReport = (report) => {
  let tdata1 = [
    [
      "Market Name",
      "Vendor Name",
      "Type",
      "Regular Hour(s) of Operation*",
      "Month(s) of Operation",
      "Contact",
      "Email",
      "Address",
      "Zip Code",
    ],
  ];
  let tdata2 = [
    [
      "Market Name",
      "Vendor Name",
      "Type",
      "Status",
      "Week",
      "Day(s)",
      "Month(s)",
      "Start Time",
      "End Time",
    ],
  ];

  sortName(report, "vendorName").forEach((ele) => {
    const months = monthsOfOperation
      .map((month) => {
        return ele.store.storeOperationMonth[month.id] === "Y" && month.values;
      })
      .filter((month) => month);

    const days = ele.store.storehours
      .map((item) => {
        return item.isWorkingDay === "Y" && item.weekdayDescription;
      })
      .filter((day) => day);

    // const address = ((ele.store.addressDetail.addressLine1 ? ele.store.addressDetail.addressLine1 : "") +" "+
    //                     ele.store.addressDetail.addressLine2 +" "+ele.store.addressDetail.city).trim();
    const address = ele?.address;
    const zipcode = ele?.zipcode ? ele?.zipcode : "-"; //ele.store.addressDetail.zipcode ? ele.store.addressDetail.zipcode : "-";
    const siteName =
      ele.store.storeType == "HIP CSA PROGRAM" ? "" : ele.store.siteName;

    tdata1.push([
      siteName,
      ele.vendorName,
      ele.store.storeType,
      days.join(" "),
      months.join(" "),
      ele.publicMobile,
      ele.publicEmail,
      address,
      zipcode,
    ]);

    if (ele.store.specialOperationFlag == "Y") {
      ele.store.storeSpecialOperation.forEach((spe) => {
        const speDays = daysOfOperation
          .map((day) => {
            return spe.dayInfo.split(",").includes(day.weekDay) && day.values;
          })
          .filter((day) => day);
        const speMonth = monthsOfOperation
          .map((month) => {
            return (
              spe.monthInfo.split(",").includes(month.month) && month.values
            );
          })
          .filter((month) => month);
        let week = spe.weekInfo;
        let text = "th";

        if (week === "1") {
          text = "st";
        } else if (week === "2") {
          text = "nd";
        } else if (week === "3") {
          text = "rd";
        }

        let weeks = week + text;
        if (weeks == "") {
          weeks = "-";
        }
        const speSiteName =
          ele.store.storeType == "HIP CSA PROGRAM" ? "" : ele.store.siteName;

        tdata2.push([
          speSiteName,
          ele.vendorName,
          ele.store.storeType,
          spe.specialStatusDescription,
          weeks,
          speDays.join(" "),
          speMonth.join(" "),
          spe.startTime,
          spe.endTime,
        ]);
      });
    }
  });

  tdata1.push(["", "", "", "", "", "", "", ""]);
  tdata1.push(["", "", "", "", "", "", "", ""]);

  tdata1.push(["* Note: Refer to Special Hours of Operation on next tab"]);

  const tableData = [
    {
      sheetName: "Verified Vendor(s)",
      data: tdata1,
    },
    {
      sheetName: "Spl Hrs of Operation",
      data: tdata2,
    },
  ];
  const filename = "Verified Vendor(s)";
  const columnWidth = [
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};
export const ExportClientPartialSignup = (partialSignup) => {
  let tdata = [
    [
      "Vendor Name",
      "Client Name",
      "Client Email",
      "Share Name",
      "Date of Signup",
      "Cost Of Share",
      "Client Phone",
      "Last Updated Time",
    ],
  ];
  sortName(partialSignup, "vendorName").forEach((element) => {
    tdata.push([
      element.vendorName,
      element.clientNam,
      element.clientEmail,
      element.shareInfo.nam,
      moment(element.submittedDate).format("MM/DD/YYYY"),
      "$" + element.totalAmnt,
      element.phoneNumber,
      dateFormat(element.lastUpdtTs),
    ]);
  });

  const tableData = [
    {
      sheetName: "PartialSignup",
      data: tdata,
    },
  ];
  const filename = "Partial Signup";
  const columnWidth = [
    { wch: 15 },
    { wch: 20 },
    { wch: 15 },
    { wch: 15 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};
export const ExportClientPaymentList = (paymentList) => {
  let tdata = [
    [
      "Client Name",
      "Client Email",
      "Share Name",
      "Vendor Name",
      "Pickup/Delivery Location",
      "Payment Status",
      "Amount",
      "Share Start Date",
      "Share End Date",
      "Scheduled Payment Date",
      "Processed Date",
      "Client Phone",
      "Last Updated Time",
    ],
  ];
  sortName(paymentList, "Client Name").forEach((element) => {
    const pickupLocation = [
      element?.shareInfo?.pickupInfo?.address1 ??
        element?.shareInfo?.deliveryInfo?.address1,
      element?.shareInfo?.pickupInfo?.address2 ??
        element?.shareInfo?.deliveryInfo?.address2,
      element?.shareInfo?.pickupInfo?.city ??
        element?.shareInfo?.deliveryInfo?.city,
      element?.shareInfo?.pickupInfo?.state ??
        element?.shareInfo?.deliveryInfo?.state,
      element?.shareInfo?.pickupInfo?.zipcode ??
        element?.shareInfo?.deliveryInfo?.zipcode,
    ]
      .filter(Boolean)
      .join(", ");

    // tdata.push([element.clientNam, element.clientEmail, element.shareNam, element.vendorName, pickupLocation, element.paymentAmount,dateFormat(element.paymentDate),element.phoneNumber,dateFormat(element.lastUpdtTs) ])
    let paymentStatus = "Scheduled";
    if (element.paymentStatus == "SUCESS") {
      paymentStatus = "Successful";
    } else if (element.paymentStatus == "REJCTD") {
      paymentStatus = "Rejected";
    } else if (element.paymentStatus == "CANCLD") {
      paymentStatus = "Cancelled";
    }
    if (element?.paymentScheduleList) {
      element?.paymentScheduleList?.forEach((item) => {
        tdata.push([
          element.clientNam,
          element.clientEmail,
          element.shareNam,
          element.vendorName,
          pickupLocation,
          paymentStatus,
          "$" + item.paymentAmount,
          CalculateUTCTzToUserTz(element.startDate),
          CalculateUTCTzToUserTz(element.endDate),
          CalculateUTCTzToUserTz(item.paymentSchdulDate),
          CalculateUTCTzToUserTz(element.processedDate),
          element.phoneNumber,
          dateFormat(element.lastUpdtTs),
        ]);
      });
    } else {
      tdata.push([
        element.clientNam,
        element.clientEmail,
        element.shareNam,
        element.vendorName,
        pickupLocation,
        paymentStatus,
        "$" + (element?.paymentAmount ? element?.paymentAmount : 0),
        CalculateUTCTzToUserTz(element.startDate),
        CalculateUTCTzToUserTz(element.endDate),
        element?.paymentSchdulDate
          ? CalculateUTCTzToUserTz(element.paymentSchdulDate)
          : "",
        element?.processedDate
          ? CalculateUTCTzToUserTz(element?.processedDate)
          : "",
        element.phoneNumber,
        dateFormat(element.lastUpdtTs),
      ]);
    }
  });
  const tableData = [
    {
      sheetName: "Payments",
      data: tdata,
    },
  ];
  const filename = "Payments";
  const columnWidth = [
    { wch: 20 },
    { wch: 25 },
    { wch: 25 },
    { wch: 35 },
    { wch: 35 },
    { wch: 20 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 45 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};
export const ExportCsaAgreementList = (signupStatus) => {
  let tdata = [
    [
      "Client Name",
      "Share Name",
      "Amount",
      "CSA Signup Status",
      "Signup Submitted Date",
      "Agreement Id",
      "Email",
      "Phone",
      "Approved/Rejected/Cancelled By",
      "Pickup/Delivery Location",
      "Pickup Day/Time",
      "Description",
    ],
  ];
  sortName(signupStatus, "clientNam").forEach((element) => {
    const pickupLocation = [
      element?.shareInfo?.pickupInfo?.address1 ??
        element?.shareInfo?.deliveryInfo?.address1,
      element?.shareInfo?.pickupInfo?.address2 ??
        element?.shareInfo?.deliveryInfo?.address2,
      element?.shareInfo?.pickupInfo?.city ??
        element?.shareInfo?.deliveryInfo?.city,
      element?.shareInfo?.pickupInfo?.state ??
        element?.shareInfo?.deliveryInfo?.state,
      element?.shareInfo?.pickupInfo?.zipcode ??
        element?.shareInfo?.deliveryInfo?.zipcode,
    ]
      .filter(Boolean)
      .join(", ");

    const pickupDaysTimes = element?.shareInfo?.pickupInfo
      ? (
          (filterDay(element.shareInfo.pickupInfo?.day) ?? "-") +
          "," +
          (element.shareInfo.pickupInfo?.openTime ?? "") +
          " - " +
          (element.shareInfo.pickupInfo?.closeTime ?? " ")
        ).trim()
      : "";

    let apprvlStatus = "";
    if (element.apprvlStatus === "DRAFT") {
      apprvlStatus = "Pending";
    } else if (element.apprvlStatus === "APPRVD") {
      apprvlStatus = "Approved";
    } else if (element.apprvlStatus === "REJCTD") {
      apprvlStatus = "Rejected";
    } else if (element.apprvlStatus === "CANCLD") {
      apprvlStatus = "Cancelled";
    }
    tdata.push([
      element.clientNam,
      element.shareInfo.nam,
      "$" + element.shareInfo.shareCost,
      apprvlStatus,
      moment(element.submittedDate).format("MM/DD/YYYY"),
      element.agrmntAppId,
      element.clientEmail,
      element.phoneNumber,
      element.lastUpdtUidNam,
      pickupLocation,
      pickupDaysTimes,
      element.shareInfo.dsc,
    ]);
  });

  const tableData = [
    {
      sheetName: "CSA Agreement List",
      data: tdata,
    },
  ];
  const filename = "CSA Agreement List";
  const columnWidth = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 20 },
    { wch: 20 },
    { wch: 25 },
    { wch: 35 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 50 },
    { wch: 50 },
    { wch: 35 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};
export const ExportCsaSignupStatus = (signupStatus) => {
  let tdata = [
    [
      "Vendor Name",
      "Client Name",
      "Share Name",
      "Amount",
      "CSA Signup Status",
      "Signup Submitted Date",
      "Agreement Id",
      "Email",
      "Phone",
      "Approved/Rejected/Cancelled By",
      "Approved/Rejected/Cancelled Date",
      "Reason for cancel",
      "Signature",
      "Cancelled Date",
      "Pickup/Delivery Location",
      "Pickup Day/Time",
      "Description",
    ],
  ];
  sortName(signupStatus, "vendorName").forEach((element) => {
    const pickupLocation = [
      element?.shareInfo?.pickupInfo?.address1 ??
        element?.shareInfo?.deliveryInfo?.address1,
      element?.shareInfo?.pickupInfo?.address2 ??
        element?.shareInfo?.deliveryInfo?.address2,
      element?.shareInfo?.pickupInfo?.city ??
        element?.shareInfo?.deliveryInfo?.city,
      element?.shareInfo?.pickupInfo?.state ??
        element?.shareInfo?.deliveryInfo?.state,
      element?.shareInfo?.pickupInfo?.zipcode ??
        element?.shareInfo?.deliveryInfo?.zipcode,
    ]
      .filter(Boolean)
      .join(", ");

    const pickupDaysTimes = element?.shareInfo?.pickupInfo
      ? (
          (filterDay(element.shareInfo.pickupInfo?.day) ?? "-") +
          "," +
          (element.shareInfo.pickupInfo?.openTime ?? "") +
          " - " +
          (element.shareInfo.pickupInfo?.closeTime ?? " ")
        ).trim()
      : "";

    const pickupDT = pickupDaysTimes ? pickupDaysTimes : "";

    let apprvlStatus = "";
    if (element.apprvlStatus === "DRAFT") {
      apprvlStatus = "Pending";
    } else if (element.apprvlStatus === "APPRVD") {
      apprvlStatus = "Approved";
    } else if (element.apprvlStatus === "REJCTD") {
      apprvlStatus = "Rejected";
    } else if (element.apprvlStatus === "CANCLD") {
      apprvlStatus = "Cancelled";
    }

    tdata.push([
      element.vendorName,
      element.clientNam,
      element.shareInfo.nam,
      "$" + element.shareInfo.shareCost,
      apprvlStatus,
      element?.submittedDate
        ? moment(element.submittedDate).format("MM/DD/YYYY")
        : "",
      element.agrmntAppId,
      element.clientEmail,
      element.phoneNumber,
      element.lastUpdtUidNam,
      element.rejectedDate
        ? moment(element.rejectedDate).format("MM/DD/YYYY")
        : "",
      element?.cancelNotes,
      element?.cancelESign,
      element?.cancelDate
        ? moment(element?.cancelDate).format("MM/DD/YYYY")
        : "",
      pickupLocation,
      pickupDT,
      element?.shareInfo?.dsc,
    ]);
  });

  const tableData = [
    {
      sheetName: "CSA Signup Status",
      data: tdata,
    },
  ];
  const filename = "CSASignup Status";
  const columnWidth = [
    { wch: 20 },
    { wch: 20 },
    { wch: 20 },
    { wch: 15 },
    { wch: 20 },
    { wch: 20 },
    { wch: 15 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 25 },
    { wch: 30 },
    { wch: 30 },
    { wch: 30 },
    { wch: 40 },
    { wch: 40 },
    { wch: 30 },
  ];
  ExportExcel(tableData, filename, columnWidth);
};
