import React, { useState } from "react";
import {
  Container,
  Grid,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";

import FileUploadContent from "./FileUploadContent";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { documnetURL } from "../../pages/senior/constant";
import toast from "react-hot-toast";
import useLoader from "../../hooks/useLoader";

const DocumentUploadForm = ({ setDocOpen, payload, section }) => {
  const { openLoader, closeLoader } = useLoader();
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileButton, setFileButton] = useState(true);
  const [document, setDocument] = useState({
    documentTypeCd: "",
  });
  const dropDocument = [
    { name: "Income", value: "1" },
    { name: "Identity", value: "2" },
  ];
  // const onChangeHandler = (event) => {
  //   const { name, value } = event.target;
  //   setDocument((prev) => {
  //     return { ...prev, [name]: value };
  //   });
  // };
  const formik = useFormik({
    initialValues: {
      documentTypeCd: "",
      fileName: "",
    },
    // validationSchema: formvalidation,
    onSubmit: (values) => {
      if (section?.id === "farmersMarket") {
        handleClose();
      } else {
        handleSubmit(values);
      }
    },
  });
  const handleSubmit = async (values) => {
    const initialState = {
      docCat: "kfresh",
      docType: values.documentTypeCd,
      sourceSystem: "KFresh",
      fileName: values.fileName,
      fileLocation: "",
      file: selectedFile,
      ...payload,
    };
    const FilesPayload = (filePayload) => {
      const formData = new FormData();
      Object.keys(filePayload).map((key) => {
        formData.append(key, filePayload[key]);
      });
      // formData.append("docType", payload.docType);
      // formData.append("sourceCaseId", payload.sourceCaseId);

      return formData;
    };
    let filePayload = FilesPayload(initialState);

    try {
      openLoader("doc_detail_upload");
      const res = await axios({
        method: "post",
        url: `${documnetURL}/docControl/documentDetails/upload`,
        data: filePayload,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (res.status === 200) {
        toast.success("File Uploaded Successfully!");
      }
      if (typeof setDocOpen === "function") {
        setDocOpen(false);
      }
    } catch (error) {
      toast.error("File Uploading failed!");
    } finally {
      closeLoader("doc_detail_upload");
    }
  };
  const handleFileChange = (file) => {
    if (file) {
      if (file.type !== "application/pdf") {
        toast.error("Only PDF files are allowed.");
        setSelectedFile(null);
        return;
      }

      setSelectedFile(file);
      setFileButton(false);
      // formik.setFieldValue(`fileFormData`, file);
    } else {
      setSelectedFile(null);

      // formik.setFieldValue(`fileFormData`, "");
    }
  };
  // const handleReset = () => {
  //   setIsAdd(true);
  // };
  const handleClose = () => {
    setDocOpen(false);
  };

  return (
    <>
      <Container
        sx={{
          height: "auto",
          boxShadow: "0px 0px 2px 2px rgba(0,0,0,0.2)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px 10px 10px 0px",
        }}
        component="form"
        onSubmit={formik.handleSubmit}
      >
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <FileUploadContent
              handleChange={handleFileChange}
              selectedFile={selectedFile}
              // isAdd={isAdd}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth margin="normal">
              <InputLabel id="doc-type">Document Type</InputLabel>
              <Select
                labelId="doc-type"
                id="select-doc-type"
                label="Document Type"
                value={formik.values.documentTypeCd}
                name="documentTypeCd"
                // onChange={(event) => handeChangeCallApi(event)}
                onChange={formik.handleChange}
              >
                {(dropDocument || [])?.map((item) => {
                  return (
                    <MenuItem key={item.value} value={item.name}>
                      {item.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <TextField
              label="File Description"
              fullWidth
              margin="normal"
              name="fileName"
              value={formik.values.fileName}
              onChange={formik.handleChange}
            />
            <div style={{ float: "none", left: "0" }}>
              {/* <Button variant="outlined">Reset</Button> */}
              <Button
                variant="contained"
                style={{ marginTop: "10px" }}
                type="submit"
                disabled={fileButton}
              >
                Upload File
              </Button>
              <Button
                // className="okay-btn"
                variant="contained"
                onClick={handleClose}
                sx={{
                  mr: "2rem",
                  width: "8rem",
                  marginLeft: "30px",
                  marginTop: "7px",
                }}
              >
                Close
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

DocumentUploadForm.propTypes = {
  setDocOpen: PropTypes.bool,
  payload: PropTypes.object,
  initData: PropTypes.object,
  section: PropTypes.object,
};

export default DocumentUploadForm;
