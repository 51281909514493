import React , { useEffect, useState } from "react";
import { Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import {CustomButton} from "../common/components/customButton";
import TableComponent from "../common/components/table";
import PageHeaderComponent from "../common/components/pageHeader";
import PropTypes from "prop-types";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Geocode from "react-geocode";

import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import { getFarmersMarket, exportFarmersMarket, addFarmersMarket, editFarmersMarket, bulkUpdateFarmersMarket, deleteFarmersMarket, refreshKey } from "../../redux/actionTypes/administration";
import { GET_COUNTYCITY } from "../../redux/actionTypes";

import '../../styles/home.scss';
import ModalComponent from "../common/components/modal";
import FarmerMarketAddEditComponent from "./farmerMarketAddEdit";
import { popUpAlert, extractExcel, checkToken, convertUTCDateToLocalDate, removeSpecialCharacter } from "../../utils/globalMethods";
import SearchComponent from "../common/components/searchComponent";
import FarmerMarketDetails from "./farmerMarketDetails";
import { GOOGLE_KEY } from "../../config";
import { useNavigate } from "react-router-dom";

Geocode.setApiKey(GOOGLE_KEY);
Geocode.setLanguage("en");

function createData(id,marketName, address, lastUpdatedTime, zipcode, city, state, webAddress, phone, lastUpdtUidNam, description) {
  return {
      id,
      marketName,
      address,
      lastUpdatedTime:convertUTCDateToLocalDate(lastUpdatedTime),
      zipcode: zipcode,
      city,
      state,
      webAddress: webAddress,
      phone: phone,
      lastUpdtUidNam,
      description: description,
  };
}

const FarmerMarketComponent = (props) => {
  const navigate = useNavigate();
 
  const [farmersMarketTotal, setFarmersMarketTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [search, setSearch] = useState("");
  const [showLoader, setShowLoader] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [validated] = useState(false);
  const [onEdit, setOnEdit] = useState({});

  const handleSubmit = (data) => {
      const selectedCity = props.mapdata.city.find(item => item.id == data?.city?.id);
      const cityDtl = selectedCity.cityName+","+selectedCity.latitude+","+selectedCity.longitude;
      let payload = {
        "name": data?.marketName.trim(),
        "phone": data?.mobile?.replace(/\D/g, ''),
        "webAddress": data?.webAddress.trim(),
        "description": data?.description.trim(),
        "addressDetail": {
            "addressLine1": data?.address,
            "city": cityDtl && cityDtl.split(',')[0],
            "state": "MA",
            "zipcode": data?.zipcode,
            "latitude": cityDtl.split(',')[1],
            "longitude": cityDtl.split(',')[2]
        }
      }

      Geocode.fromAddress(data?.address +" "+cityDtl.split(',')[0]+" "+"MA "+ data?.zipcode).then(
        (response) => {
            if (response.status == "OK") {
                payload.addressDetail.latitude=response.results[0].geometry.location.lat;
                payload.addressDetail.longitude=response.results[0].geometry.location.lng;
                // the place where loc contains geocoded coordinates
            } else {
              console.log("Geocode was not successful for the following reason: " + response.status);
            }
            if(Object.keys(onEdit).length !== 0){
              payload["id"] = onEdit?.id
              payload["addressDetail"]["id"] = onEdit?.id
              props.editFarmersMarket(payload);
            }
            else{
              props.addFarmersMarket(payload);
            }
            setShowModal(false);
            setOnEdit({});
        },
        (error) => {
          console.error(error);
        }
      );
  };

  const handleNext = (page,rowSize) => {
    (size != rowSize) && setSize(rowSize);
    setFrom(rowSize*page);
  }

  const handleOnEditAction = (row) => {
    setOnEdit(row);
    setShowModal(true);
  }

  const handleDelete = (row) => {
    const payload = {
      "id":row.id
     }
    props.deleteFarmersMarket(payload);
  }

  const handleOnDeleteAction = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Farmer market will be removed from the application.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm:()=>handleDelete(row),
    });
  }

  const renderModal = () => {
    return (<FarmerMarketAddEditComponent 
      validated={validated}
      handleFormSubmit={handleSubmit}
      onEdit={Object.keys(onEdit).length !== 0}
      row={onEdit}
    />)
  }

  const renderDetails = (row) => {
    return(<FarmerMarketDetails 
    row = {row} 
    />)
  }

  useEffect(() => {
    const userInfo = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData"));
     if (props?.allowedRoles && userInfo && userInfo !== null && Object.keys(userInfo).length != 0) {
      const checkRole = props.allowedRoles.find((role) => userInfo?.Role.includes(role));
      if (!checkRole) {
        navigate("/kfreshfep");
        return;
      }
    }
    // const payload = {
    //   "name": search,
    //    "size": size,
    //    "from": from
    //  }
    // if(checkToken()){
    //   props.getFarmersMarket(payload);
    // }
     props.getCityList();
  }, [size,from,props.administration.refreshKey,search]);

  useEffect(() => {
    const { farmersMarket } = props.administration;
    let data = []
    farmersMarket.list.forEach(element => {
      data.push(createData(element.id,element.name, element.addressDetail.addressLine1, element.lastUpdtTs, element.addressDetail.zipcode, element.addressDetail.city, element.addressDetail.state, element.webAddress, element.phone, element.lastUpdtUidNam, element.description ))
    });
    setRows(data);
    setFarmersMarketTotal(farmersMarket.total);
    setShowLoader(farmersMarket.loading);
  }, [props.administration.farmersMarket]);


  const columns = [
    { id: 'details', label: 'Details' },
    { id: 'marketName', label: 'Market Name' ,
      cell: (item) => {
        return (<>
          {item?.marketName ? removeSpecialCharacter(item.marketName) : " "}
        </>)
      }
    },
    {
      id: 'address',
      label: 'Address',
    },
    {
      id: 'lastUpdatedTime',
      label: 'Last Updated Time',
      align: 'right',
    },
    {
      id: 'action',
      label: 'Action',
      cell: (item) => {
        return (<>
          <IconButton aria-label="delete" onClick={() => handleOnEditAction(item)}>
            <EditIcon />
          </IconButton>
          <IconButton aria-label="edit" onClick={() => handleOnDeleteAction(item)} >
            <DeleteIcon />
          </IconButton>
        </>)
      }
    },
  ];

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    setFrom(0);
  }

  const exportData = () => {
    props.exportFarmersMarket();
  }

  const uploadFile = (e) => {
    const fileList = e.target.files;
    
    if(fileList.length==0){
      popUpAlert({
        title: "Failed!",
        text: "File not found. select a file to start upload.",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Ok!",
        closeOnConfirm: false,
      });
    }
    const file = fileList[0];
    
    if(file.name.includes('xls') || file.name.includes('xlsx')){
      extractExcel(file,props.mapdata.city,props.bulkUpdateFarmersMarket);
    }else{
      popUpAlert({
        title: "Failed!",
        text: "Invalid file format. File should be in xls / xlsx format.",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "Ok!",
        closeOnConfirm: false,
      });
    }
  }

  const handleRefresh = () => {
    setShowLoader(true);
    props.refreshKey();
  }

  return (
    <>
      <Grid container className="container-block" rowSpacing={2}>
        {showModal &&
          <ModalComponent 
            id={"farmer-market-popup"}
            shown = {showModal}
            close = {() => {
              setShowModal(false);
              setOnEdit({});  
            }}
            title = {Object.keys(onEdit).length === 0 ? "ADD FARMER MARKET" : "EDIT FARMER MARKET"}
            modalBody = {renderModal}
            size ={"lg"}
            label={Object.keys(onEdit).length === 0 ? "ADD FARMER MARKET" : "EDIT FARMER MARKET"}
          />
        }
        <Grid item xs={12} md={6} >
          <PageHeaderComponent 
            title = {"FARMER MARKETS"}
            showSubtitle = {true}
            subtitle = {"Total Farmer Markets"}
            count = {farmersMarketTotal}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={0.5} justifyContent="flex-end" alignItems="center" >
            <Button variant="" component="label" sx={{ textTransform:'capitalize'}} startIcon={<i className="fa fa-upload" aria-hidden="true"></i>}> 
              Import
              <input hidden onChange={uploadFile} value="" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" type="file" />
            </Button>
            <Button variant=""  sx={{ textTransform:'capitalize'}} startIcon={<i className="fa fa-download" aria-hidden="true"></i>} onClick={exportData}> 
              Export
            </Button>
            <CustomButton variant="contained" startIcon={<i className="fa fa-plus" aria-hidden="true"></i>} onClick={() => {
                setShowModal(true);
                setOnEdit({});
              }}>
              Add
            </CustomButton>
            <CustomButton variant="outlined" startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={handleRefresh}>
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
        <Grid item xs={12} sx={{ flexGrow: 1 }}>
          <SearchComponent 
            placeholder={"Market Name"}
            handleChange={handleSearch}
            searchText={search}
          />
        </Grid>
        <Grid item xs={12}>
          <TableComponent 
            columns={columns}
            rows = {rows}
            showDetails = {true}
            renderDetails={renderDetails}
            handlePagination={handleNext}
            total={farmersMarketTotal}
            page={Math.floor(from/size)}
            rowsPerPage={size}
            dataLoading={showLoader}
          />
        </Grid>
      </Grid>
    </>
  );
};

FarmerMarketComponent.propTypes = {
  getFarmersMarket: PropTypes.func.isRequired,
  exportFarmersMarket: PropTypes.func.isRequired,
  administration:PropTypes.object,
  addFarmersMarket: PropTypes.func.isRequired,
  editFarmersMarket:PropTypes.func.isRequired,
  bulkUpdateFarmersMarket:PropTypes.func.isRequired,
  deleteFarmersMarket:PropTypes.func.isRequired,
  refreshKey:PropTypes.func.isRequired,
  getCityList: PropTypes.func.isRequired,
  mapdata: PropTypes.object,
  allowedRoles: PropTypes.object,
  userAuth: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return {
      getFarmersMarket: (payload) => dispatch(getFarmersMarket(payload)),
      exportFarmersMarket: () => dispatch(exportFarmersMarket()),
      addFarmersMarket: (payload) => dispatch(addFarmersMarket(payload)),
      editFarmersMarket: (payload) => dispatch(editFarmersMarket(payload)),
      bulkUpdateFarmersMarket: (payload) => dispatch(bulkUpdateFarmersMarket(payload)),
      deleteFarmersMarket: (payload) => dispatch(deleteFarmersMarket(payload)),
      refreshKey: () => dispatch(refreshKey()),
      getCityList: (city_county) => {
        dispatch({ type: GET_COUNTYCITY, city:city_county });
      },
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(FarmerMarketComponent);