import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import PropTypes from "prop-types";
import { Box, Stack } from "@mui/material";
import farmer from "../../assets/images/farmer.png";
import senior from "../../assets/images/senior.png";
import Registration from "./Registration";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const RegistrationDialog = ({
  open,
  handleClose,
  selectedRole,
  setSelectedRole,
  handleOpenSignUp,
}) => {
  // const [selectedRole, setSelectedRole] = useState(null);
  const handleSelectRole = (role) => {
    setSelectedRole(role);
  };

  const onClose = () => {
    handleClose();
    setSelectedRole(null);
  };

  const roleSelectionComponent = (
    <Stack
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <h3>
        <b>Choose Your Role</b>
      </h3>
      <Box
        mt={2}
        gap={2}
        display="flex"
        justifyContent="space-around"
        alignItems="center"
      >
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSelectRole("senior")}
        >
          <img src={senior} />
          <h4 style={{ marginTop: "20px", textAlign: "center" }}>
            <b>Senior</b>
          </h4>
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => handleSelectRole("farmer")}
        >
          <img src={farmer} />
          <h4 style={{ marginTop: "20px", textAlign: "center" }}>
            <b>Farmer</b>
          </h4>
        </div>
      </Box>
    </Stack>
  );

  const renderForm = (role) => {
    if (selectedRole === "senior" || selectedRole === "farmer") {
      return <Registration role={role} handleOpenSignUp={handleOpenSignUp} />;
    } else {
      return roleSelectionComponent;
    }
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        maxWidth="lg"
        PaperProps={{
          sx: {
            borderRadius: "10px",
          },
        }}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle></DialogTitle>
        <DialogContent>{renderForm(selectedRole)}</DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

RegistrationDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setSelectedRole: PropTypes.func,
  selectedRole: PropTypes.any,
  handleOpenSignUp: PropTypes.func,
};

export default RegistrationDialog;
