import React, { useEffect, useState } from "react";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ModalComponent from "../../common/components/modal";
import { PropTypes } from "prop-types";

import { Button, Grid, Typography, IconButton, Stack } from "@mui/material";
import SearchComponent from "../../common/components/searchComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import TableComponent from "../../common/components/table";
import * as actionTypes from "../../../redux/actionTypes/csaSignup";
import {
  CalculateUserTzToUTCTz,
  checkToken,
  popUpAlert,
} from "../../../utils/globalMethods";
import ShareInfoDetails from "./shareInfoDetails";
import moment from "moment";
import PageHeaderComponent from "../../common/components/pageHeader";
import { CustomButton } from "../../common/components/customButton";
import ShareAddEditComponent from "./shareAddEdit";
import ShareAddEditLangComponent from "./shareAddEditLang";
import {
  getMyStoreDetails,
  getVendorInfoList,
} from "../../../redux/actionTypes/vendordetails";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import SelectDropdown from "../../common/selectdropdown-component";
import DatePickerComponent from "../../common/components/dateRangePicker";
import useLanguageCode from "../../../hooks/useLanguageCode";
// import MuiDateRangePicker from "../../common/components/MuiDateRangePicker";

function createData(
  id,
  nam,
  startDate,
  endDate,
  shareCost,
  shareStatus,
  lastUpdtTs,
  shareQty,
  dlvryFlg,
  row
) {
  return {
    id,
    nam,
    startDate: moment(startDate, "YYYY-MM-DD").format("MMMM, YYYY"),
    endDate: moment(endDate, "YYYY-MM-DD").format("MMMM, YYYY"),
    shareCost,
    shareStatus,
    lastUpdtTs: moment(lastUpdtTs, "YYYY-MM-DDTHH:mm:ss[Z]").format(
      "MM/DD/YYYY h:mm A"
    ),
    shareQty: shareQty,
    dlvryFlg: dlvryFlg,
    row,
  };
}
const ShareInfoTab = (props) => {
  const languageCode = useLanguageCode();
  const { user } = props.userAuth;
  const userLogin = JSON.parse(sessionStorage.getItem("userLogin"));

  // const [showLoader, setShowLoader] = useState(true);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [expand, setExpand] = useState(false);
  const [tableSort, setTableSort] = useState({ lastUpdtTs: "DESC" });
  const [vendorShareListTotal, setVendorShareListTotal] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showLangModal, setShowLangModal] = useState(false);
  const [langData, setLangData] = useState({});
  const [langAction, setLangAction] = useState("");
  const [validated] = useState(false);
  const [onEdit, setOnEdit] = useState({});
  const [search, setSearch] = useState("");
  const [searchParams] = useSearchParams();
  let vendorId = searchParams.get("vendor_id") || userLogin?.vendorId;
  const [addressId] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({
    name: "",
    values: "All Status",
    isActive: 1,
  });
  const [resetDate, setResetDate] = useState(false);
  const [statusList] = useState([
    { name: "", values: "All status", isActive: 1 },
    { name: "DRAFT", values: "Draft", isActive: 1 },
    { name: "PDNVRF", values: "Pending DTA review", isActive: 1 },
    { name: "ENRLNG", values: "Enrolling", isActive: 1 },
    { name: "SLDOUT", values: "Sold Out", isActive: 1 },
    // { name:"EXPIRD", values: "Expired", isActive: 1 },
    { name: "ACTV", values: "Active", isActive: 1 },
    { name: "INACTV", values: "Inactive", isActive: 1 },
    { name: "REJCTD", values: "Rejected", isActive: 1 },
  ]);
  // eslint-disable-next-line no-unused-vars
  const [_isRefresh, setIsRefresh] = useState(false);

  const [dateRangePickerLabel] = useState("Select Date");
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
  });

  const filterHandleChange = (date) => {
    setFilterData({
      ...filterData,
      endDate: date?.[0]?.endDate
        ? CalculateUserTzToUTCTz(date?.[0]?.endDate)
        : null,
      startDate: date?.[0]?.startDate
        ? CalculateUserTzToUTCTz(date?.[0]?.startDate)
        : null,
    });
  };

  if (!vendorId && props.userAuth.user.vendorId) {
    vendorId = props.userAuth.user.vendorId;
  }

  const handleSubmit = (payload) => {
    if (Object.keys(onEdit).length !== 0) {
      props.editVendorShareList(payload);
    } else {
      props.addVendorShareList(payload);
    }
    setShowModal(false);
    setOnEdit({});
  };

  const handleLangSubmit = (payload) => {
    if (langAction == "add") {
      props.addShareLang(payload);
    } else {
      props.editShareLang(payload);
    }
    setShowLangModal(false);
    setLangData({});
    setLangAction("");
  };

  const renderModal = () => {
    return (
      <ShareAddEditComponent
        validated={validated}
        submitShareData={handleSubmit}
        onEdit={Object.keys(onEdit).length !== 0}
        row={onEdit}
        vendorId={vendorId}
      />
    );
  };

  const renderLangModal = () => {
    return (
      <ShareAddEditLangComponent
        handleSubmit={handleLangSubmit}
        action={langAction}
        row={langData}
        vendorId={vendorId}
      />
    );
  };

  useEffect(() => {
    const payload = {
      vendorId: vendorId,
      nam: search,
      isManageVendor: "N",
      shareStartDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/YYYY")
        : "",
      shareEndDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/YYYY")
        : "",
      shareStatus: selectedStatus.name == "all" ? "" : selectedStatus.name,
      addressId: addressId,
      from: from,
      size: size,
      lastUpdtTsSort: "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"] : "",
    };

    if (checkToken()) {
      props.getVendorShareList(payload);
    }
  }, [
    size,
    from,
    selectedStatus,
    props.csaSignup.refreshKey,
    search,
    addressId,
    filterData,
    tableSort,
  ]);

  useEffect(() => {
    const { list, total, loading } = props?.csaSignup?.vendorShareList;
    let data = [];
    // setRows([]);
    setTimeout(() => {
      list.forEach((element) => {
        data.push(
          createData(
            element.id,
            element.nam,
            element.startDate,
            element.endDate,
            element.shareCost,
            element.shareStatus,
            element.lastUpdtTs,
            element.shareQty,
            element.dlvryFlg,
            element
          )
        );
      });
      setRows(data);
    }, 300);
    handleColumns();
    setVendorShareListTotal(total);
    // setShowLoader(loading);
  }, [props?.csaSignup?.vendorShareList]);

  const handleRowClick = (e, row) => {
    var updatedSelectedRows = [];
    if (e.target.checked) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter((r) => r.id !== row.id);
    }
    setSelectAll(true);
    setSelectedRows(updatedSelectedRows);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleNext = (page, rowSize) => {
    size != rowSize && setSize(rowSize);
    setFrom(rowSize * page);
  };

  const handleSort = (item) => {
    let sortitem = {};
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC";
    } else {
      sortitem[item.id] = "DESC";
    }
    setTableSort(sortitem);
  };
  const handleOnEditAction = (row) => {
    if (user.Role === "ADMIN" || user.Role === "HIPCRD") {
      setOnEdit(row);
      setShowModal(true);
    } else if (row.shareStatus === "DRAFT" || user.Role === "CSAVDR") {
      setOnEdit(row);
      setShowModal(true);
    }
  };

  const handleOnAddTranslateAction = (row) => {
    setShowLangModal(true);
    setLangData(row);
    setLangAction("add");
  };

  const handleDelete = (row) => {
    const payload = {
      id: row.id,
    };
    props.deleteVendorShareList(payload);
  };

  const handleOnDeleteAction = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Share will be removed from the application.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleDelete(row),
    });
  };

  const handleSendDTA = (id) => {
    const payload = {
      id: id,
      isStatusUpdate: "Y",
      shareStatus: "PDNVRF",
      lastUpdtUidNam: "ADM_3",
      vendorId: vendorId,
    };
    props.editVendorShareList(payload);
  };

  const handleOnDTAReview = (id) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Share Information will be send to DTA for review.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, Send it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleSendDTA(id),
    });
  };

  const handleSoldOut = (row) => {
    const payload = {
      id: row.id,
      isStatusUpdate: "Y",
      shareStatus: "SLDOUT",
      vendorId: vendorId,
    };
    props.editVendorShareList(payload);
  };
  const handleOnSoldOut = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Share status will be update as Sold Out.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleSoldOut(row),
    });
  };

  const handleApproveStatus = (row) => {
    const payload = {
      id: row?.id,
      isStatusUpdate: "Y",
      vendorId: vendorId,
      apprvlStatus: "APPRVD",
      languageCode: languageCode,
    };
    props.editVendorShareList(payload);
  };

  const handleApprove = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to approve this Share.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, approve it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleApproveStatus(row),
    });
  };
  const handleRejectStatus = (row) => {
    const payload = {
      id: row?.id,
      isStatusUpdate: "Y",
      vendorId: vendorId,
      apprvlStatus: "REJCTD",
      languageCode: languageCode,
    };
    props.editVendorShareList(payload);
  };
  const handleReject = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to reject this Share.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, reject it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleRejectStatus(row),
    });
  };
  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    // setShowLoader(true);
    setFrom(0);
  };
  const handleColumns = () => {
    const headers = [
      { id: "details", label: "Details" },
      {
        id: "nam",
        label: "Share Name",

        cell: (item) => {
          let status = " ";
          if (item.shareStatus && item.shareStatus === "ENRLNG") {
            status = (
              <a
                onClick={() => handleOnSoldOut(item.row)}
                href="javascript:void(0)"
                style={{
                  fontSize: "18px",
                  textDecoration: "underline",
                  color: "#006e52",
                  fontWeight: 600,
                }}
              >
                {" "}
                Sold Out
              </a>
            );
            return (
              <>
                <p>{item.nam}</p>
                {status && <span>{status}</span>}
              </>
            );
          } else {
            return item.nam;
          }
        },
      },
      {
        id: "startDate",
        label: "Start Date",
        align: "right",
        format: (value) =>
          moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format("MMMM, YYYY"),
      },
      {
        id: "endDate",
        label: "End Date",
        align: "right",
        format: (value) =>
          moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format("MMMM, YYYY"),
      },
      {
        id: "shareCost",
        label: "Amount",
        cell: (item) => {
          return <div> {"$ " + item.shareCost} </div>;
        },
      },
      {
        id: "shareStatus",
        label: "SPF Share Status	",
        cell: (item) => {
          let status = "",
            share_status = "";
          if (item.shareStatus === "PDNVRF") {
            status = "Pending DTA Review";
            return (
              <div>
                <span className="badge badge-primary">{status}</span>
                <span>
                  {(user.Role === "ADMIN" || user.Role === "HIPCRD") && (
                    <div style={{ paddingLeft: "12px" }}>
                      <div style={{ margin: "8px 0px 8px 0px" }}>
                        <Button
                          variant="outlined"
                          color="success"
                          onClick={() => handleApprove(item)}
                          startIcon={<DoneOutlinedIcon />}
                          style={{ textTransform: "none", width: "100px" }}
                        >
                          Approve
                        </Button>
                      </div>
                      <div style={{ margin: "8px 0px 8px 0px" }}>
                        <Button
                          variant="outlined"
                          color="error"
                          onClick={() => handleReject(item)}
                          startIcon={<CloseIcon />}
                          style={{ textTransform: "none", width: "100px" }}
                        >
                          Reject
                        </Button>
                      </div>
                    </div>
                  )}
                </span>
              </div>
            );
          } else if (item.shareStatus === "ENRLNG") {
            status = "Enrolling";
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="badge badge-warning">{status}</span>
              </div>
            );
          } else if (item.shareStatus === "SLDOUT") {
            status = "Sold Out";
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="badge badge-danger">{status}</span>
              </div>
            );
          } else if (item.shareStatus === "EXPIRD") {
            status = "Expired";
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="badge badge-danger">{status}</span>
              </div>
            );
          } else if (item.shareStatus === "ACTV") {
            status = "Active";
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="badge badge-success">{status}</span>
              </div>
            );
          } else if (item.shareStatus === "INACTV") {
            status = "Inactive";
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="badge badge-light">{status}</span>
              </div>
            );
          } else if (item.shareStatus === "REJCTD") {
            status = "Rejected";
            return (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <span className="badge badge-light">{status}</span>
              </div>
            );
          } else if (item.shareStatus === "DRAFT") {
            status = "Draft";
            share_status = (
              <div>
                <p
                  style={{
                    color: "#006e52",
                    fontWeight: 600,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <span className="badge badge-secondary">{status}</span>
                </p>
                <a
                  onClick={() => handleOnDTAReview(item.id)}
                  style={{
                    fontSize: "18px",
                    textDecoration: "underline",
                    color: "#006e52",
                    fontWeight: 600,
                    cursor: "pointer",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Send DTA for review
                </a>
              </div>
            );
            return share_status;
          }
          if (share_status !== "") {
            return share_status;
          } else {
            return status;
          }
        },
      },
      {
        id: "lastUpdtTs",
        label: "Last Updated Time",
        sort: "lastUpdtTs" in tableSort ? tableSort["lastUpdtTs"] : "",
        format: (value) =>
          moment(value, "YYYY-MM-DDTHH:mm:ss[Z]").format("MM/DD/YYYY, h:mm a"),
      },
      {
        id: "action",
        label: "Action",
        cell: (item) => {
          // if(item.shareStatus === "DRAFT" || !(user.Role==="CSAVDR" || user.Role === "SUBVDR")){
          if (item.shareStatus === "DRAFT") {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOnEditAction(item.row)}
                >
                  <EditIcon />
                </IconButton>
                <IconButton
                  aria-label="delete"
                  onClick={() => handleOnDeleteAction(item)}
                >
                  <DeleteIcon />
                </IconButton>
              </>
            );
          } else if (user.Role === "ADMIN") {
            return (
              <>
                <IconButton
                  aria-label="edit"
                  onClick={() => handleOnEditAction(item.row)}
                >
                  <EditIcon />
                </IconButton>
                {!(item.row.multiLang.length == 2) && (
                  <IconButton
                    aria-label="Language Translate"
                    onClick={() => handleOnAddTranslateAction(item.row)}
                  >
                    <GTranslateIcon />
                  </IconButton>
                )}
              </>
            );
          } else {
            return <div style={{ paddingLeft: "30px" }}>{"-"}</div>;
          }
        },
      },
    ];
    setColumns(headers);
  };

  const handleShareLangEdit = (row) => {
    setShowLangModal(true);
    setLangData(row);
    setLangAction("edit");
  };

  const handleShareLangDelete = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Other Language Share details will be removed from the application.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => {
        const payload = {
          id: row.id,
          shareId: row.shareId,
          nam: row.nam,
          dsc: row.dsc,
          lang: row.lang,
          range: row.range,
        };
        props.deleteShareLang(payload);
      },
    });
  };

  const renderDetails = (row) => {
    return (
      <ShareInfoDetails
        row={row.row}
        editLang={handleShareLangEdit}
        deleteLang={handleShareLangDelete}
      />
    );
  };
  return (
    <Grid container className="container-block" rowSpacing={2}>
      {showModal && (
        <ModalComponent
          shown={showModal}
          close={() => {
            setShowModal(false);
            setOnEdit({});
          }}
          title={
            Object.keys(onEdit).length === 0
              ? "ADD SHARE DETAILS"
              : "UPDATE SHARE DETIALS"
          }
          modalBody={renderModal}
          size={"lg"}
          label={
            Object.keys(onEdit).length === 0
              ? "ADD SHARE DETAILS"
              : "UPDATE SHARE DETIALS"
          }
        />
      )}
      {showLangModal && (
        <ModalComponent
          shown={showLangModal}
          close={() => {
            setShowLangModal(false);
            setLangData({});
            setLangAction("");
          }}
          title={
            langAction == "add"
              ? "ADD SHARE DETAILS FOR OTHER LANGUAGE"
              : "UPDATE SHARE DETAILS FOR OTHER LANGUAGE"
          }
          modalBody={renderLangModal}
          label={
            langAction == "add"
              ? "ADD SHARE DETAILS FOR OTHER LANGUAGE"
              : "UPDATE SHARE DETAILS FOR OTHER LANGUAGE"
          }
          size={"lg"}
        />
      )}
      <Grid container paddingBottom={2}>
        <Grid item xs={12} md={6}>
          <PageHeaderComponent
            title={"Share Information"}
            showSubtitle={true}
            subtitle={"Total Records"}
            count={vendorShareListTotal}
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={2} justifyContent="flex-end">
            <CustomButton
              variant="contained"
              startIcon={<i className="fa fa-plus" aria-hidden="true"></i>}
              onClick={() => {
                setShowModal(true);
                setOnEdit({});
              }}
            >
              Add
            </CustomButton>
          </Stack>
        </Grid>
      </Grid>
      <Grid container xs={12} sx={{ backgroundColor: "#DBE8DF" }}>
        <Grid container item columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2}>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
              By Start Date and End Date
            </Typography>
            <DatePickerComponent
              label={dateRangePickerLabel}
              selectedDateObj={(date) => {
                filterHandleChange(date, "", "date");
              }}
              resetDate={resetDate}
            />
            {/* `${moment(CalculateUTCTzToUserTz(filterData.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(filterData.endDate)).format('MM-DD-YYYY')}` */}
            {/* <MuiDateRangePicker selectedDateObj={(date) => { filterHandleChange(date, "", "date") }} resetDate={resetDate} /> */}
          </Grid>
          <Grid item xs={6} md={3}>
            <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
              By Share Status
            </Typography>
            <SelectDropdown
              id="statusFilter"
              value={selectedStatus}
              isSearch={true}
              style={{ width: "75%" }}
              variant="outlined"
              options={statusList}
              onChange={(item) => {
                setSelectedStatus(item);
              }}
            ></SelectDropdown>
          </Grid>
          <Grid container item xs="auto" p={3} alignItems={"center"}>
            <CustomButton
              variant="outlined"
              sx={{ fontWeight: "bold" }}
              startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>}
              onClick={() => {
                setIsRefresh(true);
                setFilterData("");
                setSearch("");
                setExpand(false);
                setSelectedStatus({
                  name: "",
                  values: "All Status",
                  isActive: 1,
                });
                props.refreshKey();
                setIsRefresh(false);
                // setShowLoader(true);
                setResetDate((preDate) => !preDate);
              }}
            >
              Reset
            </CustomButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sx={{ flexGrow: 1 }} paddingTop={2}>
        <SearchComponent
          placeholder={"Name,Amount"}
          handleChange={handleSearch}
          searchText={search}
        />
      </Grid>
      <Grid item xs={12} paddingTop={2}>
        <TableComponent
          columns={columns}
          rows={rows}
          showDetails={true}
          expand={expand}
          renderDetails={renderDetails}
          handlePagination={handleNext}
          total={vendorShareListTotal}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
          handleRowClick={handleRowClick}
          selectedRows={selectedRows}
          handleSort={handleSort}
          // dataLoading={showLoader}
          page={Math.floor(from / size)}
          rowsPerPage={size}
        />
      </Grid>
    </Grid>
  );
};

ShareInfoTab.propTypes = {
  getVendorShareList: PropTypes.func.isRequired,
  csaSignup: PropTypes.object.isRequired,
  userAuth: PropTypes.object,
  vendorAddtInfo: PropTypes.object,
  setVendorAddtInfo: PropTypes.object,
  addVendorShareList: PropTypes.func.isRequired,
  editVendorShareList: PropTypes.func.isRequired,
  deleteVendorShareList: PropTypes.func.isRequired,
  addShareLang: PropTypes.func.isRequired,
  editShareLang: PropTypes.func.isRequired,
  deleteShareLang: PropTypes.func.isRequired,
  refreshKey: PropTypes.func.isRequired,
  getVendorInfoList: PropTypes.func,
  getMyStoreDetails: PropTypes.func.isRequired,
  vendorDetails: PropTypes.object,
  vendorId: PropTypes.number,
  updateApproveCsaSignupStatus: PropTypes.func.isRequired,
};

function mapDispatchToProps(dispatch) {
  return {
    getVendorShareList: (payload) =>
      dispatch(actionTypes.getVendorShareList(payload)),
    addVendorShareList: (payload) =>
      dispatch(actionTypes.addVendorShareList(payload)),
    editVendorShareList: (payload) =>
      dispatch(actionTypes.editVendorShareList(payload)),
    deleteVendorShareList: (payload) =>
      dispatch(actionTypes.deleteVendorShareList(payload)),
    addShareLang: (payload) => dispatch(actionTypes.addShareLang(payload)),
    editShareLang: (payload) => dispatch(actionTypes.editShareLang(payload)),
    deleteShareLang: (payload) =>
      dispatch(actionTypes.deleteShareLang(payload)),
    getMyStoreDetails: (payload) => dispatch(getMyStoreDetails(payload)),
    getVendorInfoList: (payload) => dispatch(getVendorInfoList(payload)),
    refreshKey: () => dispatch(actionTypes.refreshKey()),
    updateApproveCsaSignupStatus: (payload) =>
      dispatch(actionTypes.updateApproveCsaSignupStatus(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareInfoTab);
