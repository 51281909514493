import React , { useEffect, useState } from "react";
// import BoxWrapper from './BoxWrapper'
import { Grid } from '@mui/material';
import TableComponent from "../common/components/table";
import PageHeaderComponent from "../common/components/pageHeader";
import Stack from '@mui/material/Stack';
import {CustomButton} from "../common/components/customButton";
import moment from "moment";
import { getAccessLogs, getLoginLogs, refreshKey } from "../../redux/actionTypes/administration";
import PropTypes from "prop-types";
import { mapStateToProps } from "../../redux/reducers";
import { connect } from "react-redux";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import DatePickerComponent from '../common/components/dateRangePicker';
import '../../styles/home.scss';
import SearchComponent from "../common/components/searchComponent";
import { CalculateUTCTzToUserTz,CalculateUserTzToUTCTz, checkToken, convertUTCDateToLocalDate } from '../../utils/globalMethods';
import { endOfDay,startOfDay } from 'date-fns';
import { useNavigate } from "react-router-dom";

function createAccessData(adUserId, dsc, apiPath, requestIpAddress,createTs, row) {
  return {
      adUserId,
      dsc,
      apiPath,
      requestIpAddress,
      createTs:convertUTCDateToLocalDate(createTs),
      row
  };
}

function createLoginData(firstNam,lastNam, requestIpAddress,createTs, row) {
  return {
    name:firstNam+ " " +lastNam,
      requestIpAddress,
      createTs:convertUTCDateToLocalDate(createTs),
      row
  };
}

const AuditLogsComponent = (props) => {
  const navigate = useNavigate(); 
  const [logsTotal, setLogsTotal] = useState(0);
  const [logValues, setLogValues] = useState('accessLogs');
  const [rows, setRows] = useState([]);
  const [showLoader, setShowLoader] = useState(true);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [search, setSearch] = useState("");
  const [tableSort, setTableSort] = useState({"createTs":"DESC"});
  const filterInitialState = {
    startDate: CalculateUserTzToUTCTz(startOfDay(new Date()).toISOString()),
    endDate: CalculateUserTzToUTCTz(endOfDay(new Date()).toISOString()),
 }
 
  const [filterData, setFilterData] = useState(filterInitialState);

  const handleNext = (page,rowSize) => {
    (size != rowSize) && setSize(rowSize);
    setFrom(rowSize*page);
  }
 

  useEffect(() => {
    const userInfo = props?.userAuth?.user ? props?.userAuth?.user : JSON.parse(sessionStorage.getItem("userData"));
     if (props?.allowedRoles && userInfo && userInfo !== null && Object.keys(userInfo).length != 0) {
      const checkRole = props.allowedRoles.find((role) => userInfo?.Role.includes(role));
      if (!checkRole) {
        navigate("/kfreshfep");
        return;
      }
    }
    const payload = {
      "searchInput":search,
      "size":size,
      "from":from,
      "createTsSort": "createTs" in tableSort ? tableSort["createTs"]:"",
      "createdTs":{"startTime":filterData.startDate.unix()*1000,"endTime":filterData.endDate.unix()*1000}
     }
     if(checkToken()){
      if(logValues === 'accessLogs'){
        props.getAccessLogs(payload);
       }else if (logValues === 'loginLogs') {
        props.getLoginLogs(payload);
       }
     }
  }, [size,from,props.administration.refreshKey,search,filterData,tableSort]);
  
  useEffect(() => {
    const { accessLogs, loginLogs } = props.administration;
    let data = []
    //if condition here for log values 
    if(logValues === 'accessLogs'){
      accessLogs.list.forEach(element => {
        data.push(createAccessData(element.adUserId,element.dsc, element.apiPath, element.requestIpAddress, element.createTs, element))
      });
      setRows(data);
      setLogsTotal(accessLogs.total);
      setShowLoader(accessLogs.loading);
    }
    else if (logValues === 'loginLogs') {
      loginLogs.list.forEach(element => {
        data.push(createLoginData(element.firstNam,element.lastNam, element.requestIpAddress,element.createTs, element ))
      });
      setRows(data);
      setLogsTotal(loginLogs.total);
      setShowLoader(loginLogs.loading);
    }   
  }, [props.administration.accessLogs,props.administration.loginLogs]);

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    setFrom(0);
  }

  const handleSort = (item) => {
    let sortitem={}
    if(item.sort=="" || item.sort=="DESC"){
      sortitem[item.id] = "ASC"
    }else{
      sortitem[item.id] = "DESC"
    }
    setTableSort(sortitem)
  }

  const accessColumns = [
    { 
      id: 'adUserId', 
      label: 'Accessed By', 
      cell: (item) => {
        return(<>
          {item["row"]["firstNam"]}{' '}{item["row"]["lastNam"]}
          <br />
          {item["row"]["adUserId"]}
        </>)
      }
    },
    {
      id: 'dsc',
      label: 'Activity',
    },
    {
      id: 'apiPath',
      label: 'Url',
      align: 'right',
      cell: (item) => {
        return(<>
          {item["row"]["apiPath"]}
          <br />
          {"API"}
        </>)
      }
    },
    {
      id: 'requestIpAddress',
      label: 'IP Address',
    },
    {
      id: 'createTs',
      label:'Accessed Time',
      sort:"createTs" in tableSort ? tableSort["createTs"]:"",
    }
  ];

  const loginColumns = [
    {
      id: `name`,
      label:'Name',
    },
    {
      id: 'requestIpAddress',
      label: 'IP Address',
    },
    {
      id: 'createTs',
      label:'Last Login Time',
      sort:"createTs" in tableSort ? tableSort["createTs"]:"",
    }
  ]

  const handleLogsChange = (e) => {
    setFrom(0);
    setSize(10);
    setTableSort({"createTs":"DESC"});
    setRows([]);
    setLogsTotal(0);
    setLogValues(e.target.value);
  }

  

  const filterHandleChange = (date) => {
    setFilterData({
      ...filterData,
      endDate: CalculateUserTzToUTCTz(endOfDay( date?.[0]?.endDate)) ,
      startDate: CalculateUserTzToUTCTz(date?.[0]?.startDate),
  })
  }

  const handleRefresh = () => {
    setShowLoader(true);
    props.refreshKey();
  }
 
  return (
    <>
      <Grid container className="container-block" rowSpacing={2}>
      <Grid item xs={6}>
          <PageHeaderComponent 
            title = {"AUDIT LOGS"}
            showSubtitle = {false}
          />
        </Grid>
        <Grid item xs={6} sx={{ flexGrow: 1 }}>
          <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            <CustomButton variant="outlined" startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} onClick={handleRefresh}>
              Refresh
            </CustomButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={3}>
        <DatePickerComponent label={`${moment(CalculateUTCTzToUserTz(filterData.startDate)).format('MM-DD-YYYY')} - ${moment(CalculateUTCTzToUserTz(filterData.endDate)).format('MM-DD-YYYY')}`} selectedDateObj={(date) => { filterHandleChange(date, "", "date") }} />
        </Grid>
        <Grid item xs={12} md={9} sx={{ flexGrow: 0.1 }}>
          <Stack direction="row" justifyContent="flex-end" sx={{ flexGrow: 0.1 }}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <Select value={logValues} id="logType" onChange={handleLogsChange}>
              <MenuItem  value="accessLogs">Access Logs</MenuItem>
              <MenuItem  value="loginLogs">Login Logs</MenuItem>
            </Select>
          </FormControl>
          </Stack>
        </Grid>
        
        <Grid item xs={12} sx={{ flexGrow: 1 }}>
          <SearchComponent 
            placeholder={"Accessed By, Activity, IP"}
            handleChange={handleSearch}
            searchText={search}
          />
        </Grid>
       {/* <DatePickerComponent />   */}
        <Grid item xs={12}>
          <TableComponent 
            columns={logValues =="accessLogs" ? accessColumns : loginColumns}
            rows = {rows}
            handleSort={handleSort}
            handlePagination={handleNext}
            dataLoading={showLoader}
            total={logsTotal}
            page={Math.floor(from/size)}
            rowsPerPage={size}
          />
        </Grid>
      </Grid>
    </>
  );
};

AuditLogsComponent.propTypes = {
  getAccessLogs: PropTypes.func.isRequired,
  getLoginLogs: PropTypes.func.isRequired,
  administration:PropTypes.object,
  refreshKey: PropTypes.func.isRequired,
  allowedRoles: PropTypes.array,
  userAuth : PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
      getAccessLogs: (payload) => dispatch(getAccessLogs(payload)),
      getLoginLogs: (payload) => dispatch(getLoginLogs(payload)),
      refreshKey: () => dispatch(refreshKey()),
    };
}

export default connect(mapStateToProps,mapDispatchToProps)(AuditLogsComponent);