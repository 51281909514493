import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Row, Col, Container, Tabs, Tab, Badge } from "react-bootstrap";
import VendorDetailsTab from "./tabs/vendor";
import PublicDetailsTab from "./tabs/public";
import { mapStateToProps } from "../../redux/reducers";
import { startOfDay } from "date-fns";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  GET_USER_DETAILS,
  GET_VENDOR_LOC_DETAILS,
  getVendorInfoList,
  getMyStoreDetails,
  updateVendorBulk,
  verifyVendor,
  removeVendorStore,
  showLocationType,
} from "../../redux/actionTypes/vendordetails";
import VendorLocDetails from "./vendor-loc-details";
import { useSearchParams } from "react-router-dom";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { GET_COUNTYCITY } from "../../redux/actionTypes";
import {
  getFarmersMarket,
  refreshKey,
} from "../../redux/actionTypes/administration";
import "../../styles/registration.scss";
import ModalComponent from "../common/components/modal";
import CommentModal from "./commentModal";
import VerifyVendorComponent from "./verifyVendor";
import ShareInfoTab from "./tabs/share";
import ClientAgreementTab from "./tabs/client";
import PaymentsTab from "./tabs/payments";
import {
  popUpAlert,
  pageRefresh,
  convertUTCDateToLocalDate,
  CalculateUTCTzToUserTz,
  CalculateDaysBwTwoDates,
} from "../../utils/globalMethods";
import SnapCsaLocation from "./tabs/snapCSALocation";
import CsaVendorDetailsTab from "./tabs/csaVendorDetails";
import CsaPublicInfoTab from "./tabs/csaPublicInfo";
import SelectVendorTypeComponent from "../vendor/selectVendorType";
import CommentBadge from "./tabs/commentBadge";
import { getCommentList } from "../../redux/actionTypes/comments";
import VendorAddUserTab from "./tabs/vendorAddUser";
import CommonLoading from "../common/components/commonLoading";
import AlertOverview from "./tabs/alertOverview/alertOverview";
import MailIcon from "@mui/icons-material/Mail";
// import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { AUTH_API } from "../../config";
import axiosInstance from "../../redux/interceptor";

const Mystore = (props) => {
  const navigate = useNavigate();
  //   const userData = props?.userAuth?.user
  //     ? props.userAuth?.user
  //     : JSON.parse(sessionStorage.getItem("userData"));

  const userData = JSON.parse(sessionStorage.getItem("userData"));
  const userLogin = JSON.parse(sessionStorage.getItem("userLogin"));

  const { vdrTyp } = useParams();
  const [key, setKey] = useState("vendor");
  const [searchParams] = useSearchParams();
  let vendorId = searchParams.get("vendor_id") || userLogin.vendorId;
  let add_CSA = searchParams.get("add_csa");
  const [vendorInfo, setVendorInfo] = useState({});
  const [userRole, setUserRole] = useState("");
  const [storeDetails, setStoreDetails] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isActive, setIsActive] = useState(false);
  const [init, setInit] = useState(true);
  const [vendorType, setVendorType] = useState(
    vdrTyp != undefined ? (vdrTyp === "hip" ? "HIPVDR" : "CSAVDR") : "HIPVDR"
  );
  const [commentTotal, setCommentTotal] = useState(0);

  if (!vendorId && props.userAuth.user.vendorId) {
    vendorId = props.userAuth.user.vendorId;
  }

  useEffect(() => {
    props.getCityList();
    // getSubmittedAlert();

    // if (vendorType === "HIPVDR") {
    //   const farmersMarketPayload = {
    //     name: "",
    //     size: 20,
    //     from: 0,
    //   };
    //   props.getFarmersMarket(farmersMarketPayload);
    // }
  }, []);

  // const getSubmittedAlert = async () => {
  //   try {
  //     const url = `${AUTH_API}/getAlertsForFarmerAndCoordinator/${vendorId}`;
  //     const res = await axiosInstance.get(url);
  //     if (res) {
  //       const modifiedData = res?.data.map((item, index) => {
  //         const datePart = item.alertCreatedDate
  //           ? item.alertCreatedDate.split("T")[0]
  //           : null;
  //         const dateUpdatePart = item.alertUpdatedDate
  //           ? item.alertUpdatedDate.split("T")[0]
  //           : null;
  //         return {
  //           ...item,
  //           id: index + 1,
  //           alertCreatedDate: datePart,
  //           alertUpdatedDate: dateUpdatePart,
  //         };
  //       });
  //       setAlertTableData(modifiedData);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    setCommentTotal(props?.commentList?.total ? props?.commentList?.total : 0);
  }, [props.commentList]);

  useEffect(() => {
    let vdrTyp = vendorType;
    setUserRole(userData?.Role);

    if (add_CSA) {
      setKey("csa_vendor");
    }

    if (userData.Role == "CSAVDR" || userData.Role === "SUBVDR") {
      setVendorType("CSAVDR");
      vdrTyp = "CSAVDR";
    } else if (userData.Role == "HIPVDR") {
      setVendorType("HIPVDR");
      vdrTyp = "HIPVDR";
    } else if (props.vendorDetails?.vendorInfoList?.isHIPVendor == "Y") {
      if (!vendorType) {
        setVendorType("HIPVDR");
        vdrTyp = "HIPVDR";
      }
    } else if (props.vendorDetails?.vendorInfoList?.isCSAVendor == "Y") {
      if (!vendorType) {
        setVendorType("CSAVDR");
        vdrTyp = "CSAVDR";
      }
    }

    if (userData?.Role === "ADMIN" || userData?.Role === "HIPCRD") {
      const payload = {
        id: vendorId,
        vendorType: vdrTyp,
      };
      props.getCommentList(payload);
    }

    setVendorInfo(props.vendorDetails.vendorInfoList);
    //setIsActive(props?.vendorDetails?.vendorInfoList?.isActive === "Y" ? true : false);

    let lastUpdtTs =
      props.vendorDetails.vendorInfoList?.isHIPVendor === "Y"
        ? props.vendorDetails.vendorInfoList?.hipInfo?.lastUpdtTs
        : props.vendorDetails.vendorInfoList?.csaInfo?.lastUpdtTs;

    if (
      lastUpdtTs &&
      (userData.Role === "HIPVDR" || userData.Role === "CSAVDR")
    ) {
      const days = CalculateDaysBwTwoDates(
        CalculateUTCTzToUserTz(lastUpdtTs),
        startOfDay(new Date())
      );
      if (days >= 75) {
        if (init && props.vendorDetails.vendorInfoList) {
          popUpAlert({
            title: "",
            html: `<p>Time to review! Your location(s) and contact information have not been updated in ${days} days.</p><p style='margin-bottom:0 !important;'>You need to update or confirm your Information.</p>`,
            icon: "warning",
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonClass: "btn-danger",
            confirmButtonText: "Continue",
            closeOnConfirm: false,
          });
          setInit(false);
        }
      }
    }
  }, [props.vendorDetails.vendorInfoList]);

  useEffect(() => {
    const userInfo = props?.userAuth?.user
      ? props?.userAuth?.user
      : JSON.parse(sessionStorage.getItem("userData"));
    // if (
    //   props?.allowedRoles &&
    //   userInfo &&
    //   userInfo !== null &&
    //   Object.keys(userInfo).length != 0
    // ) {
    //   const checkRole = props.allowedRoles.find((role) =>
    //     userInfo?.Role.includes(role)
    //   );
    //   if (!checkRole) {
    //     navigate("/kfreshfep");
    //     return;
    //   }
    // }
    const payload = {
      vendorId: vendorId,
      vendorType: vendorType,
      // createTimeStamp: 1710249152304,
      // ipAddress: "1",
      // lastUpdtTimeStamp: 1710249152304,
      // lastUpdtUidNam: "VDR_2075",
    };
    props.getMyStoreDetails(payload);
    props.getVendorInfoList(payload);
    if (vendorType) setVendorType(vendorType);
  }, [props.administration.refreshKey]);

  const renderModal = () => {
    return (
      <CommentModal
        vendorId={vendorInfo?.vendorId}
        vendorType={vendorType}
        setVendorType={setVendorType}
      />
    );
  };
  useEffect(() => {
    setStoreDetails(props.vendorDetails.myStoreDetailsList);
  }, [props.vendorDetails.myStoreDetailsList]);

  const verifyVendorSubmit = (data) => {
    let payload = {
      vendorId: vendorInfo?.vendorId,
      vendorType: vendorType,
      staffName: data.staffName,
      vendorVerfNotes: data.vendorVerfNotes,
      isVerified: "Y",
      isActive: "Y",
      status: "AP",
      mail_obj: {
        primaryEmail:
          vendorType == "HIPVDR"
            ? vendorInfo?.hipInfo?.primaryEmail
            : vendorInfo?.csaInfo?.primaryEmail,
        firstName:
          vendorType == "HIPVDR"
            ? vendorInfo?.hipInfo?.firstName
            : vendorInfo?.csaInfo?.firstName,
        lastName:
          vendorType == "HIPVDR"
            ? vendorInfo?.hipInfo?.lastName
            : vendorInfo?.csaInfo?.lastName,
      },
    };
    props.verifyVendor(payload);
    setShowVerifyModal(false);
  };

  const denyVendorSubmit = (data) => {
    let payload = {
      vendorId: vendorInfo?.vendorId,
      vendorType: vendorType,
      staffName: data.staffName,
      vendorVerfNotes: data.vendorVerfNotes,
      isVerified: "Y",
      isActive: "N",
      status: "DE",
      mail_obj: {
        primaryEmail:
          vendorType == "HIPVDR"
            ? vendorInfo?.hipInfo?.primaryEmail
            : vendorInfo?.csaInfo?.primaryEmail,
        firstName:
          vendorType == "HIPVDR"
            ? vendorInfo?.hipInfo?.firstName
            : vendorInfo?.csaInfo?.firstName,
        lastName:
          vendorType == "HIPVDR"
            ? vendorInfo?.hipInfo?.lastName
            : vendorInfo?.csaInfo?.lastName,
      },
    };
    props.verifyVendor(payload);
    setShowDenyModal(false);
  };

  const renderVerifyModal = () => {
    return (
      <VerifyVendorComponent verify verifyVendorSubmit={verifyVendorSubmit} />
    );
  };

  const renderDenyModal = () => {
    return (
      <VerifyVendorComponent
        verifyVendorSubmit={denyVendorSubmit}
        btnName={true}
      />
    );
  };
  const handleActivate = (e) => {
    popUpAlert({
      title: "Are you sure?",
      text: `Your location will ${e ? "" : "not"} be shown in the map`,
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      closeOnConfirm: false,
      onConfirmed: true,
      handleDismiss: () => {
        setVendorInfo(props.vendorDetails.vendorInfoList);
        setIsActive(
          props?.vendorDetails?.vendorInfoList?.isActive === "Y" ? true : false
        );
      },
      handleConfirm: () => {
        let payload = [];
        payload.push({
          id: vendorInfo?.vendorId,
          isActive: e ? "Y" : "N",
          lastUpdateTime: new Date().getTime(),
          primaryEmail:
            vendorType == "HIPVDR"
              ? vendorInfo?.hipInfo?.primaryEmail
              : vendorInfo?.csaInfo?.primaryEmail,
          vendorType: vendorType,
        });
        props.updateVendorBulk(payload);
      },
    });
  };

  const handleDeleteStore = (data) => {
    if (data) {
      const payload = {
        vendorId: data?.name?.vendorId,
        csaPickupSite: data?.name?.csaPickupSite,
        farmStand: data?.name?.farmStand,
        farmersMarket: data?.name?.farmersMarket,
        farmersMarketManagers: data?.name?.farmersMarketManagers,
        mobileMarket: data?.name?.mobileMarket,
        storeId: data?.name?.id,
      };
      props.removeVendorStore(payload);
    }
  };

  const handleSelect = (e) => {
    const payload = {
      vendorId: vendorId,
      vendorType: "HIPVDR",
    };
    if (e.target.value === "HIPVDR") {
      setVendorType("HIPVDR");
      setKey("vendor");
      payload.vendorType = "HIPVDR";
    } else {
      setVendorType("CSAVDR");
      setKey("csa_vendor");
      payload.vendorType = "CSAVDR";
    }
    if (userData?.Role === "ADMIN" || userData?.Role === "HIPCRD") {
      props.getCommentList(payload);
    }
    props.getVendorInfoList(payload);
  };

  useEffect(() => {
    if (vendorType === "HIPVDR") {
      setKey("vendor");
    } else {
      setKey("csa_vendor");
    }
  }, [vendorType]);

  const handleVerify = () => {
    setShowVerifyModal(true);
    props.refreshKey();
  };

  const handleDeny = () => {
    setShowDenyModal(true);
    props.refreshKey();
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{ marginTop: "25px", flexGrow: 1 }}>
      <Container fluid>
        {showModal && (
          <ModalComponent
            shown={showModal}
            close={() => {
              setShowModal(false);
            }}
            title={"NOTE(S)"}
            modalBody={renderModal}
            size={"lg"}
            id={"comment-modal"}
            label={"NOTE(S)"}
          />
        )}
        {showVerifyModal && (
          <ModalComponent
            shown={showVerifyModal}
            close={() => {
              setShowVerifyModal(false);
            }}
            title={"APPROVE FARMER"}
            modalBody={renderVerifyModal}
            size={"md"}
            label={"APPROVE FARMER"}
          />
        )}

        {showDenyModal && (
          <ModalComponent
            shown={showDenyModal}
            close={() => {
              setShowDenyModal(false);
            }}
            title={"DENY VENDOR"}
            modalBody={renderDenyModal}
            size={"md"}
            label={"DENY VENDOR"}
          />
        )}
        {/* {vendorInfo && !("vendorId" in vendorInfo) && <CommonLoading />} */}
        {/* {vendorInfo.isLoading && <CommonLoading />} */}
        <Row style={{ display: "flex" }}>
          <Col md={6}>
            <Row>
              <Col md={5} sm={6}>
                <span className="resText vendor-details">
                  {add_CSA
                    ? "ADD SPF VENDOR"
                    : vendorType == "HIPVDR"
                    ? "SPF FARMER DETAILS"
                    : vendorType == "CSAVDR"
                    ? "SPF FARMER DETAILS"
                    : ""}{" "}
                </span>
              </Col>
              {/* <Col md={7} sm={6} className="mb-4">
                {vendorInfo?.hipInfo?.status === "DE" && (
                  <Badge bg="danger">
                    {
                      <>
                        <i className="fa fa-close"></i>
                        <span className="m-l-5">Rejected</span>
                      </>
                    }
                  </Badge>
                )}
              </Col> */}

              <Col md={7} sm={6} className="mb-4">
                {!add_CSA &&
                  vendorType == "HIPVDR" &&
                  (vendorInfo?.hipInfo?.isVerified === "Y" ? (
                    <>
                      {vendorInfo?.hipInfo?.status === "DE" ? (
                        <Badge bg="danger">
                          {
                            <>
                              <i className="fa fa-close"></i>
                              <span className="m-l-5">Denied</span>
                            </>
                          }
                        </Badge>
                      ) : (
                        <Badge bg="success">
                          {
                            <>
                              <i className="fa fa-check"></i>
                              <span className="m-l-5">Approved</span>
                            </>
                          }
                        </Badge>
                      )}

                      <small className="text-muted">
                        {" "}
                        <i className="fa fa-clock-o"></i>{" "}
                        {convertUTCDateToLocalDate(
                          vendorInfo.hipInfo.verifiedTs
                        )}
                      </small>
                      <br></br>
                      <small className="text-muted">
                        {"Enrollment ID: "}
                        {vendorInfo.hipInfo.orgId}
                      </small>
                    </>
                  ) : (
                    <>
                      <Badge style={{ marginLeft: "20px" }} bg="danger">
                        {
                          <>
                            <i className="fa fa-check"></i>
                            <span className="m-l-5">Pending</span>
                          </>
                        }
                      </Badge>
                      <br></br>
                      <small className="text-muted">
                        {"Enrollment ID: "}
                        {vendorInfo?.hipInfo?.orgId}
                      </small>
                    </>
                  ))}
                {!add_CSA &&
                  vendorType == "CSAVDR" &&
                  (vendorInfo?.csaInfo?.isVerified === "Y" ? (
                    <>
                      <Badge bg="success">
                        {
                          <>
                            <i className="fa fa-check"></i>
                            <span className="m-l-5">Approved</span>
                          </>
                        }
                      </Badge>
                      <small className="text-muted">
                        {" "}
                        <i className="fa fa-clock-o"></i>{" "}
                        {convertUTCDateToLocalDate(
                          vendorInfo.csaInfo.verifiedTs
                        )}
                      </small>
                    </>
                  ) : (
                    <Badge style={{ marginLeft: "20px" }} bg="danger">
                      {
                        <>
                          <i className="fa fa-check"></i>
                          <span className="m-l-5">Pending</span>
                        </>
                      }
                    </Badge>
                  ))}
              </Col>
            </Row>
          </Col>
          {!add_CSA && (
            <Col md={5} style={{ textAlign: "right" }}>
              {(userRole == "ADMIN" || userRole == "HIPCRD") && (
                <>
                  {props.vendorDetails?.vendorInfoList?.isHipAndCsaVdr && (
                    <SelectVendorTypeComponent
                      handleChange={handleSelect}
                      vendorType={vendorType}
                    />
                  )}

                  {/* <button aria-label="button" className="btn link" >Add Comment <CommentBadge total={commentTotal}/></button> */}
                  <span className="mr-2">
                    <CommentBadge
                      total={commentTotal}
                      title={"Add Note"}
                      onClick={() => setShowModal(true)}
                    />
                  </span>
                  {/* {vendorType == "HIPVDR" &&
                    vendorInfo?.hipInfo?.isVerified === "Y" && (
                      <small className="text-muted">
                        {" "}
                        <i className="fa fa-user"></i> SPF Vendor Status :
                        <BootstrapSwitchButton
                          checked={vendorInfo?.hipInfo?.isActive === "Y"}
                          onlabel="Active"
                          offlabel="Inactive"
                          width={78}
                          style={"switch-margin"}
                          onstyle="success"
                          offstyle="danger"
                          onChange={(e) => {
                            setVendorInfo({
                              isActive: e ? "Y" : "N",
                              ...vendorInfo,
                            });
                            handleActivate(e);
                            setIsActive(e);
                          }}
                        />
                      </small>
                    )} */}
                  {vendorInfo?.csaInfo?.isVerified === "Y" &&
                    vendorType == "CSAVDR" && (
                      <small className="text-muted">
                        {" "}
                        <i className="fa fa-user"></i> SPF Vendor Status :
                        <BootstrapSwitchButton
                          checked={vendorInfo?.csaInfo?.isActive === "Y"}
                          onlabel="Active"
                          offlabel="Inactive"
                          width={78}
                          style={"switch-margin"}
                          onstyle="success"
                          offstyle="danger"
                          onChange={(e) => {
                            setVendorInfo({
                              isActive: e ? "Y" : "N",
                              ...vendorInfo,
                            });
                            handleActivate(e);
                            setIsActive(e);
                          }}
                        />
                      </small>
                    )}
                  {
                    ((vendorType == "HIPVDR" &&
                      vendorInfo?.hipInfo?.isVerified === "N") ||
                      (vendorInfo?.csaInfo?.isVerified === "N" &&
                        vendorType == "CSAVDR")) && (
                      <>
                        <button
                          aria-label="button"
                          className="btn btn-primary m-1"
                          onClick={handleVerify}
                        >
                          <i className="fa fa-check"></i> Approve
                        </button>
                        <button
                          aria-label="button"
                          className="btn btn-danger m-1"
                          onClick={handleDeny}
                        >
                          <i className="fa fa-close"></i> Deny
                        </button>
                      </>
                    )
                    // && (
                    //   <button
                    //     aria-label="button"
                    //     className="btn btn-danger m-1"
                    //     onClick={handleDeny}
                    //   >
                    //     <i className="fa fa-close"></i> Reject
                    //   </button>
                    // )
                  }
                </>
              )}
              {/* <button
                aria-label="button"
                className="btn btn-danger m-1"
                onClick={handleDeny}
              >
                <i className="fa fa-close"></i> Reject
              </button> */}
              <button
                aria-label="button"
                className="btn btn-outline-primary"
                onClick={pageRefresh}
              >
                <i className="fa fa-refresh"></i> Refresh
              </button>
            </Col>
          )}
          <Col md={1}>
            {/* <Badge bg="success">
              {
                <>
                  <i className="fa fa-bell fa-1x" aria-hidden="true"></i>
                </>
              }
            </Badge> */}
            {/* {!(userData?.Role === "ADMIN") && (
              <Badge badgeContent={alertTableData?.length} color="primary">
                <NotificationsIcon
                  color="action"
                  onClick={() => {
                    setKey("alerts");
                  }}
                />
              </Badge>
            )} */}
          </Col>
        </Row>
        <Row style={{ marginTop: "10px" }}>
          <Col className="vendor-info-tabs">
            <Tabs
              id="controlled-tab"
              activeKey={key}
              onSelect={(k) => setKey(k)}
              style={{ background: "#fff" }}
            >
              {!add_CSA && vendorId && vendorType === "HIPVDR" && (
                <Tab
                  eventKey="vendor"
                  title={
                    <div className="tab-header">
                      <i className="fa fa-user"></i>
                      <span className="m-l-5">SPF Farmer Details</span>
                    </div>
                  }
                >
                  {key === "vendor" && (
                    <VendorDetailsTab
                      vendorInfo={vendorInfo?.hipInfo}
                      userId={props?.userAuth?.user?.id}
                      vendorType={"HIPVDR"}
                      isHipAndCsaVdr={true}
                    />
                  )}
                </Tab>
              )}
              {!add_CSA && vendorId && vendorType === "HIPVDR" && (
                <Tab
                  eventKey="public"
                  title={
                    <div className="tab-header">
                      <i className="fa fa-eye"></i>
                      <span className="m-l-5">SPF Public Info</span>
                    </div>
                  }
                >
                  {key === "public" && (
                    <PublicDetailsTab
                      vendorInfo={vendorInfo?.hipInfo}
                      userId={props?.userAuth?.user?.id}
                    />
                  )}
                </Tab>
              )}

              {(add_CSA || (vendorId && vendorType === "CSAVDR")) && (
                <Tab
                  eventKey="csa_vendor"
                  title={
                    <div className="tab-header">
                      <i className="fa fa-user"></i>
                      <span className="m-l-5">SPF Farmer Details</span>
                    </div>
                  }
                >
                  {/* {key === "csa_vendor" && ( */}

                  {vendorInfo && vendorInfo.csaInfo && (
                    <CsaVendorDetailsTab
                      vendorInfo={vendorInfo.csaInfo}
                      userId={props.userAuth.user.id}
                      add_CSA={add_CSA}
                      vendorId={vendorId}
                    />
                  )}
                </Tab>
              )}
              {vendorId && vendorType === "CSAVDR" && (
                <Tab
                  eventKey="csa_public"
                  title={
                    <div className="tab-header">
                      <i className="fa fa-eye"></i>
                      <span className="m-l-5">SPF Public Info</span>
                    </div>
                  }
                >
                  {/* {key === "csa_public" && ( */}
                  {vendorInfo && vendorInfo.csaInfo && (
                    <CsaPublicInfoTab
                      vendorInfo={vendorInfo.csaInfo}
                      userId={props.userAuth.user.id}
                    />
                  )}
                </Tab>
              )}

              {
                <Tab
                  eventKey="alerts"
                  title={
                    <div className="tab-header">
                      <i className="fa fa-bell"></i>
                      <span className="m-l-5">Alert Overview</span>
                    </div>
                  }
                >
                  {key === "alerts" && (
                    <AlertOverview
                      vendorInfo={vendorInfo.hipInfo}
                      userId={props.userAuth.user.id}
                      vendorId={vendorId}
                      // getSubmittedAlert={getSubmittedAlert}
                    />
                  )}
                </Tab>
              }
              {/* {vendorId &&
                (vendorType === "HIPVDR" || vendorType === "CSAVDR") && (
                  // vendorInfo?.csaInfo?.isVerified === "Y" &&
                  <Tab
                    eventKey="share"
                    title={
                      <div className="tab-header">
                        <i className="fa fa-dollar"></i>
                        <span className="m-l-5">Share Information</span>
                      </div>
                    }
                  >
                    {key === "share" && (
                      <ShareInfoTab
                        vendorInfo={vendorInfo}
                        userId={props.userAuth.user.id}
                      />
                    )}
                  </Tab>
                )} */}
              {vendorId &&
                vendorType === "CSAVDR" &&
                vendorInfo?.csaInfo?.isVerified === "Y" && (
                  <Tab
                    eventKey="client"
                    title={
                      <div className="tab-header">
                        <i className="fa fa-handshake-o"></i>
                        <span className="m-l-5">Client Agreement</span>
                      </div>
                    }
                  >
                    {key === "client" && (
                      <ClientAgreementTab
                        vendorInfo={vendorInfo}
                        userId={props.userAuth.user.id}
                      />
                    )}
                  </Tab>
                )}
              {vendorId &&
                vendorType === "CSAVDR" &&
                userRole !== "HIPCRD" &&
                vendorInfo?.csaInfo?.isVerified === "Y" && (
                  <Tab
                    eventKey="payment"
                    title={
                      <div className="tab-header">
                        <i className="fa fa-money "></i>
                        <span className="m-l-5">Payments</span>
                      </div>
                    }
                  >
                    {key === "payment" && (
                      <PaymentsTab
                        isManagePayment={false}
                        vendorInfo={vendorInfo}
                        userId={props.userAuth.user.id}
                      />
                    )}
                  </Tab>
                )}
              {vendorId &&
                vendorType === "CSAVDR" &&
                vendorInfo?.csaInfo?.isVerified === "Y" && (
                  <Tab
                    eventKey="snapCSALocation"
                    title={
                      <div className="tab-header">
                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                        <span className="m-l-5">SNAP SPF Location</span>
                      </div>
                    }
                  >
                    {key === "snapCSALocation" && (
                      <SnapCsaLocation
                        vendorInfo={vendorInfo}
                        userId={props.userAuth.user.id}
                      />
                    )}
                  </Tab>
                )}
              {/* {vendorId &&
                vendorType === "CSAVDR" &&
                vendorInfo?.csaInfo?.isVerified === "Y" && (
                  <Tab
                    eventKey="vendorAddUser"
                    title={
                      <div className="tab-header">
                        <i className="fa fa-user"></i>
                        <span className="m-l-5">Vendor&rsquo;s Sub Users</span>
                      </div>
                    }
                  >
                    {key === "vendorAddUser" && (
                      <VendorAddUserTab
                        vendorInfo={vendorInfo}
                        userId={props.userAuth.user.id}
                      />
                    )}
                  </Tab>
                )} */}
              {vendorId && vendorType === "HIPVDR" && (
                // vendorInfo?.hipInfo?.isVerified === "Y" &&
                <Tab
                  eventKey="vendorAddUser"
                  title={
                    <div className="tab-header">
                      <i className="fa fa-user"></i>
                      <span className="m-l-5">{`Farmers' Sub Users`}</span>
                    </div>
                  }
                >
                  {key === "vendorAddUser" && (
                    <VendorAddUserTab
                      vendorInfo={vendorInfo}
                      userId={props.userAuth.user.id}
                    />
                  )}
                </Tab>
              )}
            </Tabs>
          </Col>
        </Row>
        {!add_CSA &&
          !(key === "alerts" || key === "vendorAddUser") &&
          vendorId &&
          vendorType === "HIPVDR" && (
            <Row style={{ marginTop: "10px" }}>
              <Col>
                <VendorLocDetails
                  myStoreDetails={
                    "data" in storeDetails ? storeDetails.data : []
                  }
                  // isLoading={
                  //   storeDetails &&
                  //   "isLoading" in storeDetails &&
                  //   vendorInfo &&
                  //   "vendorId" in vendorInfo
                  //     ? storeDetails.isLoading
                  //     : true
                  // }
                  vendorInfo={
                    vendorType === "HIPVDR"
                      ? vendorInfo?.hipInfo
                      : vendorInfo?.csaInfo
                  }
                  farmersList={props.administration.farmersMarket.list}
                  city={props.mapdata.city}
                  deleteStore={handleDeleteStore}
                  vendorType={vendorType}
                />
              </Col>
            </Row>
          )}
      </Container>
    </div>
  );
};
Mystore.propTypes = {
  vendorDetails: PropTypes.object,
  administration: PropTypes.object,
  mapdata: PropTypes.object,
  getUserDetails: PropTypes.func,
  // vendorLocDetails: PropTypes.object,
  getVendorLocDetails: PropTypes.func,
  getVendorInfoList: PropTypes.func,
  getMyStoreDetails: PropTypes.func,
  getCityList: PropTypes.func.isRequired,
  getFarmersMarket: PropTypes.func.isRequired,
  userAuth: PropTypes.object,
  updateVendorBulk: PropTypes.func.isRequired,
  verifyVendor: PropTypes.func.isRequired,
  removeVendorStore: PropTypes.func,
  refreshKey: PropTypes.func.isRequired,
  showLocationType: PropTypes.func.isRequired,
  getCommentList: PropTypes.func.isRequired,
  total: PropTypes.bool,
  commentList: PropTypes.object,
  allowedRoles: PropTypes.array,
};
const mapDispatchToProps = (dispatch) => {
  return {
    getVendorInfoList: (payload) => dispatch(getVendorInfoList(payload)),
    getMyStoreDetails: (payload) => dispatch(getMyStoreDetails(payload)),
    removeVendorStore: (payload) => dispatch(removeVendorStore(payload)),
    verifyVendor: (payload) => dispatch(verifyVendor(payload)),
    getUserDetails: (vendorId) => {
      dispatch({ type: GET_USER_DETAILS, value: vendorId });
    },
    getVendorLocDetails: (vendorId) => {
      dispatch({ type: GET_VENDOR_LOC_DETAILS, value: vendorId });
    },
    getCityList: (city_county) => {
      dispatch({ type: GET_COUNTYCITY, city: city_county });
    },
    getFarmersMarket: (payload) => dispatch(getFarmersMarket(payload)),
    updateVendorBulk: (payload) => dispatch(updateVendorBulk(payload)),
    showLocationType: (payload) => dispatch(showLocationType(payload)),
    getCommentList: (payload) => dispatch(getCommentList(payload)),
    refreshKey: () => dispatch(refreshKey()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mystore);

// export default Mystore;
