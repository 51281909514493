import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import FormInput from "../components/input";
import CardTypeInput from "../components/radio-button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import { mapStateToProps } from "../../../redux/reducers";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import { connect } from "react-redux";
import TableComponent from "../../common/components/table";
import CancelDetails from "./cancelDetails";
import { CustomButton } from "../../common/components/customButton";
import "../../../styles/home.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
} from "@mui/material";
import Form from "react-bootstrap/Form";
import { Row, Col } from "react-bootstrap";

import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { popUpAlert } from "../../../utils/globalMethods";
import moment from "moment";
import { refreshKey } from "../../../redux/actionTypes/administration";
import useLanguageCode from "../../../hooks/useLanguageCode";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function createData(
  agrmtId,
  id,
  setId,
  vendorId,
  clientId,
  shareId,
  verbiageDocId,
  vendorName,
  apprvlStatus,
  shareNam,
  agrmntAppId,
  lastUpdtTs,
  shareCost,
  dsc,
  shareQty,
  address1,
  address2,
  city,
  state,
  zipcode,
  day,
  openTime,
  closeTime,
  cancelNotes,
  cancelESign,
  clientNam,
  endDate,
  paymentType,
  row
) {
  return {
    agrmtId,
    id,
    setId,
    vendorId,
    clientId,
    shareId,
    verbiageDocId,
    vendorName,
    apprvlStatus,
    shareNam,
    agrmntAppId,
    lastUpdtTs: moment(new Date()).format("MM/DD/YYYY hh:mm:ss a"),
    shareCost,
    dsc: dsc,
    shareQty: shareQty,
    address1: address1,
    address2: address2,
    city: city,
    state: state,
    zipcode: zipcode,
    day: day,
    openTime: openTime,
    closeTime: closeTime,
    cancelNotes,
    cancelESign,
    clientNam,
    checkboxDisable:
      apprvlStatus === "REJCTD" || apprvlStatus === "CANCLD" ? true : false,
    endDate: endDate,
    paymentType,
    row,
  };
}
function CancelFormComponent(props) {
  const languageCode = useLanguageCode();

  let loginId = props?.userAuth?.user?.id;
  const [month, setMonth] = React.useState("");
  const [rows, setRows] = useState([]);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [columns, setColumns] = useState([]);
  const [agreementList, setAgreementList] = useState([]);
  const [showLoader, setShowLoader] = useState();
  const [expand] = useState(false);
  const [tableSort, setTableSort] = useState({ lastUpdtTs: "DESC" });
  const [showTable, setShowTable] = useState(false);
  const [selectedReason, setSelectedReason] = useState("");
  const [otherReason, setOtherReason] = useState("");
  const [submittedData, setSubmittedData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectMessage, setSelectMessage] = useState("");
  const [selectOption, setSelectOption] = useState("");
  const [cancelAgreementlist, setCancelAgreementList] = useState("");
  const userData = props?.userAuth?.user
    ? props?.userAuth?.user
    : JSON.parse(sessionStorage.getItem("userData"));
  const [isLoggin] = useState(loginId ? true : false);
  const [error, setError] = useState("");
  const [apid, setApid] = useState("");

  const monthOptions = [
    { value: "", label: "Month" },
    { value: "JAN", label: "January" },
    { value: "FEB", label: "February" },
    { value: "MAR", label: "March" },
    { value: "APR", label: "April" },
    { value: "MAY", label: "May" },
    { value: "JUN", label: "June" },
    { value: "JUL", label: "July" },
    { value: "AUG", label: "August" },
    { value: "SEP", label: "September" },
    { value: "OCT", label: "October" },
    { value: "NOV", label: "November" },
    { value: "DEC", label: "December" },
  ];

  const cancelOptions = [
    // { value: " ", text: "Select the reason" },
    { value: "1", text: "Change in Benefit Amount" },
    { value: "2", text: "Change share type" },
    { value: "3", text: "Cancelled due to HIP Suspension" },
    { value: "4", text: "No reason given" },
    { value: "5", text: "Transportaion/Medical issues" },
    { value: "6", text: "Unsatisfied with share" },
    { value: "7", text: "Other Reason" },
  ];

  const handleReasonChange = (event) => {
    const reason = event.target.value;
    setSelectedReason(reason);
    if (reason !== "7") {
      setOtherReason("");
    }
  };

  const selectedCancelOption = cancelOptions.find(
    (option) => option.value === selectedReason
  );
  const cancelNotesText = selectedCancelOption ? selectedCancelOption.text : "";

  const handleMonthChange = (event) => {
    setMonth(event.target.value);
    setSelectedMonth(event.target.value);
  };

  const {
    register,
    handleSubmit,
    watch,
    getValues,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      date: "",
      month: "",
      year: "",
      clientId: "",
      panNumber: "",
      cancelNotes: "",
      cancelESign: "",
    },
  });

  useEffect(() => {
    if (
      props?.csaSignup?.getCancelAgreementList?.getCancelAgreementList?.code ===
      "508"
    ) {
      popUpAlert({
        title: "Error",
        text: props?.csaSignup?.getCancelAgreementList?.getCancelAgreementList
          ?.message,
        icon: "error",
        timer: 1500,
        showCancelButton: false,
        showConfirmButton: false,
      });
    }
  });

  const handleNext = (page, rowSize) => {
    size != rowSize && setSize(rowSize);
    setFrom(rowSize * page);
    console.log(rowSize, "rowSize");
  };
  const dateOfBirth =
    selectedDate && selectedMonth && selectedYear
      ? `${selectedDate}-${selectedMonth}-${selectedYear}`
      : "";
  const selectedOption = watch("card");
  const onSubmit = (data) => {
    setCancelAgreementList(data);
    console.log(data);
    setSelectOption(selectedOption);
    setSelectedRows([]);
    const dob =
      selectedDate && selectedMonth && selectedYear
        ? `${selectedDate}-${selectedMonth}-${selectedYear.slice(-2)}`
        : "";
    setShowTable(true);
    props.setShowForm(false);
    const dataObj = {
      firstName: data.firstName,
      lastName: data.lastName,
      clientId: apid ? apid : "",
      panNumber: data.ebt ? data.ebt : "",
      dob: dob,
      size: size,
      from: 0,
      setObj: selectedOption,
    };

    setSubmittedData(dataObj);
    props.getCancelAgreementList(dataObj, size);
    setShowLoader(true);
  };

  useEffect(() => {
    const dob =
      selectedDate && selectedMonth && selectedYear
        ? `${selectedDate}-${selectedMonth}-${selectedYear.slice(-2)}`
        : "";
    const payload = {
      firstName: cancelAgreementlist.firstName,
      lastName: cancelAgreementlist.lastName,
      clientId: cancelAgreementlist.apid ? cancelAgreementlist.apid : "",
      panNumber: cancelAgreementlist.ebt ? cancelAgreementlist.ebt : "",
      dob: dob,
      size: size,
      from: from,
    };
    props.getCancelAgreementList(payload);
    setShowLoader(true);
  }, [props?.csaSignup?.refreshKey, size, from]);

  useEffect(() => {
    setAgreementList(props.csaSignup?.cancelAgreementList);
  }, [props?.csaSignup?.refreshKey, size, from, tableSort]);

  useEffect(() => {
    const { list, total } = props?.csaSignup?.cancelAgreementList;
    let data = [];
    setRows([]);
    setTimeout(() => {
      list.forEach((element) => {
        element.agrmtId = element.id;
        element.id = element.setId;

        data.push(
          createData(
            element.agrmtId,
            element.id,
            element.setId,
            element.vendorId,
            element.clientId,
            element.shareId,
            element.verbiageDocId,
            element.vendorName,
            element.apprvlStatus,
            element.shareNam,
            element?.agrmntAppId,
            element.lastUpdtTs,
            element.shareCost,
            element?.shareInfo.dsc,
            element.shareInfo?.shareQty,
            element?.shareInfo?.pickupInfo?.address1
              ? element?.shareInfo?.pickupInfo?.address1
              : element?.shareInfo?.deliveryInfo?.address1,
            element?.shareInfo?.pickupInfo?.address2
              ? element?.shareInfo?.pickupInfo?.address2
              : element?.shareInfo?.deliveryInfo?.address2,
            element?.shareInfo?.pickupInfo?.city
              ? element?.shareInfo?.pickupInfo?.city
              : element?.shareInfo?.deliveryInfo?.city,
            element?.shareInfo?.pickupInfo?.state
              ? element?.shareInfo?.pickupInfo?.state
              : element?.shareInfo?.deliveryInfo?.state,
            element?.shareInfo?.pickupInfo?.zipcode
              ? element?.shareInfo?.pickupInfo?.zipcode
              : element?.shareInfo?.deliveryInfo?.zipcode,
            element?.shareInfo?.pickupInfo?.day,
            element?.shareInfo?.pickupInfo?.openTime,
            element?.shareInfo?.pickupInfo?.closeTime,
            element.cancelNotes,
            element.cancelESign,
            element.clientNam,
            element.shareInfo?.endDate,
            element.paymentType,
            element.shareInfo?.shareQtyUnit,
            element.row
          )
        );
      });
      setRows(data);
    }, 300);
    handleColumns();
    setAgreementList(total);
    setShowLoader(false);
  }, [props?.csaSignup?.cancelAgreementList]);

  const handleCancelUpdate = (items) => {
    const formData = getValues();
    const timeStamp = new Date().getTime();
    let role = "";
    if (userData?.Role === "ADMIN") {
      role = "ADM_";
    } else if (userData?.Role === "HIPCRD") {
      role = "CRD_";
    } else {
      role = "VDR_";
    }
    let payload = [];
    items.map((row) => {
      payload.push({
        id: row?.agrmtId,
        setId: row?.id,
        shareId: row?.shareId,
        vendorId: row?.vendorId,
        clientId: row?.clientId,
        clientNam: row?.clientNam,
        shareNam: row?.shareNam,
        vendorName: row?.vendorName,
        apprvlStatus: "CANCLD",
        paymentType: row?.paymentType,
        cancelNotes: selectedReason === "7" ? otherReason : cancelNotesText,
        cancelESign: formData.cancelESign,
        languageCode: languageCode,
        lastUpdtUidNam: isLoggin ? role + userData?.id : "CSASIGNUP",
        createTimeStamp: timeStamp,
        lastUpdtTimeStamp: timeStamp,
      });
    });
    setSelectedRows([]);
    // console.log(payload);
    props.updateCancelAgreement(payload);
    handleClose();
  };

  const handleCancelSubmit = () => {
    const bulkupdate = selectedRows.map((selectedRow) => {
      selectedRow.apprvlStatus =
        selectedRow.apprvlStatus == "DRAFT" ||
        selectedRow.apprvlStatus == "APPRVD"
          ? "CANCLD"
          : "";
      return {
        ...selectedRow,
        agrmntAppId: selectedRow.agrmntAppId,
      };
    });
    const payload = bulkupdate;
    if (payload.length > 0) {
      handleCancelUpdate(payload);
    } else {
      popUpAlert({
        title: "Error",
        text: "Select atleast one vendor",
        icon: "error",
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonClass: "btn-danger",
        confirmButtonText: "OK",
        closeOnConfirm: true,
      });
    }
  };

  const handleDocument = (row) => {
    console.log(row, "row");
    const noticesPayload = {
      clientId: row?.clientId,
      documentId: row?.verbiageDocId,
      createTimeStamp: 1683617387095,
      lastUpdtTimeStamp: 1683617387095,
    };
    props.getCSADoc(noticesPayload);
  };

  useEffect(() => {
    if (props.csaSignup?.csaDocument?.csaDocument?.stream) {
      var link = document.createElement("a");
      link.download = "csa_signup_agreement.pdf";
      link.href = `data:application/pdf;base64,${props.csaSignup?.csaDocument?.csaDocument?.stream}`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [props.csaSignup?.csaDocument?.csaDocument]);

  const pdfDownloader = (item) => {
    handleDocument(item);
  };

  const handleColumns = () => {
    const headers = [
      { id: "checkbox", label: "" },
      { id: "details", label: "Details" },
      {
        id: "vendorName",
        label: "Vendor Name",
        cell: (item) => (
          <div>
            <p>{item.vendorName}</p>
            <span style={{ color: "#006E52" }}>{item.agrmntAppId}</span>
            <button
              style={{ border: "none", color: "#006E52" }}
              onClick={() => pdfDownloader(item)}
            >
              <i className="fa fa-download" aria-hidden="true"></i>
            </button>
          </div>
        ),
      },

      {
        id: "apprvlStatus",
        label: "Agreement Status",
        cell: (item) => {
          let status = "";
          if (item.apprvlStatus == "DRAFT") {
            status = "Pending";
            return (
              <div style={{ color: "#FFBE6F", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "REJCTD") {
            status = "Rejected";
            return (
              <div style={{ color: "#C43F0F", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "APPRVD") {
            status = "Approved";
            return (
              <div style={{ color: "#529D8A", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "CANCLD") {
            status = "Cancelled";
            return (
              <div style={{ color: "#C43F0F", fontWeight: "bold" }}>
                {" "}
                {status}{" "}
              </div>
            );
          }
        },
      },
      {
        id: "shareNam",
        label: "Share Name",
      },
      {
        id: "endDate",
        label: "Expires On",
        cell: (item) => {
          return (
            <div>{moment(item.endDate).format("MM/DD/YYYY hh:mm:ss a")}</div>
          );
        },
      },
      {
        id: "shareCost",
        label: "Amount",
        cell: (item) => {
          return <div>{"$ " + item.shareCost}</div>;
        },
      },
    ];
    setColumns(headers);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleRowClick = (e, row) => {
    var updatedSelectedRows = [];
    if (e.target.checked) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter((r) => r.id !== row.id);
    }
    setSelectAll(true);
    setSelectedRows(updatedSelectedRows);
  };
  const handleSort = (item) => {
    let sortitem = {};
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC";
    } else {
      sortitem[item.id] = "DESC";
    }
    setTableSort(sortitem);
  };

  const renderDetails = (row) => {
    return <CancelDetails row={row} />;
  };

  useEffect(() => {
    setSubmittedData(null);
  }, []);

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    if (selectedRows.length === 0) {
      setSelectMessage(
        <span className="dh-txt-danger">
          <i className="fa fa-info-circle"></i>
          Please select atleast one vendor
        </span>
      );
    } else {
      setOpen(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSubmittedData(false);
    setSelectMessage("");
  };
  useEffect(() => {
    setShowTable(!props.showForm);
    setMonth("");
  }, [props.showForm]);

  useEffect(() => {
    reset({
      firstName: "",
      lastName: "",
      clientId: "",
      panNumber: "",
      date: "",
      month: "",
      year: "",
    });
    setMonth("");
    setValue("year", "");
    setSelectOption("");
    setValue("ebt", "");
    setValue("apid", "");
  }, [props.reset]);

  const handleChange = (e) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/^0+/, "");

    if (inputValue !== "") {
      const intValue = parseInt(inputValue, 10);
      if (!isNaN(intValue) && intValue >= 1 && intValue <= 31) {
        inputValue = intValue < 10 ? `0${intValue}` : `${intValue}`;
        setError("");
      } else {
        setError("Please enter a valid day between 1 and 31.");
        inputValue = "";
      }
    }
    setSelectedDate(inputValue);
  };

  return (
    <Box className="dis" sx={{ flexGrow: 1 }}>
      <Box className="sorryTogo">
        <SentimentVeryDissatisfiedIcon
          style={{ color: "orange", fontSize: "70px" }}
        />
        <p style={{ color: "orange", fontWeight: "bold", fontSize: "24px" }}>
          We are sorry to see you go{" "}
        </p>{" "}
      </Box>
      {!showTable ? (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className=" mb-0 gray-text" style={{ fontWeight: "900" }}>
              Please provide the following information to cancel your CSA
              agreement
            </p>
            <br />
            <Grid container spacing={2}>
              {/* First Name */}
              <Grid item xs={12} sm={6} md={4}>
                <FormInput
                  label="First Name"
                  name="firstName"
                  register={register}
                  errors={errors}
                  maxLength={100}
                />
              </Grid>

              {/* Last Name */}
              <Grid item xs={12} sm={6} md={4}>
                <FormInput
                  label="Last Name"
                  name="lastName"
                  register={register}
                  errors={errors}
                  maxLength={100}
                />
              </Grid>

              {/* Date Of Birth */}
              <Grid item xs={2} md={4}>
                <label className="dh-form-label font-bold">
                  Date of Birth *
                </label>
                <TextField
                  sx={{ width: "23%", marginRight: "5px" }}
                  type="text"
                  {...register("date", {
                    required: "Date of Birth is required",
                  })}
                  onKeyPress={(e) => {
                    if (e.key && !/^\d$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  InputProps={{ inputProps: { min: 1, max: 31, maxLength: 2 } }}
                  placeholder="Day"
                  onChange={handleChange}
                />
                <Select
                  sx={{ width: "44%", marginRight: "5px" }}
                  value={month}
                  onChange={handleMonthChange}
                >
                  {monthOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <TextField
                  sx={{ width: "30%" }}
                  {...register("year", { required: true })}
                  type="text"
                  onKeyPress={(e) => {
                    if (e.key && !/^\d$/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => setSelectedYear(e.target.value)}
                  InputProps={{ inputProps: { min: 1, max: 31, maxLength: 4 } }}
                  placeholder="Year"
                />
                {errors.date && (
                  <span className="dh-txt-danger">{errors.date.message}</span>
                )}
                {error && <span className="dh-txt-danger">{error}</span>}
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ marginTop: "5px" }}>
              {/* EBT/APID */}
              <Grid item xs={12} sm={6} md={4}>
                <CardTypeInput
                  style={{ fontWeight: "900" }}
                  label="EBT Card Number / DTA Agency ID"
                  name="card"
                  register={register}
                  errors={errors}
                  value={selectedOption}
                />
              </Grid>
              {/* EBT Card Number */}

              {selectedOption === "ebt" && (
                <Grid item xs={12} sm={6} md={4}>
                  <label
                    className="dh-form-label font-bold"
                    htmlFor="ebt"
                    style={{ fontWeight: "900" }}
                  >
                    EBT Card Number *
                  </label>
                  <FormControl fullWidth>
                    <OutlinedInput
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">600875</InputAdornment>
                      }
                      {...register("ebt", {
                        required: "EBT Card Number is required",
                      })}
                      onKeyPress={(e) => {
                        if (e.key && !/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      inputProps={{ maxLength: 12 }}
                    />
                  </FormControl>

                  {errors.ebt && (
                    <span className="dh-txt-danger">{errors.ebt.message}</span>
                  )}
                </Grid>
              )}

              {/* Agency Id */}
              {selectedOption === "apid" && (
                <Grid item xs={12} sm={6} md={4}>
                  <label
                    className="dh-form-label font-bold"
                    style={{ fontWeight: "900" }}
                  >
                    DTA Agency ID*
                  </label>
                  <TextField
                    sx={{ width: "100%" }}
                    // label="DTA Agency ID"
                    {...register("apid", {
                      required: "DTA Agency ID is required",
                    })}
                    name="apid"
                    value={apid}
                    register={register}
                    errors={errors}
                    // onKeyDown={isNumber}
                    onKeyPress={(e) => {
                      if (
                        !/^\d$/.test(e.key) &&
                        e.key !== "Backspace" &&
                        e.key !== "Delete"
                      ) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => setApid(e.target.value)}
                    maxLength={7}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6} md={4} marginTop={4}>
                <Box display="flex" justifyContent="center">
                  <Button
                    type="submit"
                    sx={{
                      marginTop: "10px",
                      backgroundColor: "#006E52",
                      color: "fff",
                      "&:hover": {
                        backgroundColor: "#006E52",
                        color: "#ffffff",
                      },
                    }}
                    variant="contained"
                    color="primary"
                  >
                    Submit
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>{" "}
        </>
      ) : (
        <>
          {submittedData && (
            <Grid container spacing={2} style={{ fontFamily: "text" }}>
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom component="div">
                  First Name
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {submittedData.firstName}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom component="div">
                  Last Name
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {submittedData.lastName}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="h6" gutterBottom component="div">
                  Date of Birth
                </Typography>
                <Typography variant="subtitle1" gutterBottom>
                  {/* {submittedData.dob} */}
                  {dateOfBirth}
                </Typography>
              </Grid>
              {selectOption === "apid" ? (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom component="div">
                    DTA Agency ID
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {submittedData.clientId}
                  </Typography>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Typography variant="h6" gutterBottom component="div">
                    EBT Card Number
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {submittedData.panNumber}
                  </Typography>
                </Grid>
              )}
            </Grid>
          )}

          <Grid item xs={12} sx={{ marginTop: "40px" }}>
            <p
              style={{
                fontSize: "23px",
                color: "#dc3545",
                fontFamily: "Texta-bold",
              }}
            >
              {selectMessage}
            </p>
            <TableComponent
              columns={columns}
              rows={rows}
              expand={expand}
              showDetails={true}
              renderDetails={renderDetails}
              total={agreementList}
              handlePagination={handleNext}
              selectAll={selectAll}
              handleSelectAll={handleSelectAll}
              handleRowClick={handleRowClick}
              selectedRows={selectedRows}
              handleSort={handleSort}
              page={Math.floor(from / size)}
              rowsPerPage={size}
              dataLoading={showLoader}
            />
          </Grid>
          <div>
            <Grid
              container
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              <CustomButton variant="contained" onClick={handleClickOpen}>
                Cancel
              </CustomButton>
            </Grid>
            <BootstrapDialog
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={open}
            >
              <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                CANCEL CSA AGREEMENT
                <Divider
                  sx={{
                    borderWidth: 2,
                    backgroundColor: "#006e52",
                    width: "90px",
                  }}
                />
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <CloseIcon />
              </IconButton>
              <form onSubmit={handleSubmit(handleCancelSubmit)}>
                <DialogContent dividers>
                  <Row>
                    <Col md={5}>
                      <Form.Group as={Col} controlId="reason">
                        <Form.Label>Reason for cancellation*</Form.Label>
                      </Form.Group>
                    </Col>
                    <Col md={7}>
                      <Form.Group as={Col} controlId="reasonDropdown">
                        <Form.Select
                          size="lg"
                          {...register("cancelNotes", {
                            required: "Please choose the Reason",
                          })}
                          value={selectedReason}
                          onChange={handleReasonChange}
                        >
                          {cancelOptions.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.text}
                            </option>
                          ))}
                        </Form.Select>

                        {errors.cancelNotes && (
                          <span className="dh-txt-danger">
                            {errors.cancelNotes.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                    {selectedReason === "7" && (
                      <Col md={12}>
                        <Form.Group controlId="otherReason">
                          <Form.Label>Other Reason</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={otherReason}
                            placeholder="Max character: 255"
                            onChange={(e) => setOtherReason(e.target.value)}
                          />
                          {errors.cancelNotes && (
                            <span className="dh-txt-danger">
                              {errors.cancelNotes.message}
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                    )}
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Group as={Col} controlId="formMarketName">
                        <Form.Label>Signature*</Form.Label>
                        <Form.Control
                          type="text"
                          size="lg"
                          {...register("cancelESign", {
                            required: "Please enter your Signature",
                          })}
                        />
                        {/* <Form.Control.Feedback type="invalid">
                            Please enter Signature.
                            </Form.Control.Feedback> */}
                        {errors.cancelESign && (
                          <span className="dh-txt-danger">
                            {errors.cancelESign.message}
                          </span>
                        )}
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Label>
                        Note: Selected shares under this agreement will be
                        cancelled
                      </Form.Label>
                    </Col>
                  </Row>
                </DialogContent>
                <DialogActions>
                  <CustomButton variant="contained" type="submit">
                    Submit
                  </CustomButton>
                </DialogActions>
              </form>
            </BootstrapDialog>
          </div>
        </>
      )}
    </Box>
  );
}
CancelFormComponent.propTypes = {
  getCancelAgreementList: PropTypes.func.isRequired,
  //getUpdate:PropTypes.object.isRequired,
  updateCancelAgreement: PropTypes.func.isRequired,
  getDocument: PropTypes.object.isRequired,
  getDocumentList: PropTypes.object.isRequired,
  getCSADoc: PropTypes.func,
  csaSignup: PropTypes.object,
  showForm: PropTypes.bool,
  setShowForm: PropTypes.object,
  refreshKey: PropTypes.func.isRequired,
  userAuth: PropTypes.object.isRequired,
  reset: PropTypes.bool,
};
function mapDispatchToProps(dispatch) {
  return {
    getCancelAgreementList: (payload) =>
      dispatch(actionType.getCancelAgreementList(payload)),
    refreshKey: () => dispatch(refreshKey()),
    updateCancelAgreement: (payload) =>
      dispatch(actionType.updateCancelAgreement(payload)),
    getDocument: (payload) => dispatch(actionType.getDocument(payload)),
    getCSADoc: (payload) => dispatch(actionType.getCSADoc(payload)),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CancelFormComponent);
