import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  IconButton,
  Tooltip,
  Select,
  MenuItem,
  Avatar,
  Stack,
  Grid,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { AUTH_API, headers } from "../../config";
import axios from "axios";
import CommonLoading from "../common/components/commonLoading";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { DataGrid } from "@mui/x-data-grid";
import { Col, Row } from "react-bootstrap";
import dayjs from "dayjs";
import useLoader from "../../hooks/useLoader";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
  },
  typography: {
    minWidth: "8rem",
    fontSize: "14px !important",
    fontWeight: "700 !important",
    textAlign: "right",
  },
  formControl: {
    width: "35rem",
  },
  subFormControl: {
    width: "12.5rem",
  },
  formHelperText: {
    marginLeft: "0 !important",
  },
  dialogContent: {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    padding: "15px 25px !important",
  },
}));

let userDetails = sessionStorage.getItem("userLogin")
  ? JSON.parse(sessionStorage.getItem("userLogin"))
  : {};

const AccessRights = () => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const { openLoader, closeLoader } = useLoader();

  const formvalidation = yup.object({
    firstName: yup.string().required("Please enter First Name"),
    lastName: yup.string().required("Please enter Last Name"),
    email: yup.string().required("Please enter Email ID"),
    password: yup.string().required("Please enter Password"),
    confirmPassword: yup
      .string()
      .required("Please enter Confirm Password")
      .oneOf([yup.ref("password"), null], "Passwords do not match"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: userDetails.role ?? "",
      staffList: [],
    },
    validationSchema: formvalidation,
    onSubmit: (values) => {
      postStaffInfo(values);
    },
    enableReinitialize: true,
  });

  const postStaffInfo = async (values) => {
    if (formik.dirty) {
      try {
        openLoader("post_staff_info");
        let payload = {
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          password: values.password,
          role: values.role,
        };
        const res = await axios.post(AUTH_API + "/createAdmin", payload, {
          headers: headers,
        });
        if (res && res.data) {
          handleClose();
        }
      } catch (error) {
        console.log(error);
      } finally {
        closeLoader("post_staff_info");
      }
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    getStaffList();
  }, []);

  const getStaffList = async () => {
    try {
      openLoader("get_staff_list");
      const res = await axios.post(
        AUTH_API + `/getAdmins`,
        {},
        {
          headers: headers,
        }
      );
      if (res && res.data) {
        formik.setFieldValue("staffList", res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("get_staff_list");
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const COLUMNS = [
    {
      field: "staffName",
      headerName: "Staff Name",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <>
            <Avatar sx={{ width: "2rem", height: "2rem", fontSize: "14px" }}>
              {`${row?.firstName?.charAt(0) ?? "NU"}${
                row?.lastName?.charAt(0) ?? ""
              }`}
            </Avatar>
            &nbsp; &nbsp;
            {row?.firstName ?? ""} {row?.lastName ?? ""}
          </>
        );
      },
    },
    {
      field: "email",
      headerName: "Email ID",
      flex: 1,
    },
    {
      field: "createTimeStamp",
      headerName: "Created Date",
      flex: 1,
      valueGetter({ row }) {
        return row?.createTimeStamp
          ? dayjs(row?.createTimeStamp).format("YYYY-MM-DD")
          : null;
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        return (
          <>
            {params.row.status === "ACTIVE" ? (
              <Button
                variant="text"
                color="success"
                startIcon={<CheckCircleOutlineIcon />}
              >
                Active
              </Button>
            ) : params.row.status === "INACTV" ? (
              <Button
                variant="text"
                color="error"
                startIcon={<HighlightOffIcon />}
              >
                In Active
              </Button>
            ) : null}
          </>
        );
      },
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: ({ row }) => {
        return (
          <Select
            className={classes.subFormControl}
            size="small"
            name="accessRights"
            value={row.role}
            onChange={(event) => {
              handleRoleChange(row.id, event.target.value);
            }}
          >
            <MenuItem value={"ADMIN"}>Staff</MenuItem>
            <MenuItem value={"STAFF"}>Staff Superuser</MenuItem>
          </Select>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          <IconButton>
            <Tooltip title="Edit" placement="right">
              <ModeEditOutlineOutlinedIcon />
            </Tooltip>
          </IconButton>
          <IconButton>
            <Tooltip title="Delete" placement="right">
              <DeleteOutlineOutlinedIcon />
            </Tooltip>
          </IconButton>
        </>
      ),
    },
  ];

  const handleRoleChange = (rowId, newRole) => {
    const staffIndex = formik.values.staffList.findIndex(
      (staff) => staff.id === rowId
    );
    if (staffIndex !== -1) {
      const path = `staffList[${staffIndex}].role`;
      formik.setFieldValue(path, newRole);
    }
  };

  const generateUniqueID = () => {
    return Date.now().toString(36) + Math.random().toString(36).substring(2);
  };

  const getRowId = (row) => {
    if (!row.id) {
      row.id = generateUniqueID();
    }
    return row.id;
  };

  return (
    <>
      <Box sx={{ width: "100%", padding: "30px" }}>
        <Stack
          direction="row"
          sx={{ justifyContent: "space-between", alignItems: "center" }}
          mb={2}
        >
          <Typography
            variant="h5"
            display="inline"
            className="eligibilityText text-capitalize"
            gutterBottom
            component="div"
          >
            Access Rights
          </Typography>
          <Button
            size="small"
            variant="contained"
            color="success"
            startIcon={<PersonAddAltIcon />}
            onClick={handleClickOpen}
          >
            Add User
          </Button>
        </Stack>
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Add Staff</DialogTitle>
          <DialogContent>
            <Grid container xs={12} spacing={2}>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
                xs={12}
              >
                <Typography className={classes.typography}>
                  First Name
                </Typography>
                <TextField
                  name="firstName"
                  sx={{ width: "100%" }}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
                xs={12}
              >
                <Typography className={classes.typography}>
                  Last Name
                </Typography>
                <TextField
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  sx={{ width: "100%" }}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
                xs={12}
              >
                <Typography className={classes.typography}>Email ID</Typography>
                <TextField
                  name="email"
                  type="email"
                  value={formik.values.email}
                  sx={{ width: "100%" }}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
                xs={12}
              >
                <Typography className={classes.typography}>
                  New Password
                </Typography>
                <TextField
                  name="password"
                  type="password"
                  sx={{ width: "100%" }}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.password && Boolean(formik.errors.password)
                  }
                  helperText={formik.touched.password && formik.errors.password}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Grid>
              <Grid
                item
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "10px",
                }}
                xs={12}
              >
                <Typography className={classes.typography}>
                  Confirm Password
                </Typography>
                <TextField
                  name="confirmPassword"
                  type="password"
                  sx={{ width: "100%" }}
                  value={formik.values.confirmPassword}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.confirmPassword &&
                    Boolean(formik.errors.confirmPassword)
                  }
                  helperText={
                    formik.touched.confirmPassword &&
                    formik.errors.confirmPassword
                  }
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancel</Button>
            <Button type="submit" onClick={formik.handleSubmit}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>

        <Box flexGrow={1}>
          <DataGrid
            getRowId={getRowId}
            autoHeight
            columns={COLUMNS}
            rows={formik.values?.staffList ?? []}
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            sx={{
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#E2E2E4",
                color: "#FFFFFF",
              },
              "& .MuiDataGrid-columnHeaderTitle": {
                fontWeight: "700",
                color: "#6C6C6C",
              },
              "& .MuiDataGrid-row": {
                color: "black",
              },
              "& .MuiTablePagination-displayedRows": {
                marginTop: 0,
                marginBottom: 0,
              },
            }}
            localeText={{ noRowsLabel: "No data found" }}
          />
        </Box>

        {/* <Row>
          <Col xs={5}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Staff List
                    </TableCell>
                    <TableCell style={{ fontWeight: "bold" }}>
                      Access Rights
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {map(formik.values.staffList, (_item, index) => (
                    <TableRow>
                      <TableCell>
                        {`${_item?.firstName} ${_item.lastName}`}
                      </TableCell>
                      <TableCell>
                        <Select
                          className={classes.subFormControl}
                          size="small"
                          name="accessRights"
                          value={_item.role}
                        >
                          <MenuItem value={"ADMIN"}>Staff</MenuItem>
                          <MenuItem value={"staff"}>Staff Superuser</MenuItem>
                        </Select>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Col>
        </Row> */}
        <Row>
          <Col xs={12}>
            <Stack direction="row">
              <Button
                sx={{ ml: "auto", mt: "1rem" }}
                variant="contained"
                color="success"
                type="submit"
                style={{ textTransform: "none", width: "100px" }}
              >
                Update
              </Button>
            </Stack>
          </Col>
        </Row>
      </Box>
    </>
  );
};

export default AccessRights;
