import React, { useCallback, useEffect, useMemo, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

import useTranslator from "../../hooks/useTranslator";
import { useNavigate } from "react-router-dom";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import LinkIcon from "@mui/icons-material/Link";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { map } from "lodash";
import { ReactComponent as FARM } from "../../assets/images/farm.svg";
import { ReactComponent as FARM_STAND } from "../../assets/images/farm_stand.svg";
import { ReactComponent as MARKET_BOOTH } from "../../assets/images/market_booth.svg";
import { ReactComponent as MOBILE_MARKET } from "../../assets/images/mobile_market.svg";
import { ReactComponent as MARKET_MANAGER } from "../../assets/images/market_manager.svg";
import { ReactComponent as WELCOME } from "../../assets/images/welcome_dash.svg";
import ECHIP from "../../assets/images/SPF_Card.svg";
import axios from "axios";
import { AUTH_API, headers } from "../../config";
import { DataGrid } from "@mui/x-data-grid";
import EjectOutlinedIcon from "@mui/icons-material/EjectOutlined";
import HistoryOutlinedIcon from "@mui/icons-material/HistoryOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { STATUS_INFO } from "./constant";
import dayjs from "dayjs";
import { connect } from "react-redux";
import { fetchDemographicDashboardData } from "../../redux/actionTypes/seniorInfo";
import useLoader from "../../hooks/useLoader";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: "20px",
    minHeight: "270px",
  },
  label: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C !important",
  },
  typography: {
    fontSize: "16px !important",
    fontWeight: "700 !important",
  },
  variant: {
    fontSize: "18px !important",
    fontWeight: "500 !important",
  },
  variant1: {
    fontSize: "26px !important",
    fontWeight: "700 !important",
    letterSpacing: "2px",
  },
  variant2: {
    fontSize: "28px !important",
    fontWeight: "500 !important",
  },
  variant3: {
    fontSize: "45px !important",
    fontWeight: "700 !important",
  },
  button: {
    borderRadius: "25px !important",
  },
  actionColumn: {
    color: "#44A0E3",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    padding: "0px !important",
  },
}));

const ApplyBenefits = (props) => {
  const transProps = useTranslator(
    [
      "myApp",
      "appId",
      "fstName",
      "lstName",
      "validFrm",
      "welcomeStatment",
      "appHistory",
      "createdBy",
      "validTo",
      "status",
      "myInfo",
      "name",
      "dob",
      "gender",
      "email",
      "phnNo",
      "address",
      "welcomeKfreshTtile",
      "benefitStatus",
      "holderName",
      "validFrom",
      "validThru",
      "history",
      "applyNow",
      "discard",
      "findMarket",
      "withdraw",
      "farmStand",
      "balance",
      "marketBooth",
      "mobileMarket",
      "marketManager",
      "spfCard",
      "cardNo",
      "eligibilityInfo",
      "next",
      "notApplicable",
      "approved",
      "fresh",
    ],
    "applyBenefits"
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [rows, setRows] = useState([]);
  const { openLoader, closeLoader } = useLoader();
  const [isDailog, setIsDailog] = useState(false);
  const [history, setHistory] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [message, setMessage] = useState("");
  const [disableNext, setDisableNext] = useState(false);

  let userDetails = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : {};

  const markets = [
    // {
    //   label: "Farm",
    //   image: <FARM />,
    // },
    {
      label: transProps.farmStand,
      image: <FARM_STAND />,
    },
    {
      label: transProps.marketBooth,
      image: <MARKET_BOOTH />,
    },
    // {
    //   label: transProps.mobileMarket,
    //   image: <MOBILE_MARKET />,
    // },
    // {
    //   label: transProps.marketManager,
    //   image: <MARKET_MANAGER />,
    // },
  ];

  const columns = useMemo(() => [
    {
      field: "appNumber",
      headerName: transProps.appId,
      flex: 1,
    },
    {
      field: "firstName",
      headerName: transProps.fstName,
      flex: 1,
    },
    {
      field: "lastName",
      headerName: transProps.lstName,
      flex: 1,
    },
    {
      field: "validFrom",
      headerName: transProps.validFrom,
      flex: 1,
    },

    {
      field: "validTo",
      headerName: transProps.validTo,
      flex: 1,
    },
    {
      field: "createdBy",
      headerName: transProps.createdBy,
      flex: 1,
    },
    {
      field: "status",
      headerName: transProps.status,
      flex: 1,
      renderCell: (params) => (
        <IconButton
          onClick={(e) =>
            handleRenewal(e, params, params?.row?.status !== "PE")
          }
        >
          {params?.row?.status === "PE" && (
            <EjectOutlinedIcon
              color="primary"
              sx={{ transform: "rotate(90deg)" }}
            />
          )}
          <Link
            fontSize={14}
            underline="none"
            color={
              params?.row?.status === "AP"
                ? "success"
                : params?.row?.status === "DE"
                ? "error"
                : params?.row?.status === "PE"
                ? "primary"
                : params?.row?.status === "WL"
                ? "#ed6c02"
                : "primary"
            }
          >
            {STATUS_INFO(params.row?.status)}
          </Link>
        </IconButton>
      ),
    },
  ]);

  const handleRenewal = (event, params, isStatus) => {
    navigate(`/yourInfo/${params?.row?.appNumber}`, {
      state: { disabled: isStatus, clientId: params?.row?.clientId },
    });
  };

  useEffect(() => {
    getDashboardInfo();
  }, []);

  const getDashboardInfo = async () => {
    try {
      openLoader("seniors_dashboard");
      const res = await axios.post(
        AUTH_API + `/seniors/dashboard/${userDetails?.email}`,
        {},
        {
          headers: headers,
        }
      );
      if (res && res.data) {
        setData(res.data);
        props.fetchDemographicDashboardData(res.data);
        let result = res.data?.applicationModel?.[0];
        let obj = [
          {
            id: 1,
            appNumber: result?.appNumber ?? "",
            status: result?.appStatus ?? "",
            firstName:
              result?.demographicData?.firstName ?? result?.firstName ?? "",
            lastName:
              result?.demographicData?.lastName ?? result?.lastName ?? "",
            validFrom: result?.apProgramEligibility?.eligiblityStartDt ?? "",
            validTo: result?.apProgramEligibility?.eligiblityEndDt ?? "",
            createdBy: result?.addressmodel?.emailId ?? "",
            clientId: result?.demographicData?.clientId ?? "",
          },
        ];
        setRows(res.data?.applicationModel?.length > 0 ? obj : []);
        getHistoryData(res.data?.applicationModel?.slice(1) ?? []);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("seniors_dashboard");
    }
  };

  const getHistoryData = (data) => {
    let result = map(data, (item, index) => {
      return {
        id: index,
        appNumber: item?.appNumber ?? "",
        status: item?.appStatus ?? "",
        firstName: item?.firstName ?? "",
        lastName: item?.lastName ?? "",
        validFrom: item?.apProgramEligibility?.eligiblityStartDt ?? "",
        validTo: item?.apProgramEligibility?.eligiblityEndDt ?? "",
        createdBy: item?.addressmodel?.emailId ?? "",
        clientId: item?.demographicData?.clientId ?? "",
      };
    });
    setHistoryData(result && result?.length > 0 ? result : []);
  };

  const handleApplyNow = async () => {
    try {
      openLoader("application_eligible");
      const res = await axios.post(
        AUTH_API +
          `/seniors/dashboard/application/eligible/${userDetails?.email}`,
        {},
        {
          headers: headers,
        }
      );
      if (res && res.data) {
        if (!res.data?.appNumber) {
          handleNext();
        } else {
          setIsDailog(true);
          res.data?.appNumber ? setDisableNext(true) : setDisableNext(false);
          setMessage(res.data.message);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("application_eligible");
    }
  };

  const handleNext = () => {
    navigate("/yourInfo");
  };

  const {
    demographicData,
    addressmodel,
    apProgramEligibility,
    program,
    appNumber,
    appStatus,
  } = data?.applicationModel?.[0] ?? {};
  const { firstName, lastName, gender, dob } = demographicData ?? {};
  const { eligiblityStartDt, eligiblityEndDt } = apProgramEligibility ?? {};
  const { programCd, programText, ebtCardBalance, ebtCardNumber } =
    apProgramEligibility ?? {};
  const {
    addressLine1,
    addressLine2,
    city,
    stateCd,
    zipCd,
    contactNumber,
    emailId,
  } = addressmodel ?? {};

  const formatAddress = useCallback(
    (addressLine1, addressLine2, city, state, zipcode) => {
      const parts = [addressLine1, addressLine2, city, state, zipcode].filter(
        Boolean
      );
      return parts.join(", ");
    },
    [data]
  );

  const searchFarmers = () => {
    navigate(`/senior/kfreshfep?appId=${appNumber}`);
  };

  return (
    <>
      <Dialog
        title="Eligibility Info"
        open={isDailog}
        onClose={() => setIsDailog(false)}
        sx={{ padding: 2 }}
      >
        <DialogContent>
          <Box sx={{ width: "500px", margin: 1 }}>
            <Typography className={classes.variant} marginBottom={2}>
              {transProps.eligibilityInfo}
            </Typography>
            <Typography className={classes.typography} mb={2}>
              {message ? message : "No messages found."}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="outlined" onClick={() => setIsDailog(false)}>
              {transProps.discard}
            </Button>
            <Button
              variant="contained"
              disabled={disableNext}
              onClick={handleNext}
            >
              {transProps.next}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Dialog
        title={transProps.appHistory}
        open={history}
        onClose={() => setHistory(false)}
        sx={{ padding: 2 }}
        PaperProps={{
          style: { maxWidth: "80%", width: "100%" },
        }}
      >
        <DialogContent>
          <Box sx={{ margin: 1 }}>
            <Typography className={classes.variant} marginBottom={2}>
              {transProps.appHistory}
            </Typography>
            <Typography className={classes.typography} mb={2}>
              <DataGrid
                getRowId={(row) => row.id}
                autoHeight
                rowSelection={false}
                rows={historyData ?? []}
                columns={columns}
                components={{
                  Pagination: () => null,
                }}
                sx={{
                  backgroundColor: "white",
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: "16px",
                    fontWeight: "700",
                    padding: "0px 10px",
                    color: "#000000",
                  },
                  "& .MuiDataGrid-row": {
                    fontSize: "14px",
                    color: "black",
                    padding: "0px 10px",
                  },
                  "& .css-wop1k0-MuiDataGrid-footerContainer": {
                    minHeight: "0 !important",
                  },
                }}
                localeText={{ noRowsLabel: "No data found" }}
              />
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button variant="outlined" onClick={() => setHistory(false)}>
              {transProps.discard}
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>

      <Grid container sx={{ padding: "15px" }} spacing={2}>
        <Grid item xs={12}>
          <Paper
            className={classes.paper}
            elevation={3}
            sx={{
              minHeight: "100px !important",
              background:
                "linear-gradient(25deg, rgb(45 107 3 / 82%) 0%, rgb(218 233 209) 100%)",
              color: "#fff",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              "@media (max-width:600px)": {
                flexDirection: "column",
                justifyContent: "center",
              },
            }}
          >
            <Stack
              direction={"column"}
              sx={{
                textAlign: "left",
                "@media (max-width:600px)": {
                  textAlign: "center",
                },
              }}
            >
              <Typography className={classes.variant1}>
                {transProps.welcomeKfreshTtile}{" "}
              </Typography>
              <Typography
                className={classes.typography}
                pt={2}
                fontWeight={"400 !important"}
                fontSize={"1.25rem !important"}
              >
                {transProps.welcomeStatment}
              </Typography>
            </Stack>
            <Stack
              direction={"row"}
              sx={{
                width: "150px",
                height: "80px",
                position: "relative",
                top: "-50px",
                "@media (max-width:600px)": {
                  order: -1,
                  marginBottom: "1rem",
                },
              }}
            >
              <WELCOME />
            </Stack>
          </Paper>
        </Grid>
        {rows && rows?.length > 0 && (
          <Grid item xs={12}>
            <Paper
              className={classes.paper}
              elevation={3}
              sx={{
                minHeight: "50px !important",
              }}
            >
              <Typography className={classes.variant1} variant="h5" mb={1}>
                {transProps.myApp}
              </Typography>
              <DataGrid
                getRowId={(row) => row.id}
                autoHeight
                rowSelection={false}
                rows={rows ?? []}
                columns={columns}
                components={{
                  Pagination: () => null,
                }}
                sx={{
                  backgroundColor: "white",
                  "& .MuiDataGrid-columnHeaderTitle": {
                    fontSize: "16px",
                    fontWeight: "700",
                    padding: "0px 10px",
                    color: "#000000",
                  },
                  "& .MuiDataGrid-row": {
                    fontSize: "14px",
                    color: "black",
                    padding: "0px 10px",
                  },
                  "& .css-wop1k0-MuiDataGrid-footerContainer": {
                    minHeight: "0 !important",
                  },
                }}
                localeText={{ noRowsLabel: "No data found" }}
              />
            </Paper>
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper} elevation={3}>
            <Typography className={classes.variant1} pb={1}>
              {transProps.myInfo}
            </Typography>
            <TableContainer>
              <Table
                sx={{
                  td: {
                    padding: 0,
                    paddingBottom: "8px",
                    border: 0,
                  },
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell className={classes.label} width="30%">
                      {transProps.name}
                    </TableCell>
                    <TableCell className={classes.typography}>
                      {`${firstName ?? userDetails?.firstName ?? ""} ${
                        lastName ?? userDetails?.lastName ?? ""
                      }`}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label} width="30%">
                      {transProps.dob}
                    </TableCell>
                    <TableCell className={classes.typography}>
                      {dob ? dayjs(dob).format("MM/DD/YYYY") : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label} width="30%">
                      {transProps.gender}
                    </TableCell>
                    <TableCell className={classes.typography}>
                      {gender ?? ""}
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                  <TableCell className={classes.label} width="30%">
                    Martial Status
                  </TableCell>
                  <TableCell className={classes.typography}>
                    {maritalStatus ?? ""}
                  </TableCell>
                </TableRow> */}
                  {/* <TableRow>
                    <TableCell className={classes.label} width="30%">
                      PIN No
                    </TableCell>
                    <TableCell className={classes.typography}>
                      {pinNo ? "****" : "NA"}
                    </TableCell>
                  </TableRow> */}
                  <TableRow>
                    <TableCell className={classes.label} width="30%">
                      {transProps.email}
                    </TableCell>
                    <TableCell className={classes.typography}>
                      {emailId ?? userDetails?.email ?? ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label} width="30%">
                      {transProps.phnNo}
                    </TableCell>
                    <TableCell className={classes.typography}>
                      {contactNumber ?? ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className={classes.label} width="30%">
                      {transProps.address}
                    </TableCell>
                    <TableCell className={classes.typography}>
                      {formatAddress(
                        addressLine1,
                        addressLine2,
                        city,
                        stateCd,
                        zipCd
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper} elevation={3}>
            <Stack spacing={1}>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Stack direction={"row"}>
                  <Typography className={classes.variant1}>
                    {transProps.benefitStatus}
                  </Typography>
                </Stack>
                <Stack direction={"row"}>
                  <Button
                    sx={{
                      "& .css-1d6wzja-MuiButton-startIcon": {
                        marginRight: "2px !important",
                        marginTop: "0px",
                      },
                      marginRight: "0.55rem !important",
                    }}
                    startIcon={
                      <HistoryOutlinedIcon
                        sx={{ marginRight: "2px !important" }}
                      />
                    }
                    variant="text"
                    onClick={() => setHistory(true)}
                  >
                    {transProps.history}
                  </Button>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={handleApplyNow}
                    // disabled={appStatus === "AP" || appStatus === "WL"}
                    disabled={["WL"].includes(appStatus)}
                  >
                    {transProps.applyNow}
                  </Button>
                </Stack>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                pt={5}
              >
                <Stack direction={"column"}>
                  <Typography className={classes.label}>
                    {transProps.holderName}
                  </Typography>
                  <Typography
                    className={classes.variant1}
                    sx={{ fontSize: "24px !important" }}
                  >{`${firstName ?? ""} ${lastName ?? ""}`}</Typography>
                </Stack>
                {appStatus && (
                  <Stack direction={"column"}>
                    <Button
                      color={
                        appStatus === "AP"
                          ? "success"
                          : appStatus === "DE"
                          ? "error"
                          : appStatus === "PE"
                          ? "warning"
                          : appStatus === "WL"
                          ? "warning"
                          : "primary"
                      }
                      sx={{
                        "& .css-1d6wzja-MuiButton-startIcon": {
                          marginRight: "2px !important",
                          marginTop: "0px",
                        },
                      }}
                      startIcon={<CheckCircleOutlineIcon />}
                      variant="outlined"
                    >
                      {STATUS_INFO(appStatus)}
                    </Button>
                  </Stack>
                )}
              </Stack>
              <Stack direction={"row"} justifyContent={"space-between"} pt={5}>
                <Stack direction={"row"} spacing={1}>
                  <Typography className={classes.label}>
                    {transProps.validFrom}
                  </Typography>
                  <Typography className={classes.typography}>
                    {eligiblityStartDt ?? transProps.notApplicable}
                  </Typography>
                </Stack>
                <Stack direction={"row"} spacing={1}>
                  <Typography className={classes.label}>
                    {transProps.validThru}
                  </Typography>
                  <Typography className={classes.typography}>
                    {eligiblityEndDt ?? transProps.notApplicable}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper} elevation={3}>
            <Typography className={classes.variant1} pb={1}>
              {transProps.findMarket}
            </Typography>
            <Stack
              direction={"row"}
              spacing={1}
              alignItems={"center"}
              justifyContent={"center"}
            >
              {map(markets, (element) => (
                <Stack
                  border={1}
                  borderColor={"#6C6C6C"}
                  borderRadius={2}
                  p={1}
                  alignItems={"center"}
                  justifyContent={"center"}
                  minHeight={"115px"}
                >
                  {element.image}
                  <Typography
                    className={classes.label}
                    mt={1}
                    textAlign={"center"}
                  >
                    {element.label}
                  </Typography>
                </Stack>
              ))}
            </Stack>
            <Stack direction={"row"} justifyContent={"center"} mt={3}>
              <Button
                className={classes.button}
                variant="contained"
                color="success"
                onClick={searchFarmers}
                // disabled={appStatus !== "AP"}
              >
                {transProps.findMarket}
              </Button>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper
            className={classes.paper}
            elevation={3}
            sx={{
              background: "#F8F8F8",
            }}
          >
            <Stack spacing={1}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography className={classes.variant1}>
                  {transProps.spfCard}
                </Typography>
                <Typography className={classes.variant} color={"#0F4E96"}>
                  {transProps.withdraw}
                </Typography>
              </Stack>
              <Stack
                direction={"row"}
                justifyContent={"space-between"}
                pt={1}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://play.google.com/store/apps/details/MyMarketLink?id=com.novodiagroup.MyMarketLink"
                >
                  <img
                    src={ECHIP}
                    style={{
                      width: "60px",
                      height: "60px",
                      border: "1px solid #C2C2B7",
                      borderRadius: "5px",
                    }}
                  />
                </a>
                <Stack direction={"column"}>
                  <Typography className={classes.variant1} textAlign={"right"}>
                    {transProps.balance}
                  </Typography>
                  <Typography className={classes.variant3}>
                    ${ebtCardBalance ?? "0.00"}
                  </Typography>
                </Stack>
              </Stack>
              <Stack direction={"row"} pt={2} alignItems={"center"}>
                <Typography className={classes.label}>
                  {transProps.cardNo}{" "}
                </Typography>
                <Typography className={classes.variant1} pl={2}>
                  {ebtCardNumber
                    ? "**** **** ****" + ebtCardNumber.substring(14)
                    : transProps.notApplicable}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

ApplyBenefits.propTypes = {
  fetchDemographicDashboardData: PropTypes.func,
};

const mapDispatchToProps = (dispatch) => ({
  fetchDemographicDashboardData: (payload) =>
    dispatch(fetchDemographicDashboardData(payload)),
});

export default connect(undefined, mapDispatchToProps)(ApplyBenefits);
