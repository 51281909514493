import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  FormHelperText,
  Box,
  Dialog,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControl,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { State } from "./constant";
import { map } from "lodash";
import axios from "axios";
import { AUTH_API, headers } from "../../config";
import useTranslator from "../../hooks/useTranslator";
import useLkpTranslator from "../../hooks/useLkpTranslator";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MaskedInput from "react-text-mask";

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "20px !important",
    fontWeight: "700 !important",
    color: "#6C6C6C",
  },
  typography: {
    fontSize: "14px !important",
    fontWeight: "700 !important",
    // width: "30%",
    minWidth: "90px",
  },
  formHelperText: {
    marginLeft: "0 !important",
  },
}));

function TextMaskCustom(props) {
  const { ...other } = props;

  return (
    <MaskedInput
      {...other}
      mask={[
        "(",
        /\d/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      guide={false}
      placeholderChar={"\u2000"}
      showMask
    />
  );
}

const ContactInformation = () => {
  const { getLkpTranslation } = useLkpTranslator();

  const state = getLkpTranslation("lookUp.state", State);
  const identityOptions = [
    {
      lkpDesc: "ssn",
      lookupLongVal: "SSN",
    },
    {
      lkpDesc: "emailId",
      lookupLongVal: "Email ID",
    },
    {
      lkpDesc: "stateId",
      lookupLongVal: "State ID",
    },
    {
      lkpDesc: "contactNumber",
      lookupLongVal: "Phone No",
    },
  ];
  const transProps = useTranslator(
    [
      "pageTitle",
      "address1",
      "address2",
      "address1Label",
      "address2Label",
      "cityLabel",
      "zipCodeLabel",
      "phoneNolabel",
      "emailIdLabel",
      "city",
      "state",
      "zipCode",
      "phoneNo",
      "emailId",
      "goBack",
      "next",
    ],
    "contactInfo"
  );
  const classes = useStyles();
  const navigate = useNavigate();
  const params = useParams();
  const _appNumber = params?.appNumber ?? "";
  const _clientId = params?.clientId ?? null;
  const [maskSsn, setMaskSsn] = useState(true);
  const { openLoader, closeLoader } = useLoader();
  const [open, setOpen] = useState(false);
  const [smartyValue, setSmartyValue] = useState("");
  const [editData, setEditData] = useState({});
  const [clientId, setClientId] = useState(null);

  const location = useLocation();
  const disabled = location?.state?.disabled ?? false;

  let userDetails = sessionStorage.getItem("userLogin")
    ? JSON.parse(sessionStorage.getItem("userLogin"))
    : {};

  // const formvalidation = yup.object({
  //   addressLine1: yup.string().required("Please enter Address Line 1"),
  //   city: yup.string().required("Please enter City"),
  //   stateCd: yup.string().required("Please enter State"),
  //   zipCd: yup.string().required("Please enter Zip Code"),
  //   emailId: yup.string().required("Please enter Email ID"),
  //   identity: !userDetails?.email
  //     ? yup.string().required("Please enter Identity")
  //     : null,
  // });

  const createValidationSchema =  yup.object({
      addressLine1: yup.string().required("Please enter Address Line 1"),
      city: yup.string().required("Please enter City"),
      stateCd: yup.string().required("Please enter State"),
      zipCd: yup.string().required("Please enter Zip Code"),
  })

  // const createValidationSchema = (values) => {
  //   const schemaFields = {
  //     addressLine1: yup.string().required("Please enter Address Line 1"),
  //     city: yup.string().required("Please enter City"),
  //     stateCd: yup.string().required("Please enter State"),
  //     zipCd: yup.string().required("Please enter Zip Code"),
  //   };

  //   if (!values.emailId) {
  //     schemaFields.identity = yup.string().required("Please enter Identity");
  //   }

  //   if (values.identity === "emailId") {
  //     schemaFields.emailId = yup.string().required("Please enter  Email ID");
  //   } else {
  //     schemaFields.emailId = yup.string().nullable();
  //   }

  //   if (values.identity === "ssn") {
  //     schemaFields.ssn = yup.string().required("Please enter SSN");
  //   } else {
  //     schemaFields.ssn = yup.string().nullable();
  //   }

  //   if (values.identity === "contactNumber") {
  //     schemaFields.contactNumber = yup
  //       .string()
  //       .required("Please enter Phone No");
  //   } else {
  //     schemaFields.contactNumber = yup.string().nullable();
  //   }

  //   return yup.object(schemaFields);
  // };

  const formik = useFormik({
    initialValues: {
      addressLine1: editData?.addressLine1 ?? "",
      addressLine2: editData?.addressLine2 ?? "",
      city: editData?.city ?? "",
      stateCd: editData?.stateCd ?? "",
      county: editData?.county ?? "",
      zipCd: editData?.zipCd ?? "",
      contactNumber: editData?.contactNumber ?? "",
      emailId: editData?.emailId ?? userDetails?.email ?? "",
      clientId: editData?.clientId ?? Number(_clientId) ?? null,
      clAddressId: editData?.clAddressId ?? null,
      smartAddress: "",
      identity: editData?.identity ?? "",
      ssn: editData?.ssn ?? "",
      stateId: editData?.stateId ?? "",
    },
    validationSchema:  disabled ? null : createValidationSchema,
    onSubmit: (values) => {
      // postContactInfo(values);
      onNext(values);
    },
    enableReinitialize: true,
  });

  const postContactInfo = async (values) => {
    if (formik.dirty) {
      try {
        openLoader("senior_contactinfo");
        let payload = {
          dirtyStatus: clientId ? "UPDATE" : "INSERT",
          addressLine1:
            values.smartAddress === "0"
              ? values.addressLine1
              : smartyValue?.addressLine1,
          addressLine2: values.addressLine2,
          city: values.smartAddress === "0" ? values.city : smartyValue?.city,
          stateCd:
            values.smartAddress === "0" ? values.stateCd : smartyValue?.state,
          zipCd:
            values.smartAddress === "0" ? values.zipCd : smartyValue?.zipcode,
          contactNumber: values.contactNumber,
          emailId: values.emailId,
          verificationInd: "Y",
          clientId: values.clientId,
          clAddressId: values.clAddressId,
        };
        // let payload = {
        //   dirtyStatus: clientId ? "UPDATE" : "INSERT",
        //   addressLine1: values.addressLine1,
        //   addressLine2: values.addressLine2,
        //   city: values.city,
        //   stateCd: values.stateCd,
        //   zipCd: values.zipCd,
        //   contactNumber: values.contactNumber,
        //   emailId: values.emailId,
        //   verificationInd: "Y",
        //   clientId: values.clientId,
        //   clAddressId: values.clAddressId,
        // };
        const res = await axios.post(
          AUTH_API + "/seniors/contactInfo",
          payload,
          {
            headers: headers,
          }
        );
        if (res && res.data) {
          toast.success(
            clientId
              ? "Contact information Update Successfully"
              : "Contact information Added Successfully"
          );
          handleNext();
        }
      } catch (error) {
        console.log(error);
        toast.success(
          clientId
            ? "Contact information Update Failed"
            : "Contact information Added Failed"
        );
      } finally {
        closeLoader("senior_contactinfo");
      }
    } else {
      handleNext();
    }
  };

  // const postContactInfo = async (values) => {
  //   try {
  //     setIsLoading(true);
  //     let payload = {
  //       dirtyStatus: "insert",
  //       addressLine1:
  //         values.smartAddress === "0"
  //           ? values.addressLine1
  //           : smartyValue?.addressLine1,
  //       addressLine2: values.addressLine2,
  //       city: values.smartAddress === "0" ? values.city : smartyValue?.city,
  //       stateCd:
  //         values.smartAddress === "0" ? values.stateCd : smartyValue?.state,
  //       zipCd:
  //         values.smartAddress === "0" ? values.zipCd : smartyValue?.zipcode,
  //       contactNumber: values.contactNumber,
  //       emailId: values.emailId,
  //       verificationInd: "Y",
  //       clientId: values.clientId,
  //     };
  //     const res = await axios.post(AUTH_API + "/seniors/contactInfo", payload, {
  //       headers: headers,
  //     });
  //     if (res && res.data) {
  //       handleNext();
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };
  useEffect(() => {
    getContactInfo();
  }, []);

  const getContactInfo = async () => {
    try {
      openLoader("seniors-contact-info");
      const res = await axios.post(
        AUTH_API + `/seniors/contactInfo/${_appNumber}`,
        {},
        {
          headers: headers,
        }
      );
      if (res && res.data) {
        setEditData(res.data);
        if (res.data?.clientId) {
          setClientId(res.data?.clientId ?? "");
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("seniors-contact-info");
    }
  };

  const handleGoBack = () => {
    if (_appNumber) {
      navigate(`/yourInfo/${_appNumber}`, {
        state: { disabled: disabled, clientId: _clientId },
      });
    } else {
      navigate(`/yourInfo`, {
        state: { disabled: disabled, clientId: _clientId },
      });
    }
  };

  const handleNext = () => {
    navigate(`/programIncome/${_appNumber}/${_clientId}`, {
      state: { disabled: disabled },
    });
  };

  const postSmarty = async (values) => {
    try {
      openLoader("smarty-street");
      let payload = {
        addressId: 0,
        zip4code: "",
        addressVerifiedYN: "N",
        institutionYN: "N",
        vendorId: 0,
        homeAddressYN: "N",
        status: true,
        addressLine1: values.addressLine1,
        addressLine2: values.addressLine2,
        city: values.city,
        state: values.stateCd,
        zipcode: values.zipCd,
        county: "",
        district: "",
      };
      const smartyHeader = {
        Role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
        uid: "localUser",
        Uniqueid: "7c438d573165cdd1552231ed3bdd33",
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_SMARTY_STREET_URL}/verification/address`,
        payload,
        {
          headers: smartyHeader,
        }
      );

      if (res && res.data) {
        setOpen(true);
        if (res.data.results[0] === 0) {
          setSmartyValue([]);
        } else {
          setSmartyValue(res?.data?.results[0]);
        }
        // handleNext();
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("smarty-street");
    }
  };


  const onNext = async () => {
    if (formik.dirty) {
      if (disabled) {
        handleNext();
      } else {
        await formik.validateForm().then((errors) => {
          const isFormValid = Object.keys(errors).length === 0;
          if (isFormValid) {
            postSmarty(formik.values);
          } else {
            formik.setTouched(
              Object.keys(formik.values).reduce((acc, key) => {
                acc[key] = true;
                return acc;
              }, {})
            );
          }
        });
      }
    } else {
      handleNext();
    }
  };

  return (
    <>
      <Dialog
        title="Verify the address"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ padding: 2 }}
      >
        <DialogContent>
          <Box sx={{ width: "500px", margin: 2 }}>
            <Typography variant="h4" marginBottom={2}>
              Address Information
            </Typography>
            <Grid m={2}>
              <RadioGroup
                {...formik.getFieldProps("smartAddress")}
                defaultValue={smartyValue?.addressLine1 ? 1 : 0}
              >
                <Typography variant="h5" color="gray">
                  Original Address:
                </Typography>
                <FormControlLabel
                  name="smartAddress"
                  value={0}
                  control={<Radio />}
                  label={
                    formik.values.addressLine1 +
                    " " +
                    formik.values.city +
                    " " +
                    formik.values.stateCd +
                    " " +
                    formik.values.zipCd
                  }
                />
                <Typography variant="h5" color="gray">
                  Suggested Address:
                </Typography>
                <FormControlLabel
                  name="smartAddress"
                  value={1}
                  control={<Radio disabled={!smartyValue?.addressLine1} />}
                  label={
                    smartyValue?.addressLine1
                      ? smartyValue?.addressLine1 +
                        " " +
                        smartyValue?.city +
                        " " +
                        smartyValue?.state +
                        " " +
                        smartyValue?.zipcode
                      : "-"
                  }
                />
              </RadioGroup>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button variant="outlined" onClick={() => setOpen(false)}>
                Discard
              </Button>
              <Button
                variant="contained"
                onClick={() => postContactInfo(formik.values)}
              >
                Save And Next
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <Typography className={classes.title}>{transProps.pageTitle}</Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} md={6}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            spacing={1}
          >
            <Typography
              sx={{
                textAlign: { xs: "start", sm: "right" },
              }}
              className={classes.typography}
            >
              {transProps.address1} <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="addressLine1"
              size="small"
              placeholder={transProps.address1Label}
              value={formik.values.addressLine1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.addressLine1 &&
                Boolean(formik.errors.addressLine1)
              }
              helperText={
                formik.touched.addressLine1 && formik.errors.addressLine1
              }
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              disabled={disabled}
            ></TextField>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            spacing={1}
          >
            <Typography
              sx={{
                textAlign: { xs: "start", sm: "right" },
              }}
              className={classes.typography}
            >
              {transProps.address2}
            </Typography>
            <TextField
              fullWidth
              name="addressLine2"
              size="small"
              placeholder={transProps.address2Label}
              value={formik.values.addressLine2}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={disabled}
            ></TextField>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            spacing={1}
          >
            <Typography
              sx={{
                textAlign: { xs: "start", sm: "right" },
              }}
              className={classes.typography}
            >
              {transProps.city} <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              fullWidth
              name="city"
              size="small"
              placeholder={transProps.cityLabel}
              value={formik.values.city}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.city && Boolean(formik.errors.city)}
              helperText={formik.touched.city && formik.errors.city}
              FormHelperTextProps={{
                className: classes.formHelperText,
              }}
              disabled={disabled}
            ></TextField>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.state} <span style={{ color: "red" }}>*</span>
                </Typography>
                <Box width="100%" overflow="hidden">
                  <Select
                    fullWidth
                    name="stateCd"
                    size="small"
                    value={formik.values.stateCd}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.stateCd && Boolean(formik.errors.stateCd)
                    }
                    helperText={formik.touched.stateCd && formik.errors.stateCd}
                    FormHelperTextProps={{
                      className: classes.formHelperText,
                    }}
                    disabled={disabled}
                  >
                    {map(state, (item) => (
                      <MenuItem value={item.lkpDesc}>
                        {item.lookupLongVal}
                      </MenuItem>
                    ))}
                  </Select>
                  {formik.touched.stateCd && formik.errors.stateCd && (
                    <FormHelperText className={classes.formHelperText} error>
                      {formik.errors.stateCd}
                    </FormHelperText>
                  )}
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.zipCode} <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="zipCd"
                  size="small"
                  placeholder={transProps.zipCodeLabel}
                  value={formik.values.zipCd}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={formik.touched.zipCd && Boolean(formik.errors.zipCd)}
                  helperText={formik.touched.zipCd && formik.errors.zipCd}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                  inputProps={{
                    maxLength: 5,
                  }}
                  disabled={disabled}
                ></TextField>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6}></Grid>
        {userDetails?.email && (
          <>
            <Grid item xs={12} md={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.phoneNo}
                </Typography>
                <TextField
                  fullWidth
                  name="contactNumber"
                  size="small"
                  placeholder={transProps.phoneNolabel}
                  value={formik.values.contactNumber}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                  InputProps={{
                    inputComponent: TextMaskCustom,
                  }}
                ></TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
            <Grid item xs={12} md={6}>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems={{ xs: "flex-start", sm: "center" }}
                spacing={1}
              >
                <Typography
                  sx={{
                    textAlign: { xs: "start", sm: "right" },
                  }}
                  className={classes.typography}
                >
                  {transProps.emailId} <span style={{ color: "red" }}>*</span>
                </Typography>
                <TextField
                  fullWidth
                  name="emailId"
                  size="small"
                  placeholder={transProps.emailIdLabel}
                  value={formik.values.emailId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled={userDetails?.email}
                  error={
                    formik.touched.emailId && Boolean(formik.errors.emailId)
                  }
                  helperText={formik.touched.emailId && formik.errors.emailId}
                  FormHelperTextProps={{
                    className: classes.formHelperText,
                  }}
                ></TextField>
              </Stack>
            </Grid>
            <Grid item xs={12} md={6}></Grid>
          </>
        )}
        {!userDetails?.email && (
          <Grid item xs={12} md={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  spacing={1}
                >
                  <Typography
                    sx={{
                      textAlign: { xs: "start", sm: "right" },
                    }}
                    className={classes.typography}
                  >
                    {"Identity"} <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <Box width="100%" overflow="hidden">
                    <Select
                      fullWidth
                      name="identity"
                      size="small"
                      value={formik.values.identity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.identity &&
                        Boolean(formik.errors.identity)
                      }
                      helperText={
                        formik.touched.identity && formik.errors.identity
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      disabled={disabled}
                    >
                      {map(identityOptions, (item) => (
                        <MenuItem value={item.lkpDesc}>
                          {item.lookupLongVal}
                        </MenuItem>
                      ))}
                    </Select>
                    {formik.touched.identity && formik.errors.identity && (
                      <FormHelperText className={classes.formHelperText} error>
                        {formik.errors.identity}
                      </FormHelperText>
                    )}
                  </Box>
                </Stack>
              </Grid>
              {formik.values.identity === "emailId" ? (
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        textAlign: { xs: "start", sm: "right" },
                      }}
                      className={classes.typography}
                    >
                      {"Email ID"} <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="emailId"
                      size="small"
                      inputProps={{ maxLength: 50 }}
                      value={formik.values.emailId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.emailId && Boolean(formik.errors.emailId)
                      }
                      helperText={
                        formik.touched.emailId && formik.errors.emailId
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      disabled={disabled}
                    ></TextField>
                  </Stack>
                </Grid>
              ) : formik.values.identity === "stateId" ? (
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        textAlign: { xs: "start", sm: "right" },
                      }}
                      className={classes.typography}
                    >
                      {"State ID"} <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="stateId"
                      size="small"
                      // placeholder={transProps.zipCodeLabel}
                      value={formik.values.stateId}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.stateId && Boolean(formik.errors.stateId)
                      }
                      helperText={
                        formik.touched.stateId && formik.errors.stateId
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      disabled={disabled}
                      InputProps={{
                        onInput: (event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        },
                      }}
                      inputProps={{ maxLength: 12 }}
                    ></TextField>
                  </Stack>
                </Grid>
              ) : formik.values.identity === "ssn" ? (
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        textAlign: { xs: "start", sm: "right" },
                      }}
                      className={classes.typography}
                    >
                      {"SSN"} <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <InputMask
                      mask="999-99-9999"
                      maskChar={null}
                      disabled={disabled}
                      value={formik.values.ssn}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      {(params) => (
                        <FormControl
                          fullWidth
                          error={
                            formik.touched.ssn && Boolean(formik.errors.ssn)
                          }
                        >
                          <OutlinedInput
                            name="ssn"
                            size="small"
                            type={maskSsn ? "password" : "text"}
                            variant="outlined"
                            placeholder=" Enter ssn"
                            disabled={disabled}
                            endAdornment={
                              <InputAdornment position="start">
                                <IconButton
                                  onClick={() => setMaskSsn(!maskSsn)}
                                >
                                  {maskSsn ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            }
                            {...params}
                          />
                          <FormHelperText className={classes.formHelperText}>
                            {formik.touched.ssn && formik.errors.ssn}
                          </FormHelperText>
                        </FormControl>
                      )}
                    </InputMask>
                  </Stack>
                </Grid>
              ) : formik.values.identity === "contactNumber" ? (
                <Grid item xs={12} sm={6}>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    alignItems={{ xs: "flex-start", sm: "center" }}
                    spacing={1}
                  >
                    <Typography
                      sx={{
                        textAlign: { xs: "start", sm: "right" },
                      }}
                      className={classes.typography}
                    >
                      {transProps.phoneNo}
                      <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                      fullWidth
                      name="contactNumber"
                      size="small"
                      placeholder={transProps.phoneNolabel}
                      value={formik.values.contactNumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={disabled}
                      error={
                        formik.touched.contactNumber &&
                        Boolean(formik.errors.contactNumber)
                      }
                      helperText={
                        formik.touched.contactNumber &&
                        formik.errors.contactNumber
                      }
                      FormHelperTextProps={{
                        className: classes.formHelperText,
                      }}
                      InputProps={{
                        onInput: (event) => {
                          event.target.value = event.target.value.replace(
                            /[^0-9]/g,
                            ""
                          );
                        },
                      }}
                      inputProps={{ maxLength: 10 }}
                    ></TextField>
                  </Stack>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        )}
        <Grid item xs={12}>
          <Stack direction="row" spacing={2} mt={3} justifyContent="flex-end">
            <Button
              size="small"
              variant="outlined"
              color="inherit"
              onClick={handleGoBack}
            >
              {transProps.goBack}
            </Button>
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={formik.handleSubmit}
            >
              {transProps.next}{" "}
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};
export default ContactInformation;
