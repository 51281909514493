import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Select,
  MenuItem,
  IconButton,
  Tooltip,
  Grid,
} from "@mui/material";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { map } from "lodash";
import axiosInstance from "../../redux/interceptor";
import useLoader from "../../hooks/useLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import useLookup from "../../hooks/useLookup";
import DataGridTable from "../../common/DataGrid";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import { ReactComponent as StateAmountIcon } from "../../assets/icons/things_to_do.svg";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import FederalAmountModel from "./FederalAmountModel";
import HistoryIcon from "@mui/icons-material/History";
import HistoryModel from "./HistoryModel";
import toast from "react-hot-toast";

const FederalAmount = () => {
  const { openLoader, closeLoader } = useLoader();
  const [tableRow, setTableRow] = useState();
  const [historyTableRow, setHistoryTableRow] = useState();
  const [edit, setEdit] = useState(false);
  const [countyData, setCountyData] = useState({});
  const [openHistory, setOpenHistory] = useState(false);
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      federalAmount: "",
      propValue: "",
      effectiveBeginDt: null,
    },
  });
  const onSubmit = async (data) => {
    const payload = {
      fedAmount: data?.federalAmount,
      stateAmount: data?.stateAmount,
      effectiveBeginDt: data?.effectiveBeginDt,
      code: data?.propValue,
    };
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/save/fedAndStateAmount`,
        payload
      );
      if(res.status === 200){
        getCountyData()
      }
    } catch (error) {
      toast.error("County already exists");
    }
  };

  const update = (data) => {
    const payload = [
      {
        propKey: "Fed Amount",
        propValue: data?.federalAmount,
        effectiveBeginDt: data?.effectiveBeginDt,
        uniqueKey: data?.propValue,
      },
      {
        propKey: "State Amount",
        propValue: data?.stateAmount,
        effectiveBeginDt: data?.effectiveBeginDt,
        uniqueKey: data?.propValue,
      },
    ];
  };
  const { getOptions } = useLookup("marketRegistration");

  useEffect(() => {
    getCountyData();
  }, []);

  const getCountyData = async () => {
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/getFedAndStateAmount`,
        {}
      );

      if (res?.status === 200) {
        setTableRow(res?.data);
      }
    } catch (error) {
      //
    } finally {
      closeLoader("fetch_el_cr");
    }
  };

  const handleOpenEdit = (data) => {
    setCountyData(data);
    setEdit(!edit);
  };

  const handleClickOpenHistory = (id) => {
    setOpenHistory(true);
    const payload = {
      county: id,
    };
    getHistory(payload);
  };
  const handleClickCloseHistory = () => {
    setOpenHistory(false);
  };

  const getHistory = async (payload) => {
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/fedAndStateAmount/history`,
        payload
      );

      if (res?.status === 200) {
        let values = res?.data.map((item, index) => ({
          ...item,
          id: index + 1,
        }));
        setHistoryTableRow(values);
      }
    } catch (error) {
      //
    } finally {
      closeLoader("fetch_el_cr");
    }
  };

  const handleClose = () => {
    setEdit(false);
  };

  const column = [
    {
      field: "county",
      headerName: "County",
      flex: 1,
      valueGetter: (value, row) => {
        if (value.row.county) {
          return value.row.county;
        } else {
          return "-";
        }
      },
    },
    {
      field: "fedAmount",
      headerName: "Federal Amount",
      flex: 1,
    },
    {
      field: "stateAmount",
      headerName: "State Amount",
      flex: 1,
    },
    {
      field: "effectiveBeginDt",
      headerName: "Begin Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (value.row.effectiveBeginDt) {
          return dayjs(value.row.effectiveBeginDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
    {
      field: "effectiveEndDt",
      headerName: "End Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (dayjs(value.row.effectiveEndDt).year() !== 9999) {
          return dayjs(value.row.effectiveEndDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          <Box>
            {console.log(params, "params")}
            <IconButton onClick={() => handleOpenEdit(params.row)}>
              <Tooltip title="Edit" placement="right">
                <ModeEditOutlineOutlinedIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => handleClickOpenHistory(params.row?.code)}
            >
              <Tooltip title="History" placement="right">
                <HistoryIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </>
      ),
    },
  ];
  const historyColomns = [
    {
      field: "fedAmount",
      headerName: "Federal Amount",
      flex: 1,
    },
    {
      field: "stateAmount",
      headerName: "State Amount",
      flex: 1,
    },
    {
      field: "effectiveBeginDt",
      headerName: "Begin Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (value.row.effectiveBeginDt) {
          return dayjs(value.row.effectiveBeginDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
    {
      field: "effectiveEndDt",
      headerName: "End Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (dayjs(value.row.effectiveEndDt).year() !== 9999) {
          return dayjs(value.row.effectiveEndDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
  ];

  const clear = () => {
    reset({
      propValue: "",
      effectiveBeginDt: null,
      federalAmount: "",
      stateAmount: "",
    });
  };

  const { countyOpts } = useMemo(() => {
    return {
      countyOpts: getOptions("county"),
    };
  }, [getOptions]);

  return (
    <>
      <Box
        className="ven-reg-pg"
        sx={{
          p: "1rem",
          flexGrow: 1,
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
        }}
      >
        <Stack direction="row">
          <Typography
            sx={{ fontWeight: 600, fontSize: "24px" }}
            display="inline"
            gutterBottom
            component="div"
          >
            County List
          </Typography>
        </Stack>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              border: "1px solid grey",
              borderRadius: "1rem",
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              gap: "2rem",
            }}
            xs={12}
          >
            <Grid
              container
              direction="row"
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "1rem",
              }}
              xs={12}
            >
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <LocationOnOutlinedIcon />
                <Typography
                  fontWeight="fontWeightBold"
                  className="typography"
                  variant="h6"
                >
                  Choose County
                  {/* <span style={{ color: "red" }}>*</span> */}
                </Typography>
                <Select
                  fullWidth
                  {...register("propValue")}
                  placeholder="Select County"
                >
                  {map(countyOpts, (item) => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CalendarMonthOutlinedIcon />
                <Typography
                  fontWeight="fontWeightBold"
                  className="typography"
                  variant="h6"
                >
                  Effective Start Date
                </Typography>
                <Controller
                  name="effectiveBeginDt"
                  control={control}
                  required
                  render={({ field }) => (
                    <>
                      <DatePicker
                        {...field}
                        disablePast
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                        format="YYYY/MM/DD"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        placeholder="Enter due date"
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <AccountBalanceOutlinedIcon />
                <Typography
                  fontWeight="fontWeightBold"
                  className="typography"
                  variant="h6"
                >
                  Federal Amount
                </Typography>
                <Form.Group as={Col} controlId="federalAmount">
                  <Form.Control
                    placeholder="Enter Amount"
                    type="number"
                    {...register("federalAmount", { valueAsNumber: true })}
                  />
                </Form.Group>
              </Grid>
              <Grid
                item
                xs={3}
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <StateAmountIcon />
                <Typography
                  fontWeight="fontWeightBold"
                  className="typography"
                  variant="h6"
                >
                  State Amount
                </Typography>
                <Form.Group as={Col} controlId="stateAmount">
                  <Form.Control
                    placeholder="Enter Amount"
                    type="number"
                    {...register("stateAmount", { valueAsNumber: true })}
                  />
                </Form.Group>
              </Grid>
            </Grid>
            <Box
              sx={{
                alignItems: "flex-end",
                flexDirection: "column",
                display: "flex",
              }}
              xs={12}
            >
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="outlined"
                  sx={{ color: "#6C6C6C", border: "1px solid #6C6C6C" }}
                  onClick={clear}
                >
                  Clear
                </Button>
                <Button
                  // sx={{ ml: "auto", mt: "1rem", mr: "1rem" }}
                  variant="contained"
                  type="submit"
                  style={{
                    textTransform: "none",
                    width: "100px",
                    background: "#457425",
                    color: "#FFF",
                  }}
                >
                  Allocate
                </Button>
              </Box>
            </Box>
          </Box>
        </Form>
        <Box>
          <DataGridTable columns={column} data={tableRow} />
        </Box>
      </Box>
      {edit && (
        <FederalAmountModel
          open={edit}
          handleClose={handleClose}
          countyData={countyData}
          getCountyData={getCountyData}
        />
      )}

      {openHistory && (
        <HistoryModel
          openHistory={openHistory}
          handleClickCloseHistory={handleClickCloseHistory}
          historyColomns={historyColomns}
          historyTableRow={historyTableRow}
        />
      )}
      {/* <Dialog
        maxWidth="md"
        fullWidth
        scroll="paper"
        open={openHistory}
        onClose={handleClickCloseHistory}
        aria-labelledby="responsive-dialog-title"
        sx={{ paddingBottom: "50px" }}
      >
        <DialogTitle id="responsive-dialog-title">Amount limits</DialogTitle>
        <DialogContent>
          <DataGridTable columns={historyColomns} data={historyTableRow} />
        </DialogContent>
      </Dialog> */}
    </>
  );
};

export default FederalAmount;
