import React, { useEffect, useState } from 'react'
import { Grid } from '@mui/material';
import PageHeaderComponent from '../../common/components/pageHeader';
import { CustomButton } from '../../common/components/customButton';
import ModalComponent from '../../common/components/modal';
import SearchComponent from '../../common/components/searchComponent';
import TableComponent from '../../common/components/table';
import { useSearchParams } from 'react-router-dom';
import { Row, Col } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import '../../../styles/home.scss';
import { useForm } from 'react-hook-form';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import { connect } from 'react-redux';
import { mapStateToProps } from '../../../redux/reducers';
import * as actionTypes from "../../../redux/actionTypes/csaSignup";
import { PropTypes } from "prop-types";
import moment from "moment";
import { popUpAlert, validateEmail } from '../../../utils/globalMethods';
import { SwapCalls } from '@mui/icons-material';
import { refreshKey } from '../../../redux/actionTypes/administration';

function createData(id,firstName,lastName,lastUpdtTs,primaryEmailAddr,userRole,status,row){
    return {
      id,
      firstName,
      lastName,
      lastUpdtTs :moment(lastUpdtTs, "YYYY-MM-DD").format('MM/DD/YYYY hh:mm A'),
      primaryEmailAddr,
      userRole,
      status,
      row
    }
  }

const VendorAddUserTab = (props) => {

    const [showModal, setShowModal] = useState(false);
    const [rows, setRows] = useState([]);
    const [onEdit, setOnEdit] = useState({});
    const [search, setSearch] = useState("");
    const [columns, setColumns] = useState([]);
    const [searchParams] = useSearchParams();
    const [size, setSize] = useState(10);
    const [from, setFrom] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [subUserTotal, setSubUserTotal] = useState(0);
    const [tableSort, setTableSort] = useState({"lastUpdtTs":"DESC"});
    // eslint-disable-next-line no-unused-vars 
    const [isRefresh, setIsRefresh] = useState(false);

    let userData = JSON.parse(sessionStorage.getItem("userData"));

    let vendorId = searchParams.get("vendor_id") || userData.vendorId;
    const {user } = props.userAuth;

    if (!vendorId && props.userAuth.user.vendorId) {
      vendorId = props.userAuth.user.vendorId;
    }
    useEffect(() => {
        const payload = {
            "vendorId": vendorId,
            "from":from,
            "size":size,
            "name":search
        }
        props.getVendorSubUserList(payload);
    },[size,from,props.csaSignup.refreshKey,search,tableSort]);

    useEffect(() =>{
        const {list, total, loading} = props?.csaSignup?.vendorSubUserList;
        let data = [];
        setTimeout(()=>{
            list.forEach(element => {
                data.push(createData(element?.id,element?.firstName,element?.lastName,element.lastUpdtTs,element?.primaryEmailAddr,element?.userRole,
                element?.status,element));
            });
            setRows(data);
        },300);
        handleColumns();
        setSubUserTotal(total);
        setShowLoader(loading);
    },[props.csaSignup?.vendorSubUserList]);
    // eslint-disable-next-line no-unused-vars 
    const [formValue, setFormValue] = useState();

    const {
        register,
        formState: { errors },
        handleSubmit,
        reset,
    } = useForm({
        defaultValues: formValue
    });

    const handleUpdateSubUser = (row) =>{
        const payload ={
            "userId": row?.id,
            "vendorId": vendorId          
        }
        props.updateSubUser(payload);
      }

    const handleUpdateUser = (row) => {
        popUpAlert({
          title: "Are you sure?",
          text: "Do you want to convert this user as Primary Vendor.",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Yes, approve it!",
          closeOnConfirm: false,
          onConfirmed: true,
          handleConfirm: () => handleUpdateSubUser(row),
        });
      }

      const handleDelete = (row) => {
        const payload = {
        "userId": row?.id,
          
        }
       props.deleteSubUser(payload);

      }

      const handleOnDelete = (row) => {
        popUpAlert({
          title: "Are you sure?",
          text: "Vendor Sub User will be removed from the application.",
          icon: "warning",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonClass: "btn-danger",
          confirmButtonText: "Yes, delete it!",
          closeOnConfirm: false,
          onConfirmed: true,
          handleConfirm:()=>handleDelete(row),
        });
      }
    const handleColumns = () => {
        const headers = [
            { id: "firstName", label: "First Name " },
            { id: "lastName", label: " Last Name" },
            { id: "primaryEmailAddr", label: "Email" },
            { id: "userRole", label: "Role",
            cell: (item) => {
                const role = item.userRole === "SUBVDR" ? <span>Sub Vendor</span> : item.userRole === "CSAVDR" ? <span className="badge badge-primary">Primary Vendor</span> : "";
                return role;
            }
             },
            { id: "lastUpdtTs", label: "Last Updated Time" }    
        ];
        if (user.Role === "ADMIN" || user.Role === "HIPVDR") {
          headers.push({
            id: "action",
            label: "Action",
            cell: (item) => {
              if (item.userRole == "SUBVDR") {
                if (item.status === "ACTIVE") {
                  return (
                    <>
                      <IconButton
                        aria-label="edit"
                        title="Convert Vendor"
                        onClick={() => handleUpdateUser(item)}
                      >
                        <SwapCalls />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        title="Delete Vendor"
                        onClick={() => handleOnDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  );
                } else if (item.status === "INACTV") {
                  return (
                    <>
                      <span style={{ marginLeft: "40px" }}></span>
                      <IconButton
                        aria-label="delete"
                        title="Delete Vendor"
                        onClick={() => handleOnDelete(item)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </>
                  );
                }
              } else {
                return <div style={{ paddingLeft: "30px" }}>{"-"}</div>;
              }
            },
          });
        }
        setColumns(headers);
    }

    useEffect(()=>{
        if (props?.csaSignup?.getAddUserList?.getAddUserList?.code === "200") {
            const payload = {
                "vendorId": vendorId,//1151,
                "from":from,
                "size":size,
                "name":search
            }
            props.getVendorSubUserList(payload);
            if (props?.csaSignup?.getAddUserList?.getAddUserList?.code === "200") {
                popUpAlert({
                    title: "Successfully",
                    text: "Sub User added successfully!",
                    icon: "success",
                    timer:1500,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            }
            setShowModal(false);
            reset();   
        } else {
            if (props?.csaSignup?.getAddUserList?.getAddUserList?.code === "506") {
                popUpAlert({
                    title: "Error",
                    text: props?.csaSignup?.getAddUserList?.getAddUserList?.message,
                    icon: "error",
                    timer:1500,
                    showCancelButton: false,
                    showConfirmButton: false,
                });
            }
        }
    },[props?.csaSignup?.getAddUserList]);


    const onSubmit = (data)=>{
        const payload ={
            "firstName": data.firstName,
            "lastName": data.lastName,
            "primaryEmail": data.email,
            "role": "SUBVDR",
            "vendorId" : vendorId
        };
        props.addUserList(payload);
    }

    const renderModal = () => {
        return (
            <Form noValidate onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Form.Group>
                        <Form.Label className="smallTitle">First Name *</Form.Label>
                        <Form.Control type="text" size="lg" {...register("firstName", { required: "Please enter First Name" })} />
                    </Form.Group>
                    {errors.firstName && (
                         <span className="dh-txt-danger">
                         {errors.firstName.message}
                         </span>
                    )}
                </Row>
                <Row>
                   
                    <Form.Group>
                        <Form.Label className="smallTitle">
                            Last Name *
                        </Form.Label>
                        <Form.Control
                            className='last-textfield custom-input'
                            type="text"
                            {...register("lastName", { required: "Please enter Last Name"  })}
                            size="lg"
                        />
                    </Form.Group>
                    {errors.lastName && (
                         <span className="dh-txt-danger">
                         {errors.lastName.message}
                         </span>
                    )}
                </Row>

                <Row>
                    <Form.Group>
                        <Form.Label className="smallTitle">
                            Email *
                        </Form.Label>
                        <Form.Control
                            className='last-textfield custom-input lowercase'
                            type="text"
                            {...register("email", {required:
                                "Please enter Email",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Invalid Email Format"
                                  },
                               validate:validateEmail,
                            })}
                            size="lg"
                        />
                    </Form.Group>
                    {errors.email && (
                        <span className="dh-txt-danger">
                            {errors.email.message}
                        </span>
                            )}
                </Row>
                {onEdit && <Form.Control type="text" hidden />}
                <Row className="modal-footer">
                    <Col>
                        <Button variant="primary" type="submit" className="float-right">
                            {onEdit ? 'Save' : 'Add'}
                        </Button>
                    </Col>
                </Row>
            </Form >

        )
    }

    const handleSearch = (e) => {
        setSearch(e.target.value.toLowerCase());
        setShowLoader(true);
        setFrom(0);

    }

    const handleNext = (page, rowSize) => {
        (size != rowSize) && setSize(rowSize);
        setFrom(rowSize * page);
    };

    const handleSort = (item) => {
        let sortitem = {};
        if (item.sort == "" || item.sort == "DESC") {
            sortitem[item.id] = "ASC";
        } else {
            sortitem[item.id] = "DESC";
        }
        setTableSort(sortitem);
    };
    return (
        <Grid container className="container-block" rowSpacing={2} >
            {showModal && <ModalComponent 
                shown={showModal}
                close={() => {
                    setShowModal(false);
                    setOnEdit({});reset();

                }}
                title={"ADD USER"}
                label={"ADD USER"}
                modalBody={renderModal}
                size={"md"}
            />}
            <Grid container >
                <Grid item xs={12} md={6}>
                    <PageHeaderComponent
                        title={"Users"}
                        showSubtitle={true}
                        subtitle={"Total Records"}
                        count={subUserTotal}

                    />
                </Grid>
                <Grid container xs={12} md={6} justifyContent={"flex-end"}>
                    {(user.Role === "ADMIN") || (user.Role === "HIPVDR") && <CustomButton variant="contained" startIcon={<i className="fa fa-plus" aria-hidden="true"></i>}
                        onClick={() => { setShowModal(true); setOnEdit({}); }}
                    >
                        Add
                    </CustomButton>}
                    <CustomButton variant="outlined" startIcon={<i className="fa fa-refresh" aria-hidden="true"></i>} sx={{ marginLeft: '10px' }}
                         onClick={()=>{
                            setIsRefresh(true);
                            setSearch("");
                            props.refreshKey();
                            setIsRefresh(false);
                            setShowLoader(true);
                         }}>
                        Refresh
                    </CustomButton>
                </Grid>
            </Grid>
            <Grid container xs={12} direction={"row"} paddingTop={2} justifyContent={"flex-end"}>
                <SearchComponent
                    placeholder={"Name,Email"}
                    handleChange={handleSearch}
                    searchText={search}
                />
            </Grid>
            <Grid item xs={12}>
                <TableComponent
                    columns={columns}
                    rows = {rows}
                    showDetails = {false}
                    handlePagination={handleNext}
                    total={subUserTotal}
                    handleSort={handleSort}
                    page={Math.floor(from/size)}
                    rowsPerPage={size}
                    dataLoading={showLoader}
                />
            </Grid>

        </Grid>
    )
}

VendorAddUserTab.propTypes ={
    getVendorSubUserList: PropTypes.func.isRequired,
    csaSignup: PropTypes.object.isRequired,
    userAuth: PropTypes.object,
    addUserList: PropTypes.func.isRequired,
    updateSubUser: PropTypes.func.isRequired,
    refreshKey: PropTypes.func.isRequired,
    deleteSubUser: PropTypes.func.isRequired,
}

function mapDispatchToProps(dispatch) {
  return {
    getVendorSubUserList: (payload) =>dispatch(actionTypes.getVendorSubUserList(payload)),
    addUserList: (payload=>dispatch(actionTypes.addUserList(payload))),
    updateSubUser: (payload) =>dispatch(actionTypes.updateSubUser(payload)),
    deleteSubUser: (payload) =>dispatch(actionTypes.deleteSubUser(payload)),
    refreshKey: () => dispatch(refreshKey())
  }}

  export default connect(mapStateToProps, mapDispatchToProps)(VendorAddUserTab);