import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, Box, Button, Typography, FormControl } from "@mui/material";
import { mapStateToProps } from "../../../redux/reducers";
import { connect } from "react-redux";
import * as actionType from "../../../redux/actionTypes/csaSignup";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import SelectDropdown from "../../common/selectdropdown-component";
import { useParams, useSearchParams } from "react-router-dom";
import CommonLoading from "../../common/components/commonLoading";
import SelectDropdown from "../../../components/SelectDropdown";
import { useNavigate } from "react-router-dom";
import useTranslator from "../../../hooks/useTranslator";

function FarmInfocomponent(props) {
  const transProps = useTranslator(
    [
      "pageTitle",
      "applicationId",
      "chooseFarm",
      "pleaseSelectFarm",
      "notesAboutSPF",
      "website",
      "discard",
      "next",
    ],
    "farmInfo"
  );
  const [searchParams] = useSearchParams();
  const appId = searchParams.get("appId");
  const navigate = useNavigate();
  let { vendorId } = useParams();

  let mapVendorId = searchParams.get("vendorId");
  vendorId = vendorId ? vendorId : mapVendorId ? mapVendorId : "";
  const [csaVendorList, setCsaVendorList] = useState([]);
  const [csaVendorInfo, setCsaVendorInfo] = useState({});
  const [selectedVendor, setSelectedVendor] = useState(
    props?.farmInfo?.farmDetails
      ? {
          name: props?.farmInfo?.farmDetails?.farmName,
          label: props?.farmInfo?.farmDetails?.farmName,
          id: props?.farmInfo?.farmDetails?.vendorId,
          isHIPVendor: props?.farmInfo?.farmDetails?.isHIPVendor,
        }
      : { name: "", label: "--Select--" }
  );
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (props.csaSignup?.csaVendorList?.csaVendorList?.length == 0) {
      props.getCsaVendorList();
    }
    props.setVendorChange(false);
  }, []);

  useEffect(() => {
    const { loading } = props.csaSignup?.csaVendorList;

    if (props.csaSignup?.csaVendorList?.csaVendorList?.length) {
      if (vendorId) {
        const selectedVdr = props.csaSignup?.csaVendorList?.csaVendorList.find(
          (item) => item.vendorId == vendorId
        );
        const obj = {
          name: selectedVdr.vendorName,
          values: selectedVdr.vendorName,
          id: selectedVdr.vendorId,
          isHIPVendor: selectedVdr.isHIPVendor,
        };
        setSelectedVendor(obj);
        props.getVendorInfo({ vendorId });
      }
      const vendorList = props.csaSignup?.csaVendorList?.csaVendorList.map(
        (item) => {
          return {
            name: item.vendorName,
            values: item.vendorName,
            id: item.vendorId,
            isHIPVendor: item.isHIPVendor,
          };
        }
      );
      setCsaVendorList(vendorList);
      setIsLoading(loading);
    }
  }, [vendorId, props.csaSignup?.csaVendorList?.csaVendorList]);

  useEffect(() => {
    const { loading } = props.csaSignup?.csaVendorList;

    setCsaVendorInfo(
      Object.keys(props.csaSignup?.csaVendorInfo?.csaVendorInfo).length
        ? props.csaSignup?.csaVendorInfo?.csaVendorInfo
        : {}
    );
    setIsLoading(loading);
  }, [props.csaSignup?.csaVendorInfo?.csaVendorInfo]);
  const handleClick = () => {
    navigate("/kfreshfep");
  };

  return (
    <Grid
      item
      lg={12}
      md={12}
      ml={4}
      className="wrapper-gird"
      sx={{ background: "#FFF", height: "94vh", border: "1px solid #CCCCCC" }}
    >
      <Box>
        {isLoading && <CommonLoading />}
        <Box
          sx={{
            backgroundColor: "#FFFFFF",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #CCCCCC",
          }}
        >
          <Typography variant="h5" className="sub-heading" tabIndex={0}>
            {transProps.pageTitle}
          </Typography>
          <Typography variant="h5" className="sub-heading">
            {`${transProps.applicationId} : ${appId}`}
          </Typography>
        </Box>
        <Grid
          item
          xs={12}
          display={"flex"}
          sx={{
            borderTop: "none",
            minHeight: "10rem",
          }}
          className="row"
        >
          <Box
            // container
            // item
            // xs={6}
            // sm={6}
            // md={6}
            sx={{ m: 2 }}
            direction={"column"}
            className="row"
          >
            <FormControl
              sx={{ mt: 1, pb: 2, height: "auto" }}
              className="col-sm-4"
            >
              <Typography
                variant="h5"
                sx={{
                  mb: "10px",
                  fontFamily: "texta-bold",
                  fontSize: "1.25rem",
                }}
              >
                {transProps.chooseFarm} *
              </Typography>
              <SelectDropdown
                label={"Choose Farm"}
                defaultValue={selectedVendor}
                onChange={(e) => {
                  props.getVendorInfo({ vendorId: e.id });
                  props.setVendorChange(true);
                  setSelectedVendor(e);
                  setIsError(false);
                  setIsLoading(true);
                }}
                dataList={csaVendorList}
                // isSearch={true}
                // defaultValueProps={{
                //   default: true
                // }}
              />
            </FormControl>
            {isError && (
              <span className="dh-txt-danger">
                <i className="fa fa-info-circle"></i>{" "}
                {transProps.pleaseSelectFarm}
              </span>
            )}
            <Box
              // item
              // xs={12}
              // lg={12}
              // md={12}
              sx={{ border: "1px solid #CCCCCC", borderRadius: "5px" }}
              className="row"
            >
              {/* <FormControl sx={{ mt: 1, width: "90%", height: "auto" }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: "10px",
                    fontFamily: "texta-bold",
                    fontSize: "1.25rem",
                  }}
                >
                  Choose Farm *
                </Typography>
                <SelectDropdown
                  label={"Choose Farm"}
                  defaultValue={selectedVendor}
                  onChange={(e) => {
                    props.getVendorInfo({ vendorId: e.id });
                    props.setVendorChange(true);
                    setSelectedVendor(e);
                    setIsError(false);
                    setIsLoading(true);
                  }}
                  dataList={csaVendorList}
                  // isSearch={true}
                  // defaultValueProps={{
                  //   default: true
                  // }}
                />
              </FormControl> */}
              <Box className="col-sm-7">
                {Object.keys(csaVendorInfo).length > 0 &&
                  csaVendorInfo?.products && (
                    <Box pt={2}>
                      <Typography
                        variant="h5"
                        sx={{
                          mb: "10px",
                          fontFamily: "Texta-bold",
                          fontSize: "1.25rem",
                        }}
                      >
                        {transProps.notesAboutSPF}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          mb: "10px",
                          fontSize: "1.25rem",
                          fontFamily:
                            "Texta-book, Helvetica, Arial, 'sans-serif'",
                        }}
                      >
                        {csaVendorInfo?.products}
                      </Typography>
                    </Box>
                  )}
                {Object.keys(csaVendorInfo).length > 0 &&
                  csaVendorInfo?.publicWebsite && (
                    <Box pt={2}>
                      <Typography
                        variant="h5"
                        sx={{
                          mb: "10px",
                          fontFamily: "Texta-bold",
                          fontSize: "1.25rem",
                        }}
                      >
                        {transProps.website}
                      </Typography>
                      <Typography
                        variant="p"
                        sx={{
                          mb: "10px",
                          fontSize: "1.25rem",
                          fontFamily:
                            "Texta-book, Helvetica, Arial, 'sans-serif'",
                        }}
                      >
                        {csaVendorInfo?.publicWebsite}
                      </Typography>
                    </Box>
                  )}
              </Box>
              <Box
                className="col-sm-5"
                sx={{ display: "flex", alignItems: "center" }}
              >
                {Object.keys(csaVendorInfo).length > 0 && (
                  <img
                    src={csaVendorInfo?.publicPhoto}
                    style={{
                      backgroundColor: "#fff",
                      padding: "0.25rem",
                      border: "1px solid #dee2e6",
                      alignItems: "center",
                      width: "200px",
                      height: "150px",
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
          {/* <Grid item xs={6} lg={6} md={6} p={2}>
            {Object.keys(csaVendorInfo).length > 0 && (
              <img
                src={csaVendorInfo?.publicPhoto}
                style={{
                  backgroundColor: "#fff",
                  padding: "0.25rem",
                  border: "1px solid #dee2e6",
                  alignItems: "center",
                  width: "200px",
                  height: "150px",
                }}
              />
            )}
          </Grid> */}
        </Grid>
        <Box
          sx={{
            padding: "10px",
            borderTop: "none",
            display: "flex",
            justifyContent: "flex-end",
            height: "80px",
          }}
        >
          <Button
            onClick={handleClick}
            sx={{
              backgroundColor: "white !important",
              color: "#457425",
              height: "40px",
              width: "120px",
              padding: "10px",
              marginLeft: "10px",
              textTransform: "capitalize",
              fontWeight: 700,
              fontSize: "14px",
              border: "#79747E 1px solid",
            }}
          >
            {transProps.discard}
          </Button>

          <Button
            onClick={(e) => {
              e.preventDefault();
              if (selectedVendor?.name) {
                props.setCSAFormValidate((prevState) => ({
                  ...prevState,
                  farm_info: true,
                }));
                const farmInfo = {
                  farmDetails: {
                    farmName: selectedVendor?.name,
                    vendorId: selectedVendor?.id,
                    phoneNumber:
                      props.csaSignup?.csaVendorInfo?.csaVendorInfo
                        ?.primaryMobile,
                    email: props.csaSignup?.csaVendorInfo?.csaVendorInfo?.email,
                    isHIPVendor: selectedVendor?.isHIPVendor,
                  },
                };
                props.setFarmInfo(farmInfo);
                props.setActiveTab("menuStep2");
              } else {
                setIsError(true);
              }
            }}
            sx={{
              backgroundColor: "#457425 !important",
              color: "white",
              height: "40px",
              width: "120px",
              padding: "10px",
              marginLeft: "10px",
              textTransform: "capitalize",
              fontWeight: 700,
              fontSize: "14px",
            }}
          >
            {transProps.next}
          </Button>
        </Box>
      </Box>
    </Grid>
  );
}

FarmInfocomponent.propTypes = {
  setActiveTab: PropTypes.func.isRequired,
  getCsaVendorList: PropTypes.func.isRequired,
  getVendorInfo: PropTypes.func.isRequired,
  csaSignup: PropTypes.object.isRequired,
  activeMenu: PropTypes.object,
  handleUpdate: PropTypes.func,
  setCSAFormValidate: PropTypes.func.isRequired,
  setFarmInfo: PropTypes.func,
  farmInfo: PropTypes.object,
  setVendorChange: PropTypes.func,
};

function mapDispatchToProps(dispatch) {
  return {
    getCsaVendorList: (payload) =>
      dispatch(actionType.getCsaVendorList(payload)),
    getVendorInfo: (payload) => dispatch(actionType.getVendorInfo(payload)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FarmInfocomponent);
