import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { mapStateToProps } from "../../../redux/reducers";
import { Grid } from "@mui/material";
import PageHeaderComponent from "../../common/components/pageHeader";
import { CustomButton } from "../../common/components/customButton";
import ModalComponent from "../../common/components/modal";
import SnapAddEditComponent from "./snapAddEdit";
import SearchComponent from "../../common/components/searchComponent";
import PropTypes from "prop-types";
import { popUpAlert } from "../../../utils/globalMethods";
import TableComponent from "../../common/components/table";
import Geocode from "react-geocode";
import * as actionTypes from "../../../redux/actionTypes/csaSignup";
import { useSearchParams } from "react-router-dom";
import SnapCSALocationDetail from "./snapCSALocationDetail";

function createData(
  id,
  addressId,
  address1,
  address2,
  city,
  state,
  zipcode,
  latitude,
  longitude,
  vendorId,
  shareNam,
  row
) {
  return {
    id,
    addressId,
    address1,
    address2,
    city,
    state,
    zipcode,
    latitude: latitude,
    longitude: longitude,
    vendorId,
    shareNam: shareNam,
    row,
  };
}

function SnapCsaLocation(props) {
  const userLogin = JSON.parse(sessionStorage.getItem("userLogin"));
  const [showModal, setShowModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [onEdit, setOnEdit] = useState({});
  const [search, setSearch] = useState("");
  // const [showLoader, setShowLoader] = useState(true);
  const [tableSort, setTableSort] = useState({ lastUpdtTs: "DESC" });
  const [validated, setValidated] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [snapCsaLocationList, setSnapCsaLocationList] = useState([]);
  const [columns, setColumns] = useState([]);
  const [expand] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [snapCsaLocationListTotal, setSnapCsaLocationListTotal] = useState(0);
  const [latLng] = useState();
  // eslint-disable-next-line no-unused-vars
  const [_isLoading, setIsLoading] = useState(false);
  const [searchParams] = useSearchParams();
  let vendorId = searchParams.get("vendor_id") || userLogin?.vendorId;
  if (!vendorId && props.userAuth?.user?.vendorId) {
    vendorId = props?.userAuth?.user?.vendorId;
  }

  // useEffect(() => {
  //     const payload = {
  //       "name": search,
  //        "size": size,
  //        "from": from
  //      }
  //      props.getCityList();
  //   }, [size,from,search]);

  useEffect(() => {
    const payload = {
      lastUpdtTimeStamp: 1710521332039,
      lastUpdtUidNam: "VDR_2075",
      nam: search,
      from,
      size,
      vendorId: vendorId,
    };
    props.getSnapCsaLocationList(payload);
    setIsLoading(false);
  }, [size, from, props.csaSignup.refreshKey, search, tableSort]);

  useEffect(() => {
    if (props.csaSignup?.snapCsaLocationList?.snapCsaLocationList?.length) {
      setSnapCsaLocationList(
        props?.csaSignup?.snapCsaLocationList?.snapCsaLocationList
      );
    } else {
      setSnapCsaLocationList([]);
    }
    console.log(
      "dddddddddddd",
      props.csaSignup?.snapCsaLocationList?.snapCsaLocationList
    );
  }, [props?.csaSignup?.snapCsaLocationList?.snapCsaLocationList]);

  useEffect(() => {
    const { list, total, loading } = props?.csaSignup?.snapCsaLocationList;
    let data = [];
    // setRows([]);
    setTimeout(() => {
      list.forEach((element) => {
        data.push(
          createData(
            element?.id,
            element?.addressId,
            element?.addressLine1,
            element.addressLine2,
            element?.city,
            element?.state,
            element?.zipcode,
            element?.latitude,
            element?.longitude,
            element?.vendorId,
            element.shareNam,
            element
          )
        );
      });
      setRows(data);
    }, 300);
    handleColumns();
    console.log(data, "heyyyyyyyy");
    setSnapCsaLocationListTotal(total);
    // setShowLoader(loading);
  }, [props?.csaSignup?.snapCsaLocationList]);

  const handleRowClick = (e, row) => {
    var updatedSelectedRows = [];
    if (e.target.checked) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter((r) => r.id !== row.id);
    }
    setSelectAll(true);
    setSelectedRows(updatedSelectedRows);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleNext = (page, rowSize) => {
    size != rowSize && setSize(rowSize);
    setFrom(rowSize * page);
  };

  const handleSort = (item) => {
    let sortitem = {};
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC";
    } else {
      sortitem[item.id] = "DESC";
    }
    setTableSort(sortitem);
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      const cityDtl = event.target[2].value;

      let payload = {
        // "addressId" : "",
        addressLine1: event.target[0].value,
        addressLine2: event.target[1].value,
        city: cityDtl && cityDtl.split(",")[0],
        state: event.target[4].value,
        zipcode: event.target[3].value,
        createTimeStamp: 1710510767871,
        ipAddress: "182.73.228.218",
        lastUpdtTimeStamp: 1710510767871,
        lastUpdtUidNam: "VDR_2075",
        latitude: cityDtl.split(",")[1],
        longitude: cityDtl.split(",")[2],
        vendorId: vendorId,
        vendorType: "CSAVDR",
        id: onEdit?.id,
      };
      const address2 = event.target[1].value ? event.target[1].value : "";
      Geocode.fromAddress(
        event.target[0].value +
          " " +
          address2 +
          " " +
          event.target[2].value.split(",")[0] +
          " " +
          "MA" +
          event.target[3].value
      ).then(
        (response) => {
          console.log(response);
          if (response.status == "OK") {
            payload.latitude = response.results[0].geometry.location.lat;
            payload.longitude = response.results[0].geometry.location.lng;
          } else {
            console.log(
              "Geocode was not successful for the following reason: " +
                response.status
            );
          }
          //setLatLng({lat:response[0].geometry.location.lat, lng:response[0].geometry.location.lng});
          if (Object.keys(onEdit).length !== 0) {
            payload["addressId"] = event.target[5].value;
            props.editSnapCsaLocation(payload);
          } else {
            props.addSnapCsaLocation(payload);
          }
          setShowModal(false);
          setOnEdit({});
        },
        (error) => {
          console.error(error);
        }
      );
      event.preventDefault();
      event.stopPropagation();
    }
    event.preventDefault();
    event.stopPropagation();
  };

  const handleOnEditAction = (row) => {
    setOnEdit(row);
    setShowModal(true);
  };
  const handleDelete = (row) => {
    const payload = {
      id: row?.id,
      addressId: row?.row?.addressId,
      vendorId: row?.vendorId,
      vendorType: "CSAVDR",
    };
    props.deleteSnapCsaLocation(payload);
  };

  const handleOnDeleteAction = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "SNAP SPF Location will be removed from the application.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleDelete(row),
    });
  };
  const handleColumns = () => {
    const headers = [
      { id: "details", label: "Details" },
      { id: "address1", label: "Address Line 1" },
      { id: "address2", label: "Address Line 2" },
      { id: "city", label: "City" },
      { id: "state", label: "State" },
      { id: "zipcode", label: "Zipcode" },
      {
        id: "action",
        label: "Action",
        cell: (item) => {
          if (!item.shareNam) {
            return (
              <>
                <span
                  className="cursor sortColorBlack mr-2"
                  title="Edit"
                  onClick={() => handleOnEditAction(item)}
                >
                  <i className="fa fa-edit" aria-hidden="true"></i>
                </span>
                <span
                  className="cursor sortColorBlack"
                  title="Delete"
                  onClick={() => handleOnDeleteAction(item)}
                >
                  <i className="fa fa-trash-o" aria-hidden="true"></i>
                </span>
              </>
            );
          } else {
            return <div style={{ paddingLeft: "40px" }}> - </div>;
          }
        },
      },
    ];
    setColumns(headers);
  };

  const renderModal = () => {
    return (
      <SnapAddEditComponent
        validated={validated}
        handleSubmit={handleSubmit}
        onEdit={Object.keys(onEdit).length !== 0}
        row={onEdit}
        latLng={latLng}
      />
    );
  };

  const renderDetails = (row) => {
    return <SnapCSALocationDetail row={row} />;
  };

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    // setShowLoader(true);
    setFrom(0);
  };

  return (
    <Grid container className="container-block" rowSpacing={2}>
      {showModal && (
        <ModalComponent
          shown={showModal}
          close={() => {
            setShowModal(false);
            setOnEdit({});
          }}
          title={
            Object.keys(onEdit).length === 0
              ? "ADD SNAP SPF LOCATION"
              : "EDIT SNAP SPF LOCATION"
          }
          modalBody={renderModal}
          size={"lg"}
          label={
            Object.keys(onEdit).length === 0
              ? "ADD SNAP SPF LOCATION"
              : "EDIT SNAP SPF LOCATION"
          }
        />
      )}
      <Grid container>
        <Grid item xs={12} md={6}>
          <PageHeaderComponent
            title={"SPF CSA Location"}
            showSubtitle={true}
            subtitle={"Total Records"}
            count={snapCsaLocationListTotal}
          />
        </Grid>
        <Grid container xs={12} md={6} justifyContent={"flex-end"}>
          <CustomButton
            variant="contained"
            startIcon={<i className="fa fa-plus" aria-hidden="true"></i>}
            onClick={() => {
              setShowModal(true);
              setOnEdit({});
            }}
          >
            Add
          </CustomButton>
        </Grid>
      </Grid>
      <Grid
        container
        xs={12}
        direction={"row"}
        paddingTop={2}
        justifyContent={"flex-end"}
      >
        <SearchComponent
          placeholder={"Address,City,Zipcode"}
          handleChange={handleSearch}
          searchText={search}
        />
      </Grid>
      <Grid item xs={12}>
        <TableComponent
          columns={columns}
          rows={rows}
          showDetails={true}
          expand={expand}
          renderDetails={renderDetails}
          handlePagination={handleNext}
          total={snapCsaLocationListTotal}
          selectAll={selectAll}
          handleSelectAll={handleSelectAll}
          handleRowClick={handleRowClick}
          selectedRows={selectedRows}
          handleSort={handleSort}
          page={Math.floor(from / size)}
          rowsPerPage={size}
          // dataLoading={showLoader}
        />
      </Grid>
    </Grid>
  );
}

SnapCsaLocation.propTypes = {
  csaSignup: PropTypes.object.isRequired,
  getSnapCsaLocationList: PropTypes.func.isRequired,
  addSnapCsaLocation: PropTypes.func.isRequired,
  editSnapCsaLocation: PropTypes.func.isRequired,
  deleteSnapCsaLocation: PropTypes.func.isRequired,
  vendorInfo: PropTypes.object,
  userAuth: PropTypes.object,
};

function mapDispatchToProps(dispatch) {
  return {
    getSnapCsaLocationList: (payload) =>
      dispatch(actionTypes.getSnapCsaLocationList(payload)),
    addSnapCsaLocation: (payload) =>
      dispatch(actionTypes.addSnapCsaLocation(payload)),
    editSnapCsaLocation: (payload) =>
      dispatch(actionTypes.editSnapCsaLocation(payload)),
    deleteSnapCsaLocation: (payload) =>
      dispatch(actionTypes.deleteSnapCsaLocation(payload)),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(SnapCsaLocation);
