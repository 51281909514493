import {
  Box,
  Collapse,
  Grid,
  Stack,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { includes, map, reject, some } from "lodash";
import PropTypes from "prop-types";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import CloseIcon from "@mui/icons-material/Close";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import React, { useState } from "react";
import FilePresentOutlinedIcon from "@mui/icons-material/FilePresentOutlined";
import { headers } from "../../config/index";
import axiosInstance from "../../redux/interceptor";
import axios from "axios";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Modal from "@mui/material/Modal";
import { documnetURL } from "../senior/constant";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import DataGridTable from "../../common/DataGrid";
import useLoader from "../../hooks/useLoader";
import DeleteIcon from "@mui/icons-material/Delete";
import moment from "moment";
import {
  popUpAlert,
} from "../../utils/globalMethods";
import { AUTH_API } from "../../config";
import toast from "react-hot-toast";


function MarketList({
  markets,
  onApprove,
  onReject,
  isLoading,
  userRole,
  vendorType = "HIPVDR",
  renderDetails,
  fetchMarketsWaitingList,
  activeTab
}) {
  const { openLoader, closeLoader } = useLoader();
  const [openIds, setOpenIds] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [pdfUrl, setPdfUrl] = useState(null);

  if (!markets?.length && !isLoading) {
    return <Typography>No result found</Typography>;
  }
  // Document view
  const handleShowDocument = (orgId) => {
    getFormerDocuments(orgId);
  };

  const getFormerDocuments = async (orgId) => {
    try {
      openLoader("get_doc_case_id");
      const url = `${documnetURL}/docControl/getDocumentsByCaseId/${orgId}/KFresh`;
      const res = await axios.get(url, {
        headers: headers,
      });
      if (res) {
        let modifiedData = res?.data.map((item, index) => ({
          ...item,
          id: index + 1,
          docCat: item.docCat == "kfresh" ? item.docType : item.docCat,
        }));
        setTableData(modifiedData);
        setOpenModal((prev) => !prev);
      }
    } catch (error) {
      console.log(error);
    } finally {
      closeLoader("get_doc_case_id");
    }
  };

  const toggleDetails = (id) => {
    const isOpen = includes(openIds, id);
    if (isOpen) {
      setOpenIds(reject(openIds, (_id) => id));
    } else {
      setOpenIds([...openIds, id]);
    }
  };

  const COLUMN = [
    {
      field: "id",
      headerName: "S.NO",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "docCat",
      headerName: "Document Type",
      headerClassName: "grid_header",
      flex: 1,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "grid_header",
      flex: 1,
      renderCell: ({ row: { documentId = null, sourceCaseId = null } }) => {
        return (
          <Stack direction="row" alignItems={"center"}>
            <Button
              startIcon={<VisibilityOutlinedIcon />}
              sx={{
                color: "#44A0E3",
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                padding: "0px !important",
                "& .css-1d6wzja-MuiButton-startIcon": {
                  marginRight: "3px !important",
                },
              }}
              onClick={(e) => handlePDFDownload(e, documentId, sourceCaseId)}
            ></Button>
          </Stack>
        );
      },
    },
  ];
  const handlePDFDownload = async (e, documentId, sourceCaseId) => {
    try {
      openLoader("handle_pdf_download");
      const url = `${documnetURL}/docControl/downloadDocumentBy/${documentId}/${sourceCaseId}/KFresh`;
      const response = await axios.post(
        url,
        {},
        { responseType: "blob", headers: headers }
      );
      if (response.status === 200 && response.data) {
        const blob = new Blob([response.data], { type: "application/pdf" });
        const pdfUrl = window.URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);
      } else {
        throw new Error("Invalid response from server");
      }
    } catch (err) {
      console.log("ERROR", err);
    } finally {
      closeLoader("handle_pdf_download");
      // hideLoader();
    }
  };
  const toggleFullscreen = () => {
    const element = document.querySelector(".fullscreen-object");
    if (!document.fullscreenElement) {
      element.requestFullscreen().catch((err) => {
        console.log(
          `Error attempting to enable full-screen mode: ${err.message}`
        );
      });
    } else {
      document.exitFullscreen();
    }
  };

  const handleDelete = async (row) => {
        try {
          openLoader("market_delete_list");
            const url = `${AUTH_API}/csa/marketCoordinator/delete/${row.id}`;
            const res = await axios.delete(url, {}, {
              headers: headers,
            });
            if (res.status === 200) {
              fetchMarketsWaitingList(activeTab)
              toast.success("Deleted Successfully!");
            }
          }
         catch (err) {
          console.log("ERROR", err);
          toast.error("Failed to Delete!");
        } finally {
          closeLoader("market_delete_list");
        } 
  };

  const handleOnDeleteAction = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Market cooridinator will be removed from the application.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, delete it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleDelete(row),
    });
  };
  
  return (
    <>
      <Stack gap={2}>
        {map(markets, (market = {}) => {
          const {
            id,
            vendorName,
            marketName,
            firstName,
            lastName,
            isHipAndCsaVdr,
            isDeletedCsaVendor,
            isHIPVendor,
            primaryEmail,
            isActive,
            fnsNum,
            lastUpdtTs,
            adminLastUpdtTs,
            isVerified,
            isEnableDelBtn,
            orgId,
          } = market;
          const isOpen = includes(openIds, id);
          return (
            <Stack direction="row" className="vendorContainer">
              {/* <Box>
                <Checkbox
                  checked={some(selectedRows, (row) => row.id === id)}
                  onChange={(e) => handleRowClick(e, vendor)}
                />
              </Box> */}
              <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                  <Grid xs={9}>
                    <Box>
                      <Grid container>
                        <Grid item xs={6} md={4}>
                          <Stack className="textContent">
                            <p className="contentTitle">Market Name</p>
                            <a href={"/kfreshfep/markets-co/" + id}>
                              {marketName || "null"}
                              <OpenInNewIcon />
                            </a>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Stack className="textContent" gap={0.5}>
                            <p className="contentTitle">Federal ID</p>
                            <p className="contentSubTitle">{fnsNum}</p>
                          </Stack>
                        </Grid>
                        {/* <Grid item xs={6} md={3}>
                        <Stack className="textContent" gap={0.5}>
                          <p className="contentTitle">Merchant Type</p>
                          {row?.isHipAndCsaVdr ? (
                            <>
                              <span className="badge badge-primary mr-1">
                                {row?.isDeletedCsaVendor ? "Deleted: " : ""} SPF
                                Vendor Registration
                              </span>
                              <span className="badge badge-info">
                                SPF Vendor Product
                              </span>
                            </>
                          ) : row?.isHIPVendor === "Y" ? (
                            <span className="badge badge-info">
                              SPF Vendor Product
                            </span>
                          ) : (
                            <span className="badge badge-primary">
                              SPF Vendor Registration
                            </span>
                          )}
                        </Stack>
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <Stack className="textContent" gap={0.5}>
                          <p className="contentTitle">Status</p>
                          <CustomSwitch
                            sx={{ alignSelf: "flex-start" }}
                            checked={isActive === "Y"}
                            verified={isVerified === "Y"}
                            onChange={(status) => {
                              const newVendor = {
                                ...vendor,
                                isActive: status ? "Y" : "N",
                                lastUpdtTs: new Date().getTime(),
                              };
                              handleBulkUpdate([newVendor]);
                            }}
                          />
                        </Stack>
                      </Grid> */}
                        <Grid item xs={6} md={4}>
                          <Stack className="textContent">
                            <p className="contentTitle">Email</p>
                            <Stack direction="row" alignItems="center">
                              <Typography variant="body1">
                                {primaryEmail}
                              </Typography>

                              {/* {row.isHIPVendor === "Y" &&
                              userRole?.Role &&
                              userRole?.Role === "ADMIN" && (
                                <>
                                  <IconButton
                                    aria-label="edit"
                                    onClick={() => {
                                      handleOnEditEmailAction(vendor);
                                    }}
                                  >
                                    <EditIcon />
                                  </IconButton>
                                </>
                              )} */}
                            </Stack>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Stack className="textContent">
                            <p className="contentTitle">
                              Market co-ordinator Last Updated Time
                            </p>
                            <Typography variant="body1">
                              {/* {lastUpdtTs} */}
                              {moment(lastUpdtTs).format("MM/DD/YYYY hh:mm A")}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Stack className="textContent">
                            <p className="contentTitle">
                              Staff Last Updated Time
                            </p>
                            <Typography variant="body1">
                              {/* {adminLastUpdtTs} */}
                              {moment(adminLastUpdtTs).format(
                                "MM/DD/YYYY hh:mm A"
                              )}
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={6} md={4}>
                          <Stack className="textContent">
                            <p className="contentTitle">Approved</p>
                            {isVerified === "Y" ? (
                              <Typography variant="body1">Yes</Typography>
                            ) : (
                              <a
                                href={
                                  "/kfreshfep/vendors/" +
                                  (vendorType === "HIPVDR" ? "hip" : "csa") +
                                  "?vendor_id=" +
                                  id
                                }
                              >
                                Pending
                              </a>
                            )}
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid xs={6} md={3}>
                    <Stack gap={1}>
                      <p
                        className="contentTitle txtBtnTitle"
                        style={{ textAlign: "center" }}
                      >
                        Action
                      </p>
                      <Stack direction="row" gap={1} className="actionButtons">
                        <>
                          {isEnableDelBtn && userRole?.Role === "ADMIN" && (
                            <IconButton
                              aria-label="audit"
                              onClick={() => handleOnDeleteAction(market)}
                            >
                              <Stack alignItems="center">
                                <DeleteIcon />
                                <span className="txtBtnTitle">Delete</span>
                              </Stack>
                            </IconButton>
                          )}

                          {/* <IconButton
                          aria-label="audit"
                          onClick={() => handleOnAuditAction(vendor)}
                        >
                          <Stack alignItems="center">
                            <AuditIcon />
                            <span className="txtBtnTitle">History</span>
                          </Stack>
                        </IconButton>

                        {row.isVerified === "N" && (
                          <IconButton
                            aria-label="audit"
                            onClick={() => handleOnFollowUpAction(vendor)}
                          >
                            <Stack alignItems="center">
                              <FlagIcon />
                              <span className="txtBtnTitle">Follow</span>
                            </Stack>
                          </IconButton>
                        )} */}
                          <IconButton
                            aria-label="view"
                            onClick={() => handleShowDocument(orgId)}
                          >
                            <Stack alignItems="center">
                              <VisibilityOutlinedIcon />
                              <span className="txtBtnTitle">Documents</span>
                            </Stack>
                          </IconButton>
                        </>
                      </Stack>
                    </Stack>
                  </Grid>
                </Grid>
                {/*<Stack direction="row" justifyContent="flex-end">
                <IconButton onClick={() => toggleDetails(id)}>
                  {/* {isOpen ? "Collapse details" : "Details"} */
                /*}
                  {isOpen ? (
                    <ExpandCircleDownIcon
                      sx={{ transform: "rotate(180deg)" }}
                    />
                  ) : (
                    <ExpandCircleDownIcon />
                  )}
                </IconButton>
              </Stack>*/}
                <Collapse in={isOpen}>
                  {isOpen && <Box>{renderDetails(market)}</Box>}
                </Collapse>
              </Box>
            </Stack>
          );
        })}
        <div>
          <Modal
            open={openModal}
            onClose={() => {
              setOpenModal(false);
              setPdfUrl(null);
              if (document.fullscreenElement) {
                document.exitFullscreen();
              }
            }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                bgcolor: "background.paper",
                p: 4,
                "& .css-levciy-MuiTablePagination-displayedRows": {
                  margin: "0px !important",
                },
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                  justifyContent: "center !important",
                },
                "& .css-wstmaz-MuiDataGrid-root .MuiDataGrid-cell--textLeft": {
                  justifyContent: "center",
                },
                "& .css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop": {
                  opacity: "0 !important",
                  transition: "none !important",
                },
                "& .MuiDataGrid-columnHeader": {
                  paddingLeft: "0px !important",
                },
                "& .MuiDataGrid-row": { paddingLeft: "0px !important" },
                "& .MuiDataGrid-cell ": {
                  display: "flex",
                  alignItems: "center",
                  borderBottom: "1px solid",
                  justifyContent: "center !important",
                },
              }}
            >
              <DataGridTable columns={COLUMN} data={tableData} />
              {/* Display PDF here */}
              {pdfUrl && (
                <>
                  <div style={{ position: "relative" }}>
                    <Button
                      onClick={toggleFullscreen}
                      sx={{
                        position: "absolute",
                        top: "12px",
                        right: "21%",
                        zIndex: 1000,
                        color: "#f1f1f1",
                      }}
                    >
                      <FullscreenIcon />
                    </Button>
                    <object
                      className="fullscreen-object"
                      data={pdfUrl}
                      type="application/pdf"
                      width="100%"
                      height="350px"
                    ></object>
                  </div>
                </>
              )}
            </Box>
          </Modal>
        </div>
      </Stack>
    </>
  );
}

MarketList.propTypes = {
  markets: PropTypes.array,
  onReject: PropTypes.func,
  onApprove: PropTypes.func,
  isLoading: PropTypes.bool,
  vendorType: PropTypes.any,
  userRole: PropTypes.object,
  renderDetails: PropTypes.func,
  fetchMarketsWaitingList: PropTypes.func,
  activeTab: PropTypes.string,
};

export default MarketList;

