import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Grid,
  Select,
  MenuItem,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { map } from "lodash";
import axiosInstance from "../../redux/interceptor";
import useLoader from "../../hooks/useLoader";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import HistoryIcon from "@mui/icons-material/History";
import DataGridTable from "../../common/DataGrid";
import EligibilityCriteriaModel from "./EligibilityCriteriaModel";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import HistoryModel from "./HistoryModel";
import toast from "react-hot-toast";

function EligibilityCriteria() {
  const { openLoader, closeLoader } = useLoader();
  const [person, setPerson] = useState();
  const [historyTableRow, setHistoryTableRow] = useState({});
  const [tableRow, setTableRow] = useState({});
  const [memberData, setMembarData] = useState();
  const [edit, setEdit] = useState(false);
  const [openHistory, setOpenHistory] = useState(false);

  const handleClose = () => {
    setEdit(false);
  };

  const handleCloseHistory = () => {
    setOpenHistory(false);
  };

  const handleOpenEdit = (data) => {
    setMembarData(data);
    setEdit(!edit);
  };

  const handleClickOpenHistory = (id) => {
    setOpenHistory(true);
    const payload = {
      person: id,
    };
    getHistory(payload);
  };

  const historyColumn = [
    {
      field: "propKey",
      headerName: "Family Size",
      flex: 1,
    },
    {
      field: "propValue",
      headerName: "Gross Monthly",
      flex: 1,
    },
    {
      field: "effectiveBeginDt",
      headerName: "Effective Begin Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (value.row.effectiveBeginDt) {
          return dayjs(value.row.effectiveBeginDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
    {
      field: "effectiveEndDt",
      headerName: "Effective End Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (dayjs(value.row.effectiveEndDt).year() !== 9999) {
          return dayjs(value.row.effectiveEndDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
  ];

  const column = [
    {
      field: "propKey",
      headerName: "Family Size",
      flex: 1,
    },
    {
      field: "propValue",
      headerName: "Gross Monthly",
      flex: 1,
    },
    {
      field: "effectiveBeginDt",
      headerName: "Effective Begin Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (value.row.effectiveBeginDt) {
          return dayjs(value.row.effectiveBeginDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
    {
      field: "effectiveEndDt",
      headerName: "Effective End Date",
      flex: 1,
      valueGetter: (value, row) => {
        if (dayjs(value.row.effectiveEndDt).year() !== 9999) {
          return dayjs(value.row.effectiveEndDt).format("YYYY-MM-DD");
        } else {
          return "-";
        }
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          <Box>
            <IconButton onClick={() => handleOpenEdit(params.row)}>
              <Tooltip title="Edit" placement="right">
                <ModeEditOutlineOutlinedIcon />
              </Tooltip>
            </IconButton>
            <IconButton
              onClick={() => handleClickOpenHistory(params.row?.propKey)}
            >
              <Tooltip title="Hostory" placement="right">
                <HistoryIcon />
              </Tooltip>
            </IconButton>
          </Box>
        </>
      ),
    },
  ];

  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: {
      propKey: "",
      propValue: "",
      effectiveBeginDt: null,
    },
  });
  const handleChange = (event) => {
    setPerson(event.target.value);
  };
  const clear = () => {
    reset({
      propKey: "",
      propValue: "",
      effectiveBeginDt: null,
    });
  };
  const names = [
    "1 Person",
    "2 Person",
    "3 Person",
    "4 Person",
    "5 Person",
    "6 Person",
    "7 Person",
    "8 Person",
    "9 Person",
    "10 Person",
  ];
  const fetchValues = async () => {
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/eligibility/criteria`,
        {}
      );

      if (res?.status === 200) {
        const values = res?.data;
        setTableRow(values);
      }
    } catch (error) {
      //
    } finally {
      closeLoader("fetch_el_cr");
    }
  };
  const getHistory = async (data) => {
    try {
      openLoader("fetch_el_cr");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/eligibility/criteria/history`,
        data
      );

      if (res?.status === 200) {
        setHistoryTableRow(res?.data);
      }
    } catch (error) {
      //
    } finally {
      closeLoader("fetch_el_cr");
    }
  };

  useEffect(() => {
    fetchValues();
  }, []);

  const onSubmit = async (data) => {
    const payload = [
      {
        propValue: data?.propValue,
        propKey: data?.propKey,
        effectiveBeginDt: data?.effectiveBeginDt,
      },
    ];
    openLoader("sub_el_cr");
    try {
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/staff/save/eligibility/criteria`,
        payload
      );
      if (res.status === 200) {
        fetchValues();
      }
    } catch (error) {
      toast.error("County already exists");
    } finally {
      closeLoader("sub_el_cr");
    }
  };
  // const formik = useFormik({
  //   initialValues: {

  //     //    status: "PE",
  //   },
  //   // validationSchema: activeTab === "details" ? validationSchema : null,
  //   onSubmit: async (values) => {
  //     // await updateMarketCoordinator(values);
  //   },
  //   enableReinitialize: true,
  // });

  return (
    <>
      <Box
        className="ven-reg-pg"
        sx={{
          p: "1rem",
          flexGrow: 1,
          display: "flex",
          gap: "1rem",
          flexDirection: "column",
        }}
      >
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Typography
            sx={{ fontWeight: 600, fontSize: "24px" }}
            display="inline"
            gutterBottom
            component="div"
          >
            Income Limit
          </Typography>
        </Stack>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Box
            sx={{
              gap: "1rem",
              display: "flex",
              flexDirection: "column",
              border: "1px solid grey",
              borderRadius: "1rem",
              padding: "1rem",
            }}
          >
            <Grid container xs={12} spacing={2}>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <AccountCircleOutlinedIcon />
                <Typography
                  fontWeight="fontWeightBold"
                  className="typography"
                  variant="h6"
                >
                  Number of Members
                </Typography>
                <Select
                  fullWidth
                  {...register("propKey")}
                  placeholder="Select County"
                >
                  {map(names, (item) => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <CalendarMonthOutlinedIcon />
                <Typography
                  fontWeight="fontWeightBold"
                  className="typography"
                  variant="h6"
                >
                  Begin Date
                </Typography>
                <Controller
                  name="effectiveBeginDt"
                  control={control}
                  required
                  render={({ field }) => (
                    <>
                      <DatePicker
                        {...field}
                        disablePast
                        slotProps={{
                          textField: {
                            error: false,
                          },
                        }}
                        format="YYYY/MM/DD"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        type="date"
                        placeholder="Select Date"
                      />
                    </>
                  )}
                />
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <AccountBalanceOutlinedIcon />
                <Typography className="smallTitle">Amount</Typography>
                <TextField
                  placeholder="Enter Amount"
                  type="number"
                  {...register("propValue", { valueAsNumber: true })}
                />
              </Grid>
            </Grid>
            <Grid sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Box sx={{ display: "flex", gap: "10px" }}>
                <Button
                  variant="outlined"
                  sx={{ color: "#6C6C6C", border: "1px solid #6C6C6C" }}
                  onClick={clear}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  type="submit"
                  style={{
                    textTransform: "none",
                    width: "100px",
                    background: "#457425",
                    color: "#FFF",
                  }}
                >
                  Approve
                </Button>
              </Box>
            </Grid>
          </Box>
          {/* <Box>
          <Button
            sx={{ ml: "auto", mt: "1rem", mr: "1rem" }}
            variant="contained"
            color="success"
            style={{ textTransform: "none" }}
            onClick={handleClickOpen}
          >
            Update Income List
          </Button>
        </Box> */}
        </Form>
        <DataGridTable columns={column} data={tableRow} />
      </Box>

      {edit && (
        <EligibilityCriteriaModel
          open={edit}
          names={names}
          handleClose={handleClose}
          editData={memberData}
          fetchValues={fetchValues}
        />
      )}
      {openHistory && (
        <HistoryModel
          openHistory={openHistory}
          handleClickCloseHistory={handleCloseHistory}
          historyColomns={historyColumn}
          historyTableRow={historyTableRow}
        />
      )}
      {/* <Dialog
        maxWidth="md"
        fullWidth
        scroll="paper"
        open={openHistory}
        onClose={handleCloseHistory}
        aria-labelledby="responsive-dialog-title"
        sx={{ paddingBottom: "50px" }}
      >
        <DialogTitle id="responsive-dialog-title">
          History Income limits
        </DialogTitle>
        <DialogContent>
         
          <DataGridTable columns={historyColumn} data={historyTableRow} />
        </DialogContent>
      </Dialog> */}
    </>
  );
}

export default EligibilityCriteria;
