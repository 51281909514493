/* eslint-disable */
import React, { useEffect, useState, useRef } from "react"; //
import PropTypes from "prop-types";
import { useFieldArray } from "react-hook-form";
// import SignupNestedFieldArray from "./signup-nested-fieldarray";
// import { ToggleButton, ToggleButtonGroup } from "react-bootstrap";
import SelectDropdown from "../../common/selectdropdown-component";
import Map from "../../common/map";
import Geocode from "react-geocode";
import { useFormik } from "formik";
// import { useNavigate } from "react-router-dom";
import { Form, FormControl, Table, Button } from "react-bootstrap"; //, Row , Col
import SignupNestedFieldArray from "./signup-nested-fieldarray";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import _ from "lodash";
import "../../../styles/registration.scss";
import { GOOGLE_KEY, AUTH_API, headers } from "../../../config";
import { popUpAlert } from "../../../utils/globalMethods";
import {
  Stack,
  MenuItem,
  Chip,
  Select,
  FormControl as MUIFormControl,
  Typography,
  Box,
  DialogContent,
  Dialog,
  RadioGroup,
  FormControlLabel,
  Radio,
  Grid,
} from "@mui/material";
import FileUploadModal from "../../../common/fileUpload/FileUploadModal";

import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Cancel";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import axios from "axios";
import useTranslator from "../../../hooks/useTranslator";

// import "./styles.css";
const StyledLocStatTgl = styled.div`
    margin-top: -5px;
    border-left: 1px solid #ccc;
    padding-left: 10px;
    float: right;
    font-weight: 600;
}`;

let renderCount = 0;

Geocode.setApiKey(GOOGLE_KEY);
Geocode.setLanguage("en");

const SignupFieldArray = (
  {
    type,
    control,
    getValues,
    setValue,
    register,
    watch,
    section,
    programData,
    errors,
    city,
    days,
    months,
    order_option,
    indexSubmit,
    selectedCityList,
    setZipcodeList,
    setLatLngList,
    setFarmerMarketList,
    getVendorStoreId,
    farmermarkets,
    refreshKey,
    isReset,
    deleteStore,
    existPgmData,
    programArray,
    // setSelectedNames,
    // selectedNames,
    user,
    // setFormData
  },
  props
) => {
  const transProps = useTranslator(
    [
      "update",
      "fileUpload",
      "cancel",
      "location",
      "discard",
      "suggestedAddress",
      "originalAddress",
      "addressInformation",
    ],
    "userRegistrationComponent"
  );
  const [isOtherTxt, setIsOtherTxt] = useState();
  const [otherTxt, setOtherTxt] = useState();
  const [sectionData, setSectionData] = useState([]);
  const [selectedCity, setSelectedCity] = useState(); //{ values: "Select City", name: "" }
  const [selectedState, setSelectedState] = useState({
    values: "MI",
    name: "MI",
  }); //
  const [selectedFarmerMarket, setSelectedFarmerMarket] = useState(); //{ values: "Select Farmers' Market", name: "" }
  const [address1, setAddress1] = useState();
  const [address2, setAddress2] = useState();
  const [zipcode, setZipcode] = useState();
  const [latLng, setLatLng] = useState();
  const [mapShow, setMapShow] = useState(true);
  const [isSpecialHours, setIsSpecialHours] = useState(false);
  const [specialInst, setSpecialInst] = useState();
  const [specialHour, setSpecialHour] = useState();
  const [open, setOpen] = useState(false);
  const [smartyValue, setSmartyValue] = useState("");
  const [onEdit, setOnEdit] = useState();
  const [currentSection, setCurrentSection] = useState();
  const [docOpen, setDocOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [currentSectionIndex, setCurrentSectionIndex] = useState();
  const [addressVal1, setAddressVal1] = useState("");
  const [addressVal2, setAddressVal2] = useState("");
  const [zipcodeVal, setZipcodeVal] = useState("");
  const [cityVal, setCityVal] = useState("");
  // const [deleteStoreDetail, setDeleteStore] = useState();
  const myRef = useRef(null);
  const { fields, append, replace, prepend, remove, swap, move, insert } =
    useFieldArray({
      control,
      name: `program[${section.id}]`,
    });
  const w = watch();


  useEffect(() => {
    const getProductsList = async () => {
      try {
        const res = await axios.post(
          AUTH_API + `/farmers/get/productsList`,
          {},
          {
            headers: headers,
          }
        );
        if (res && res.data) {
          setProducts(res.data);
        }
      } catch (error) {
        console.log(error);
      }
    };
    getProductsList();
  }, []);

  const handleModalOpen = () => {
    if(section.id === "farmersMarket"){
      return;
    }
    setDocOpen(true);
  };

  useEffect(() => {
    if (onEdit?.[currentSection + currentSectionIndex]) {
      console.log(existPgmData?.[currentSection]?.[currentSectionIndex]);
      setValue(
        `program[${currentSection}][${currentSectionIndex}]`,
        existPgmData?.[currentSection]?.[currentSectionIndex]
      );
    }
  }, [onEdit]);

  useEffect(() => {
    if (!programArray) {
      const clone = _.cloneDeep(programData);
      replace([
        {
          name: {
            working_hours: clone[section.id]?.working_hours,
            storeAvailFlag: true,
          },
          special_hoursOfOperation: [
            { ...clone[section.id]?.special_hoursOfOperation[0] },
          ],
          updated: false,
        },
      ]);
    } else {
      replace(programArray);
    }
  }, [programArray, replace]);

  useEffect(() => {
    if (fields && fields.length) {
      let editStatus = {};
      console.log(existPgmData);
      fields.map((item, i) => {
        if (existPgmData) {
          editStatus[section.id + i] =
            section.id in existPgmData && existPgmData[section.id][i]
              ? false
              : true;
        }
      });
      setOnEdit(editStatus);
    }
  }, [refreshKey, section, fields]);

  useEffect(() => {
    if (fields && fields.length) {
      const otherIndex = order_option.findIndex((x) => x.values === "Other");
      fields.map((item, i) => {
        if (item?.name) {
          setValue(
            `program[${section.id}][${i}].name.pickup_site_name`,
            item?.name?.pickup_site_name
          );
          setValue(
            `program[${section.id}][${i}].name.location_name`,
            item?.name?.location_name
          );
          setValue(
            `program[${section.id}][${i}].name.address_1`,
            item?.name?.address_1
          );
          setValue(
            `program[${section.id}][${i}].name.address_2`,
            item?.name?.address_2
          );
          setValue(
            `program[${section.id}][${i}].name.zipcode`,
            item?.name?.zipcode
          );
        }
        setIsOtherTxt((prevState) => ({
          ...prevState,
          [`program[${section.id}][${i}].name[distribution_option][${otherIndex}]`]:
            item?.name?.distribution_option?.includes("Other") ? true : false,
        }));
      });
    }
  }, [fields]);

  const appendForm = () => {
    const clone = _.cloneDeep(programData);
    append({
      name: {
        working_hours: clone[section.id].working_hours,
        storeAvailFlag: clone[section.id].storeAvailFlag
          ? clone[section.id].storeAvailFlag
          : "Y",
      },
      special_hoursOfOperation: [
        { ...clone[section.id].special_hoursOfOperation[0] },
      ],
    });
  };

  const handleInputChange = (e, field, id, index) => {
    setTimeout(() => {
      const val = e.target.value;
      let address_1 = "";
      let address_2 = "";
      let zipcodeVal = "";
      const city =
        selectedCity && selectedCity[`program[${id}][${index}].name[city]`]
          ? selectedCity[`program[${id}][${index}].name[city]`].name
          : "";
      setCityVal(city);
      if (field === "address_1") {
        address_1 = val;
        setAddress1((prevState) => ({
          ...prevState,
          [`program[${id}][${index}].name.address_1`]: address_1,
        }));
        setAddressVal1(val);
        // formik.setValues({"",})
        getLatLng(address_1, address2, zipcodeVal, city, id, index);
      } else if (field === "address_2") {
        address_2 = val;
        setAddress2((prevState) => ({
          ...prevState,
          [`program[${id}][${index}].name.address_2`]: address_2,
        }));
        setAddressVal2(val);
        getLatLng(address1, address_2, zipcodeVal, city, id, index);
      } else if (field === "zipcode") {
        zipcodeVal = val;
        // if (!isNaN(zipcodeVal)) {
        setZipcode((prevState) => ({
          ...prevState,
          [`program[${id}][${index}].name[zipcode]`]: zipcodeVal,
        }));
        setValue(`program[${id}][${index}].name[zipcode]`, zipcodeVal);

        setZipcodeList({
          ...zipcode,
          ...{
            [`program[${id}][${index}].name[zipcode]`]: zipcodeVal,
          },
        });
        // }
        setZipcodeVal(val);
        getLatLng(address1, address2, zipcodeVal, city, id, index);
        if (zipcodeVal.length > 4) {
          postSmarty(
            getValues()?.program?.[section.id]?.[index]?.name?.address_1
              ? getValues()?.program?.[section.id]?.[index]?.name?.address_1
              : address_1?.[`program[${section.id}][${index}].name[address_1]`],
            getValues()?.program?.[section.id]?.[index]?.name?.address_2
              ? getValues()?.program?.[section.id]?.[index]?.name?.address_2
              : address_2?.[`program[${section.id}][${index}].name[address_2]`],
            zipcodeVal,
            getValues()?.program?.[section.id]?.[index]?.name?.city
              ? getValues()?.program?.[section.id]?.[index]?.name?.city?.name
              : cityName
          );
        }
      }
    }, 500);
  };
  const formik = useFormik({
    initialValues: {
      addressLine1: addressVal1 ? addressVal1 : "",
      addressLine2: addressVal2 ? addressVal2 : "",
      city: cityVal ? cityVal : "",
      zipCd: zipcodeVal ? zipcodeVal : "",
      smartAddress: "",
    },
    onSubmit: (values) => {
      postContactInfo(values);
    },
  });
  const postSmarty = async (address_1, address_2, zipcode, city) => {
    try {
      // setIsLoading(true);
      let payload = {
        addressId: 0,
        zip4code: "",
        addressVerifiedYN: "N",
        institutionYN: "N",
        vendorId: 0,
        homeAddressYN: "N",
        status: true,
        addressLine1: address_1,
        addressLine2: address_2,
        city: city,
        state: "MI",
        zipcode: zipcode,
        county: "",
        district: "",
      };
      const smartyHeader = {
        Role: "cn=EE_SUPVRS,|cn=EE_SUPUSR, |cn=EE_VEND_ADMN",
        uid: "localUser",
        Uniqueid: "7c438d573165cdd1552231ed3bdd33",
        "Content-Type": "application/json",
      };
      const res = await axios.post(
        `${process.env.REACT_APP_SMARTY_STREET_URL}/verification/address`,
        payload,
        {
          headers: smartyHeader,
        }
      );

      if (res && res.data) {
        setOpen(true);
        if (res.data.results[0] === 0) {
          setSmartyValue([]);
        } else {
          setSmartyValue(res?.data?.results[0]);
        }
        //  handleNext();
      }
    } catch (error) {
      console.log(error);
    } finally {
      // setIsLoading(false);
    }
  };

  const postContactInfo = async (values) => {
    //set the values
    //setValue(`program[${id}][${index}].name[zipcode]`, zipcodeVal);
    fields.map((item, i) => {
      if (item?.name) {
        setValue(
          `program[${section.id}][${i}].name.address_1`,
          values.smartAddress === "0" ? addressVal1 : smartyValue?.addressLine1
        );
        // setValue(`program[${section.id}][${i}].name.city`, values.smartAddress === "0" ? : smartyValue?.city);
        setValue(
          `program[${section.id}][${i}].name.zipcode`,
          values.smartAddress === "0" ? zipcodeVal : smartyValue?.zipcode
        );
      }
    });
    setOpen(false);
  };

  const getLatLng = (
    address_1,
    address_2,
    zipcode,
    city,
    id = "",
    index = 0
  ) => {
    setTimeout(() => {
      let cityName = city && city.name ? city.name : "";
      address_1 = getValues()?.program?.[section.id]?.[index]?.name?.address_1
        ? getValues()?.program?.[section.id]?.[index]?.name?.address_1
        : address_1?.[`program[${section.id}][${index}].name[address_1]`];
      address_2 = getValues()?.program?.[section.id]?.[index]?.name?.address_2
        ? getValues()?.program?.[section.id]?.[index]?.name?.address_2
        : address_2?.[`program[${section.id}][${index}].name[address_2]`];
      cityName = getValues()?.program?.[section.id]?.[index]?.name?.city
        ? getValues()?.program?.[section.id]?.[index]?.name?.city?.name
        : cityName;
      zipcode = getValues()?.program?.[section.id]?.[index]?.name?.zipcode
        ? getValues()?.program?.[section.id]?.[index]?.name?.zipcode
        : zipcode;

      var formatted_address = "";
      formatted_address += address_1 ? address_1 + "," : "";
      formatted_address += address_2 ? address_2 + "," : "";
      formatted_address += cityName ? cityName + "," : "";
      formatted_address += selectedState ? selectedState.name + "," : "";
      formatted_address += zipcode ? zipcode : "";
      if (!address_1 && !address_2 && !city && !zipcode) {
        formatted_address = "Boston,MA";
      }
      Geocode.fromAddress(formatted_address).then(
        (response) => {
          const { lat, lng } = response.results[0].geometry.location;
          if (id) {
            setLatLng((prevState) => ({
              ...prevState,
              [`program[${id}][${index}].name[latlng]`]: { lat: lat, lng: lng },
            }));
            setLatLngList({
              ...latLng,
              ...{
                [`program[${id}][${index}].name[latlng]`]: {
                  lat: lat,
                  lng: lng,
                },
              },
            });
          }
        },
        (error) => {
          console.error(error);
        }
      );
    }, 500);
  };

  const getFarmerMarkets = () => {
    // let farmerMarketList =
    //   farmermarkets !== undefined &&
    //   farmermarkets.map((item) => {
    //     let market = {
    //       values: item.name,
    //       name: item.id,
    //       address: item.addressDetail.addressLine1,
    //       city: item.addressDetail.city,
    //       zip: item.addressDetail.zipcode,
    //     };

    //   return market;
    // });
    //       Allen Farmers Market
    // 1611 East Kalamazoo Street, Lansing, 48912
    let farmerMarketList = [
      {
        values: "Allen Farmers Market",
        name: 1,
        address: "1611 East Kalamazoo Street",
        city: "Lansing",
        zip: 48912,
      },
      {
        values: "Farmers Markets at the Capitol",
        name: 2,
        address: "100 N Capitol Ave",
        city: "Lansing",
        zip: 48933,
      },
      {
        values: "Auburn Hills My Garden Produce",
        name: 3,
        address: "3157 Auburn Road",
        city: "Auburn Hills",
        zip: 48326,
      },
      {
        values: "Farmington Farmers and Artisans Market",
        name: 4,
        address: "33113 Grand River Avenue",
        city: "Farmington",
        zip: 48335,
      },
      {
        values: "Wayne Open-Air Market",
        name: 5,
        address: "3144 South Wayne Road",
        city: "Wayne",
        zip: 48184,
      },
      {
        values: "Livonia Farmers’ Market",
        name: 6,
        address: "29350 West Chicago",
        city: "Livonia",
        zip: 48150,
      },
    ];
    const defaultData = [{ name: "", values: "Select Farmers' Market" }];
    return defaultData.concat(farmerMarketList);
  };

  const getSpecialHourData = (i) => {
    return specialHour && specialHour[i] ? specialHour[i] : [];
  };

  const setSpecialHourData = (data) => {
    // console.log("setSpecialHourData::",data);
    setSpecialHour((prevState) => ({
      ...prevState,
      [data.index]: data.data,
    }));
  };

  const scrollToRef = (ref) =>
    ref.current && window.scrollTo(0, ref.current.offsetTop);

  // const swalAlertAuto = (type, msg, id, index) => {
  //     var typeTxt = "";
  //     if (type === "success") {
  //       typeTxt = "Success";
  //     } else if (type === "error") {
  //       typeTxt = "Failed";
  //     }
  //     swal({
  //       icon: type,
  //       type: type,
  //       title: typeTxt,
  //       text: msg,
  //       buttons: ["Cancel", true],
  //       dangerMode: true,
  //     //   timer: 2000
  //     }) .then((willDelete) => {
  //         if (willDelete) {
  //             getVendorStoreId(id,"remove");
  //             remove(index);
  //         }
  //       });
  //   };

  const swalAlertRemove = (row) => {
    popUpAlert({
      icon: "warning",
      title: "Are you sure?",
      text: "Remove this location of this type? Your data will be deleted.    Note: Before removing the location, please save any changes you've made on this page by clicking 'Update' ",
      color: "#545454",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-success",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
      cancelButtonColor: "#AAAAAA",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => {
        deleteStore(row);
      },
    });
  };

  const handleSwitchOnChange = (e, id) => {
    console.log(e);
    getVendorStoreId(id, "loc_toggle", e);
  };

  renderCount++;
  return (
    <>
      <Dialog
        title="Verify the address"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ padding: 2 }}
      >
        <DialogContent>
          <Box sx={{ width: "500px", margin: 2 }}>
            <Typography variant="h4" marginBottom={2}>
              {transProps.addressInformation}
            </Typography>
            <Grid m={2}>
              <RadioGroup
                {...formik.getFieldProps("smartAddress")}
                defaultValue={smartyValue?.addressLine1 ? 1 : 0}
              >
                <Typography variant="h5" color={"gray"}>
                  {transProps.originalAddress}
                </Typography>
                <FormControlLabel
                  name="smartAddress"
                  value={0}
                  control={<Radio />}
                  label={
                    addressVal1 +
                    " " +
                    addressVal2 +
                    " " +
                    cityVal +
                    " " +
                    "MI" +
                    " " +
                    zipcodeVal
                  }
                />
                <Typography variant="h5" color={"gray"}>
                  {transProps.suggestedAddress}
                </Typography>
                <FormControlLabel
                  name="smartAddress"
                  value={1}
                  control={<Radio disabled={!smartyValue?.addressLine1} />}
                  label={
                    smartyValue?.addressLine1
                      ? smartyValue?.addressLine1 +
                        " " +
                        smartyValue?.city +
                        " " +
                        smartyValue?.state +
                        " " +
                        smartyValue?.zipcode
                      : "-"
                  }
                />
              </RadioGroup>
            </Grid>
            <Stack direction="row" justifyContent="flex-end" spacing={2}>
              <Button
                variant="outlined"
                className="btn-primary"
                onClick={() => setOpen(false)}
              >
                {transProps.discard}
              </Button>
              <Button
                variant="contained"
                className="btn-primary"
                onClick={() => postContactInfo(formik.values)}
              >
                Save and Next
                {/* {transProps.saveAndNext} */}
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Dialog>
      <div id="marketManagersList">
        {onEdit &&
          fields.map((item, index) => {
            return (
              <div ref={myRef} key={index}>
                <div className="dh-legend">
                  {/* {section.title} */}
                  <StyledLocStatTgl>
                    {type === "edit" && (
                      <div className="dh-tgl-loc">
                        <i className="fa fa-map-marker"></i>{" "}
                        {transProps.location}{" "}
                      </div>
                    )}
                    <div className="dh-tgl-btn">
                      {onEdit[section.id + index] ||
                      !(section.id + index in onEdit) ? (
                        <>
                          {type === "edit" && (
                            <BootstrapSwitchButton
                              checked={item?.name?.storeAvailFlag === "Y"}
                              onlabel="Show"
                              offlabel="Hide"
                              width={78}
                              // style={"switch-margin"}
                              onstyle="success"
                              offstyle="danger"
                              style={"switch-margin"}
                              onChange={(e) => {
                                item.name.storeAvailFlag =
                                  e === true ? "Y" : "N";
                                setValue(
                                  `program[${section.id}][${index}].name.storeAvailFlag`,
                                  e
                                );
                              }}
                            />
                          )}
                          <Button
                            type="submit"
                            onClick={() => indexSubmit(index, section.id)}
                            className="btn btn-primary m-r-5"
                          >
                            <span>
                              <i className="fa fa-check"></i>{" "}
                              {transProps.update}
                            </span>
                          </Button>
                          {existPgmData &&
                            section.id in existPgmData &&
                            [index] in existPgmData[section.id] && (
                              <Button
                                type="button"
                                onClick={() => {
                                  setValue(
                                    `program[${section.id}][${index}]`,
                                    existPgmData[section.id][index]
                                  );
                                  setSelectedCity((prevState) => ({
                                    ...prevState,
                                    [`program[${section.id}][${index}].name[city]`]:
                                      existPgmData[section.id][index].name.city,
                                  }));
                                  setSelectedFarmerMarket((prevState) => ({
                                    ...prevState,
                                    [`program[${section.id}][${index}].name[farmer_market_name]`]:
                                      {
                                        values:
                                          existPgmData[section.id][index]?.name
                                            ?.farmer_market_name,
                                        name: existPgmData[section.id][index]
                                          ?.name?.addressDetail?.id,
                                        address:
                                          existPgmData[section.id][index]?.name
                                            ?.addressDetail?.addressLine1,
                                        city: existPgmData[section.id][index]
                                          ?.name?.addressDetail?.city,
                                        zip: existPgmData[section.id][index]
                                          ?.name?.addressDetail?.zipcode,
                                      },
                                  }));
                                  setOnEdit({
                                    ...onEdit,
                                    [section.id + index]: false,
                                  });
                                }}
                                className="btn btn-primary"
                              >
                                <span>
                                  <i className="fa fa-times"></i> Cancel
                                </span>
                              </Button>
                            )}
                        </>
                      ) : (
                        <>
                          {" "}
                          {/* <BootstrapSwitchButton
                            checked={item?.name?.storeAvailFlag === "Y"}
                            disabled={true}
                            onlabel="Show"
                            offlabel="Hide"
                            width={78}
                            // style={"switch-margin"}
                            onstyle="success"
                            offstyle="danger"
                            style={"button-fade switch-margin"}
                            onChange={(e) => {
                              item.name.storeAvailFlag = e;
                              setValue(
                                `program[${section.id}][${index}].name.storeAvailFlag`,
                                e
                              );
                            }}
                          /> */}
                          <Button
                            onClick={(e) => {
                              e.preventDefault();
                              setOnEdit({
                                ...onEdit,
                                [section.id + index]: true,
                              });
                              setCurrentSection(section.id);
                              setCurrentSectionIndex(index);
                            }}
                            className="btn btn-primary m-r-5"
                          >
                            <span>
                              <i className="fa fa-pencil"></i> Edit
                            </span>
                          </Button>
                        </>
                      )}
                    </div>
                  </StyledLocStatTgl>
                  <a
                    // style={{ display: "none" }}
                    className="dh-remove"
                    onClick={() => {
                      //Do you wish to hide store details
                      if (item.name.id) {
                        swalAlertRemove(item);
                      } else {
                        remove(index);
                      }

                      // console.log(item);
                      // if (item.name.id) {
                      //     getVendorStoreId(item.name.id);
                      // }
                      // setSectionData((prevActions) =>
                      //   // Filter out the item with the matching index
                      //   prevActions.filter((value, i) => i !== index)
                      // );
                    }}
                  >
                    <i className="fa fa-trash-o"></i>
                    &nbsp;Remove
                  </a>
                  <a
                    className="dh-remove"
                    style={{
                      opacity:
                        section.id + index in onEdit &&
                        !onEdit[section.id + index]
                          ? "1"
                          : "0.5",
                      // display: "none",
                    }}
                    onClick={() => {
                      if (
                        section.id + index in onEdit &&
                        !onEdit[section.id + index]
                      ) {
                        const data = _.cloneDeep(
                          getValues().program[section.id][index].name
                        );
                        data.id = "";
                        append({
                          name: data,
                          special_hoursOfOperation: [
                            ...getValues().program[section.id][index]
                              .special_hoursOfOperation,
                          ],
                        });
                        setLatLngList({
                          ...latLng,
                          ...{
                            [`program[${section.id}][${
                              index + 1
                            }].name[latlng]`]: {
                              lat:
                                latLng &&
                                latLng[
                                  `program[${section.id}][${index}].name[latlng]`
                                ]
                                  ? latLng[
                                      `program[${section.id}][${index}].name[latlng]`
                                    ].lat
                                  : "",
                              lng:
                                latLng &&
                                latLng[
                                  `program[${section.id}][${index}].name[latlng]`
                                ]
                                  ? latLng[
                                      `program[${section.id}][${index}].name[latlng]`
                                    ].lng
                                  : "",
                            },
                          },
                        });
                        scrollToRef(myRef);
                      }
                    }}
                  >
                    <i className="fa fa-files-o" aria-hidden="true"></i>
                    &nbsp;Copy these Details
                  </a>
                </div>
                <fieldset
                  className="dh-fieldset"
                  style={{ marginTop: "15px", width: "100%" }}
                  disabled={
                    section.id + index in onEdit
                      ? !onEdit[section.id + index]
                      : false
                  }
                >
                  <Row
                    formProps={{
                      register,
                      control,
                      errors,
                      remove,
                    }}
                    getLatLng={getLatLng}
                    handleInputChange={handleInputChange}
                    months={months}
                    days={days}
                    watch={watch}
                    order_option={order_option}
                    section={section}
                    otherTxt={otherTxt}
                    id={section.id}
                    field_item={item}
                    index={index}
                    setOtherTxt={setOtherTxt}
                    setIsOtherTxt={setIsOtherTxt}
                    isOtherTxt={isOtherTxt}
                    getValues={getValues}
                    setValue={setValue}
                    programData={programData}
                    sectionData={sectionData}
                    selectedCity={selectedCity}
                    city={city}
                    setSelectedCity={setSelectedCity}
                    address1={address1}
                    address2={address2}
                    zipcode={zipcode}
                    selectedCityList={selectedCityList}
                    selectedState={selectedState}
                    setMapShow={setMapShow}
                    mapShow={mapShow}
                    setZipcodeList={setZipcodeList}
                    setZipcode={setZipcode}
                    // setSelectedNames={setSelectedNames}
                    // selectedNames={selectedNames}
                    products={products}
                    latLng={latLng}
                    setSpecialInst={setSpecialInst}
                    selectedFarmerMarket={selectedFarmerMarket}
                    getFarmerMarkets={getFarmerMarkets}
                    setSelectedFarmerMarket={setSelectedFarmerMarket}
                    setFarmerMarketList={setFarmerMarketList}
                    setAddress1={setAddress1}
                    setIsSpecialHours={setIsSpecialHours}
                    isSpecialHours={isSpecialHours}
                    setSpecialHourData={setSpecialHourData}
                    getSpecialHourData={getSpecialHourData}
                    onClear={() => {
                      reset({
                        test: fields.map((f, idx) => {
                          return idx === index
                            ? { name: "reset", nest: {} }
                            : f;
                        }),
                      });
                    }}
                    onEdit={
                      section.id + index in onEdit
                        ? !onEdit[section.id + index]
                        : false
                    }
                  />
                  {(onEdit[section.id + index] ||
                    !(section.id + index in onEdit)) && (
                    <div className="row mb-2">
                      <div className="col-12">
                        <div className="pull-right  mt-2 mt-lg-0 mt-md-0 pl-2">
                          <Button
                            type="submit"
                            onClick={() => indexSubmit(index, section.id)}
                            className="btn btn-primary m-r-5"
                          >
                            <span>
                              <i className="fa fa-check"></i>{" "}
                              {transProps.update}
                            </span>
                          </Button>
                          {existPgmData &&
                            section.id in existPgmData &&
                            [index] in existPgmData[section.id] && (
                              <Button
                                type="button"
                                onClick={() => {
                                  setValue(
                                    `program[${section.id}][${index}]`,
                                    existPgmData[section.id][index]
                                  );
                                  setOnEdit({
                                    ...onEdit,
                                    [section.id + index]: false,
                                  });
                                }}
                                className="btn btn-primary"
                              >
                                <span>
                                  <i className="fa fa-times"></i>{" "}
                                  {transProps.cancel}
                                </span>
                              </Button>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                  <Button
                    onClick={handleModalOpen}
                    sx={{
                      border: "1px solid #44A0E3 !important",
                      color: " var(--Secon-1-D-Blue, #44A0E3)",
                      fontSize: "14px !important ",
                      fontWeight: "700 !important",
                    }}
                    // disabled={disabled}
                  >
                    <UploadFileIcon />
                    {transProps.fileUpload}
                  </Button>

                  <FileUploadModal
                    docOpen={docOpen}
                    section={section}
                    setDocOpen={setDocOpen}
                    payload={{
                      createDt: dayjs().format("YYYY/MM/DD"),
                      createUserId: user.firstName + " " + user.lastName,
                      role: user.roleName,
                      sourceCaseId: user.orgId,
                    }}
                  />
                </fieldset>
              </div>
            );
          })}
      </div>
      <div className="form-text text-muted" style={{ marginTop: "10px" }}>
      {/* <span>To add another {section.title}?</span> */}
      <span>To add another SPF Farmers Market ?</span>
        <span
          onClick={() => {
            appendForm();
          }}
          className="n-wrap"
        >
          &nbsp;<i className="fa fa-plus"></i> Add More
        </span>
      </div>
    </>
  );
};

function Row({
  id,
  formProps,
  handleInputChange,
  field_item,
  index,
  setSelectedCity,
  selectedCityList,
  selectedCity,
  city,
  watch,
  selectedState,
  mapShow,
  setMapShow,
  setZipcode,
  setZipcodeList,
  latLng,
  setSpecialInst,
  selectedFarmerMarket,
  getFarmerMarkets,
  setSelectedFarmerMarket,
  getLatLng,
  address1,
  address2,
  zipcode,
  // setSelectedNames,
  // selectedNames,
  products,
  months,
  days,
  order_option,
  section,
  programData,
  sectionData,
  setFarmerMarketList,
  setAddress1,
  setValue,
  getValues,
  onClear,
  otherTxt,
  isOtherTxt,
  setOtherTxt,
  setIsOtherTxt,
  setIsSpecialHours,
  isSpecialHours,
  setSpecialHourData,
  getSpecialHourData,
  onEdit,
}) {
  const { register, control, remove, errors } = formProps;
  const transProps = useTranslator(
    [
      "registration",
      "locType",
      "publicInfo",
      "register",
      "regCnt",
      "regSpf",
      "needHelp",
      "needHelpSpf",
      "needHelpCnt",
      "basicRegistration",
      "firstName",
      "fnameReq",
      "lastName",
      "lnameReq",
      "routingNumber",
      "routinRequired",
      "routingDigits",
      "bankingAccNum",
      "bankingReq",
      "bankingDigit",
      "bankName",
      "bankNameReq",
      "bankType",
      "banktypeReq",
      "selectBankType",
      "savings",
      "checking",
      "federalId",
      "remove",
      "federalNumReq",
      "federalNumDigits",
      "primaryEmail",
      "primaryEmailReq",
      "invalidEmailAddress",
      "primaryPhoneNumber",
      "primaryPhoneNumRequired",
      "invalidPhoneNumber",
      "password",
      "passwordRequired",
      "confirmPassword",
      "confirmPasswordRequired",
      "passwordsNotMatch",
      "signatureDate",
      "signature",
      "registerAndNext",
      "merchantName",
      "merchantNameRequired",
      "publicEmailAddress",
      "publicPhone",
      "website",
      "invalidWebsite",
      "businessDescription",
      "maximumCharacter",
      "maxCharacter",
      "vendorPhoto",
      "notAvailable",
      "clickEdit",
      "fileShould",
      "fileFormat",
      "saveAndNext",
      "locationType",
      "SPFFarmersMarket",
      "SPFFarmersMarketBooth",
      "SPFProgram",
      "SPFFarmStand",
      "SPFMobileMarketStop",
      "yes",
      "no",
      "marketsList",
      "finish",
      "claremont",
      "downtown",
      "redondo",
      "burbank",
      "hollywood",
      "alhambra",
      "culver",
      "encino",
      "venice",
      "spfPickupSite",
      "pickupSiteName",
      "startDate",
      "endDate",
      "address1",
      "address2",
      "city",
      "state",
      "zipcode",
      "farmStand",
      "farmersMarketBooth",
      "farmerMarketName",
      "mobileMarket",
      "locationName",
      "farmersMarket",
      "specialInstr",
      "workingHours",
      "products",
      "monthOperations",
      "storeName",
      "january",
      "february",
      "march",
      "april",
      "may",
      "june",
      "july",
      "august",
      "september",
      "october",
      "november",
      "december",
      "whatDetails",
      "AM",
      "PM",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
      "sunday",
      "open",
      "close",
      "available",
      "allDay",
      "custom",
      "invalidZipcode",
      "day",
      "startTime",
      "endTime",
      "storeStatus",
      "closeMap",
      "showMap",
    ],
    "userRegistrationComponent"
  );
  //  const handleChange = (event) => {
  //    const { value } = event.target;
  //    setValue("selectedNames", value);
  //  };
  //  const selectedNames = watch("selectedNames");
  //  console.log("selectedNames", watch("selectedNames"));

  //  const handleDelete = (value) => {
  //    const newSelectedNames = selectedNames.filter((name) => name !== value);
  //    setValue("selectedNames", newSelectedNames);
  //  };
  return (
    <div key={index}>
      {section.questions.map((question, s) => {
      return (
        <div
          key={s}
          className={`${
            question.name === "is_specialhours" ? "row" : "form-group"
          }  ${question.className}`}
        >
          {question.label && (
            <div
              className={`${
                question.name === "is_specialhours"
                  ? "col-md-8 col-sm-12 col-lg-4 special-hof pr-0"
                  : ""
              }`}
            >
              <label
                className="dh-form-label dh-title-lb"
                htmlFor={section.id + question.name}
              >
                {question.name === "address_1" ||
                question.name === "city" ||
                question.name === "zipcode"
                  ? `${transProps[question.label]} *`
                  : transProps[question.label]}
              </label>
            </div>
          )}
          {question.type === "select" &&
            question.name === "farmer_market_name" && (
              <SelectDropdown
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                placeholder={transProps[question.label]}
                readOnly={onEdit}
                value={
                  selectedFarmerMarket &&
                  selectedFarmerMarket[
                    `program[${id}][${index}].name[${question.name}]`
                  ]
                    ? selectedFarmerMarket[
                        `program[${id}][${index}].name[${question.name}]`
                      ]
                    : field_item && field_item?.name?.farmer_market_name
                    ? {
                        name: field_item?.name?.farmer_market_name,
                        values: field_item?.name?.farmer_market_name,
                      }
                    : { name: "", values: "Select Farmers' Market" }
                }
                onChange={(v) => {
                  if (v.name) {
                    setSelectedFarmerMarket((prevState) => ({
                      ...prevState,
                      [`program[${id}][${index}].name[${question.name}]`]: v,
                    }));

                    getLatLng(v.values, v.address, v.zip, v.city, id, index);
                    setSelectedCity((prevState) => ({
                      ...prevState,
                      [`program[${id}][${index}].name[city]`]: {
                        name: v.city,
                        values: v.city,
                      },
                    }));
                    selectedCityList({
                      ...selectedCity,
                      ...{
                        [`program[${id}][${index}].name[city]`]: {
                          name: v.city,
                          values: v.city,
                        },
                      },
                    });

                    setFarmerMarketList({
                      ...selectedFarmerMarket,
                      ...{
                        [`program[${id}][${index}].name[${question.name}]`]: v,
                      },
                    });

                    // if (!isNaN(v.zip)) {
                    setZipcode((prevState) => ({
                      ...prevState,
                      [`program[${id}][${index}].name[zipcode]`]: v.zip,
                    }));
                    setValue(`program[${id}][${index}].name[zipcode]`, v.zip);
                    setZipcodeList({
                      ...zipcode,
                      ...{
                        [`program[${id}][${index}].name[zipcode]`]: v.zip,
                      },
                    });
                    setAddress1((prevState) => ({
                      ...prevState,
                      [`program[${id}][${index}].name[address_1]`]: v.address,
                    }));
                    setValue(
                      `program[${id}][${index}].name[farmer_market_name]`,
                      v.values
                    );
                    setValue(
                      `program[${id}][${index}].name[address_1]`,
                      v.address
                    );
                    setValue(`program[${id}][${index}].name[city]`, {
                      name: v.city,
                      values: v.city,
                    });
                  }
                }}
                options={getFarmerMarkets()} //props.user.regCity
                isSearch={true}
                defaultValueProps={{
                  default: true,
                  defaultOption: { selectedFarmerMarket },
                }}
              />
            )}

          {question.type === "inputText" &&
            question.name === "farmer_store_name" && (
              <FormControl
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                type="text"
                readOnly={onEdit}
                id={`program[${id}][${index}].name[${question.name}]`}
                aria-describedby="farmerStoreName"
                onChange={(e) => {
                  setValue(
                    `program[${id}][${index}].name[${question.name}]`,
                    e.target.value
                  );
                }}
              />
            )}
          {question.type === "inputText" &&
            question.name === "location_name" && (
              <FormControl
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                type="text"
                readOnly={onEdit}
                id={`program[${id}][${index}].name[${question.name}]`}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => {
                  setValue(
                    `program[${id}][${index}].name[${question.name}]`,
                    e.target.value
                  );
                }}
              />
            )}

          {question.type === "inputText" &&
            question.name === "pickup_site_name" && (
              <FormControl
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                type="text"
                readOnly={onEdit}
                id={`program[${id}][${index}].name[${question.name}]`}
                aria-describedby="passwordHelpBlock"
                onChange={(e) => {
                  setValue(
                    `program[${id}][${index}].name[${question.name}]`,
                    e.target.value
                  );
                }}
              />
            )}

          {question.type === "date" && question.name === "startDate" && (
            <FormControl
              {...register(`program[${id}][${index}].name[${question.name}]`)}
              type="date"
              readOnly={onEdit}
              id={`program[${id}][${index}].name[${question.name}]`}
              aria-describedby="startDate"
              onChange={(e) => {
                setValue(
                  `program[${id}][${index}].name[${question.name}]`,
                  e.target.value
                );
              }}
            />
          )}
          {question.type === "date" && question.name === "endDate" && (
            <FormControl
              {...register(`program[${id}][${index}].name[${question.name}]`)}
              type="date"
              readOnly={onEdit}
              id={`program[${id}][${index}].name[${question.name}]`}
              aria-describedby="endDate"
              onChange={(e) => {
                setValue(
                  `program[${id}][${index}].name[${question.name}]`,
                  e.target.value
                );
              }}
            />
          )}

          {question &&
            question.type === "inputText" &&
            question.name === "address_1" && (
              <FormControl
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                type="text"
                autoComplete="off"
                readOnly={
                  onEdit ||
                  section.title === "HIP Farmers Market Booth" ||
                  section.id === "farmersMarket"
                }
                id={`program[${id}][${index}].name[${question.name}]`}
                aria-describedby="passwordHelpBlock"
                defaultValue={
                  field_item?.name && field_item.name[question.name]
                }
                onChange={(e) => {
                  if (question.name === "address_1") {
                    handleInputChange(e, question.name, id, index);
                  }

                  setValue(
                    `program[${id}][${index}].name[${question.name}]`,
                    e.target.value
                  );
                }}
              />
            )}

          {question &&
            question.type === "inputText" &&
            question.name === "address_2" && (
              <FormControl
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                type="text"
                autoComplete="off"
                readOnly={
                  onEdit ||
                  section.title === "HIP Farmers Market Booth" ||
                  section.id === "farmersMarket"
                }
                id={`program[${id}][${index}].name[${question.name}]`}
                aria-describedby="passwordHelpBlock"
                defaultValue={
                  field_item?.name && field_item.name[question.name]
                }
                onChange={(e) => {
                  if (question.name === "address_2") {
                    handleInputChange(e, question.name, id, index);
                  }

                  setValue(
                    `program[${id}][${index}].name[${question.name}]`,
                    e.target.value
                  );
                }}
              />
            )}

          {question.type === "select" && question.name === "city" && (
            <SelectDropdown
              {...register(`program[${id}][${index}].name[${question.name}]`)}
              placeholder={transProps[question.label]}
              // readOnly={onEdit}
              readOnly={
                onEdit ||
                section.title == "HIP Farmers Market Booth" ||
                section.id === "farmersMarket"
              }
              value={
                selectedCity &&
                selectedCity[`program[${id}][${index}].name[${question.name}]`]
                  ? selectedCity[
                      `program[${id}][${index}].name[${question.name}]`
                    ]
                  : field_item && field_item?.name[question.name]
                  ? field_item?.name[question.name]
                  : { values: "Select City", name: "" }
              }
              onChange={(v) => {
                setSelectedCity((prevState) => ({
                  ...prevState,
                  [`program[${id}][${index}].name[${question.name}]`]: v,
                }));
                setValue(`program[${id}][${index}].name[${question.name}]`, v);
                getLatLng(address1, address2, zipcode, v, id, index);
                selectedCityList({
                  ...selectedCity,
                  ...{ [`program[${id}][${index}].name[${question.name}]`]: v },
                });
              }}
              options={city}
              isSearch={true}
              defaultValueProps={{
                default: true,
                defaultOption:
                  selectedCity &&
                  selectedCity[
                    `program[${id}][${index}].name[${question.name}]`
                  ]
                    ? selectedCity[
                        `program[${id}][${index}].name[${question.name}]`
                      ]
                    : { values: "Select City", name: "" },
              }}
            />
          )}

          {question.type === "select" && question.name === "state" && (
            <SelectDropdown
              {...register(`program[${id}][${index}].name[${question.name}]`)}
              placeholder={question.label}
              value={selectedState || ""}
              onChange={(v) => {
                setValue(`program[${id}][${index}].name[${question.name}]`, v);
              }}
              readOnly={
                onEdit ||
                section?.title === "HIP Farmers Market Booth" ||
                section.id === "farmersMarket"
              }
              options={selectedState ? [selectedState] : []}
              defaultValueProps={{
                default: true,
                defaultOption: selectedState || "",
              }}
            />
          )}

          {question.type === "toggle" && question.name === "mapShow" && (
            <a
              style={{ cursor: "pointer" }}
              className="dh-link-line"
              onClick={() => setMapShow((prevMapShow) => !prevMapShow)}
            >
              <i className={`fa ${mapShow ? "fa-times" : "fa-map-marker"}`}></i>
              &nbsp;{mapShow ? transProps.closeMap : transProps.showMap}
            </a>
          )}

          {question.type === "map" && mapShow && (
            <Map
              state={selectedState}
              city={
                selectedCity &&
                selectedCity[`program[${id}][${index}].name[city]`]
                  ? selectedCity[`program[${id}][${index}].name[city]`]
                  : { values: "Select City", name: "" }
              }
              latlng={
                latLng && latLng[`program[${id}][${index}].name[latlng]`]
                  ? latLng[`program[${id}][${index}].name[latlng]`]
                  : {
                      lat:
                        field_item?.name && field_item.name["latitude"]
                          ? field_item.name["latitude"]
                          : "",
                      lng:
                        field_item?.name && field_item.name["longitude"]
                          ? field_item.name["longitude"]
                          : "",
                    }
              }
            />
          )}

          {question.type === "numbers" && (
            <>
              <FormControl
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                defaultValue={
                  section?.title?.toLowerCase() ===
                    (field_item?.name &&
                      field_item.name["storeType"]?.toLowerCase()) &&
                  field_item &&
                  field_item.name[question.name]
                    ? field_item.name[question.name]
                    : zipcode &&
                      zipcode[`program[${id}][${index}].name[${question.name}]`]
                    ? zipcode[`program[${id}][${index}].name[${question.name}]`]
                    : ""
                }
                autoComplete="off"
                readOnly={
                  onEdit ||
                  section?.title === "HIP Farmers Market Booth" ||
                  section.id === "farmersMarket"
                }
                id={`program[${id}][${index}].name[${question.name}]`}
                maxLength={5}
                onChange={(e) => {
                  handleInputChange(e, question.name, id, index);
                  setZipcode((prevState) => ({
                    ...prevState,
                    [`program[${id}][${index}].name[${question.name}]`]:
                      e.target.value,
                  }));
                  setValue(
                    `program[${id}][${index}].name[${question.name}]`,
                    e.target.value.trim()
                  );
                  setZipcodeList({
                    ...zipcode,
                    ...{
                      [`program[${id}][${index}].name[${question.name}]`]:
                        e.target.value,
                    },
                  });
                }}
                className={`form-control form-control-lg ${
                  errors?.program?.[index]?.name?.[question.name]
                    ? "dh-error-box"
                    : ""
                }`}
              />
              {errors?.program?.[index]?.name?.[question.name] && (
                <span className="dh-txt-danger">
                  {transProps.invalidZipcode}
                </span>
              )}
            </>
          )}

          {question.type === "textarea" && (
            <>
              <FormControl
                {...register(`program[${id}][${index}].name[${question.name}]`)}
                as="textarea"
                rows={3}
                readOnly={onEdit}
                defaultValue={
                  field_item &&
                  field_item.name &&
                  field_item.name[question.name]
                    ? field_item.name[question.name]
                    : ""
                }
                placeholder={transProps[question.placeholder]}
                onChange={(e) => {
                  setSpecialInst((prevState) => ({
                    ...prevState,
                    [`program[${id}][${index}].name[${question.name}]`]:
                      e.target.value,
                  }));
                  setValue(
                    `program[${id}][${index}].name[${question.name}]`,
                    e.target.value
                  );
                }}
              />
              <Form.Text className="helper-text" muted>
                <i className="fa fa-info-circle ml-2" aria-hidden="true"></i>{" "}
                {transProps.maxCharacter}
              </Form.Text>
            </>
          )}

          {/* {question.type === "multiSelect" && question.name === "products" && (
            <div className="row">
              <div className="col-md-12">
                <MUIFormControl
                  className="w-100"
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      background: "#fff",
                      borderRadius: "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3", // Hover
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3", // Focus
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3", // Default and other states
                      },
                    },
                  }}
                >
                  <Select
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "12px 14px !important",
                      },
                    }}
                    multiple
                    value={selectedNames}
                    onChange={(e) => setSelectedNames(e.target.value)}
                    InputLabelProps={{ shrink: false }}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap" mb={1}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() =>
                              setSelectedNames(
                                selectedNames.filter((item) => item !== value)
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                  >
                    {products?.map((item) => (
                      <MenuItem
                        key={item.productListId}
                        value={item.name}
                        sx={{
                          justifyContent: "space-between",
                        }}
                      >
                        {item.name}
                        {selectedNames.includes(item.name) ? (
                          <CheckIcon color="info" />
                        ) : null}
                      </MenuItem>
                    ))}
                  </Select>
                </MUIFormControl>
              </div>
            </div>
          )} */}

          {/* {question.type === "multiSelect" && question.name === "products" && (
            <div className="row">
              <div className="col-md-12">
                <MUIFormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      background: "#fff",
                      borderRadius: "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3",
                      },
                    },
                  }}
                >
                  <Select
                    {...register("selectedNames")}
                    multiple
                    value={selectedNames || []}
                    onChange={handleChange}
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap" mb={1}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() => handleDelete(value)}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "12px 14px !important",
                      },
                    }}
                  >
                    {products?.map((item) => (
                      <MenuItem
                        key={item.productListId}
                        value={item.name}
                        sx={{ justifyContent: "space-between" }}
                      >
                        {item.name}
                        {selectedNames.includes(item.name) ? (
                          <CheckIcon color="info" />
                        ) : null}
                      </MenuItem>
                    ))}
                  </Select>
                </MUIFormControl>
              </div>
            </div>
          )} */}

          {question.type === "multiSelect" && question.name === "products" && (
            <div className="row">
              <div className="col-md-12">
                <MUIFormControl
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      background: "#fff",
                      borderRadius: "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3",
                      },
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#44a0e3",
                      },
                    },
                  }}
                >
                  <Select
                    {...register(
                      `program[${id}][${index}].name[${question.name}]`
                    )}
                    multiple
                    onChange={(e) => {
                      setValue(
                        `program[${id}][${index}].name[${question.name}]`,
                        e.target.value
                      );
                    }}
                    value={
                      getValues(
                        `program[${id}][${index}].name[${question.name}]`
                      ) || []
                    }
                    renderValue={(selected) => (
                      <Stack gap={1} direction="row" flexWrap="wrap" mb={1}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            onDelete={() => {
                              const currentSelected = getValues(
                                `program[${id}][${index}].name[${question.name}]`
                              );
                              const newValue = currentSelected.filter(
                                (v) => v !== value
                              );
                              setValue(
                                `program[${id}][${index}].name[${question.name}]`,
                                newValue
                              );
                            }}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Stack>
                    )}
                    sx={{
                      "& .MuiSelect-select": {
                        padding: "12px 14px !important",
                      },
                    }}
                  >
                    {products?.map((item) => (
                      <MenuItem
                        key={item.productListId}
                        value={item.name}
                        sx={{ justifyContent: "space-between" }}
                      >
                        {item.name}
                        {field_item?.name?.products?.includes(item.name) &&
                        (
                          <CheckIcon color="info" />
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </MUIFormControl>
              </div>
            </div>
          )}

          {question.type === "checkbox" &&
            question.name === "months_operations" &&
            months !== undefined && (
              <ul className="dh-calendar row">
                {months.map((option, j) => (
                  <li className="col-lg-4 col-md-6 col-sm-12 col-12" key={j}>
                    <div className="form-group form-check">
                      <input
                        {...register(
                          `program[${id}][${index}].name[${question.name}][${j}]`
                        )}
                        readOnly={onEdit}
                        type="checkbox"
                        checked={getValues(
                          `program[${id}][${index}].name[${question.name}][${j}]`
                        )}
                        id={`program[${id}][${index}].name[${question.name}][${
                          option.values + j
                        }]`}
                        value={option.values}
                        className="form-check-input"
                        onChange={(e) => {
                          setValue(
                            `program[${id}][${index}].name[${question.name}][${j}]`,
                            e.target.checked
                          );
                        }}
                      />
                      <label
                        htmlFor={`program[${id}][${index}].name[${
                          question.name
                        }][${option.values + j}]`}
                        className="form-check-label"
                      >
                        {transProps[option.values]}
                      </label>
                    </div>
                  </li>
                ))}
              </ul>
            )}

          {question.type === "checkbox" &&
            question.name === "distribution_option" &&
            order_option !== undefined && (
              <>
                {order_option.map((option, j) => (
                  <div className="form-group form-check" key={j}>
                    <input
                      {...register(
                        `program[${id}][${index}].name[${question.name}][${j}]`
                      )}
                      readOnly={onEdit}
                      type="checkbox"
                      id={`program[${id}][${index}].name[${question.name}][${
                        option.values + j
                      }]`}
                      value={option.values}
                      checked={getValues(
                        `program[${id}][${index}].name[${question.name}][${j}]`
                      )}
                      className="form-check-input mt-2"
                      onChange={(e) => {
                        setIsOtherTxt((prevState) => ({
                          ...prevState,
                          [`program[${id}][${index}].name[${question.name}][${j}]`]:
                            e.target.checked && e.target.value === "Other",
                        }));
                        setValue(
                          `program[${id}][${index}].name[${question.name}][${j}]`,
                          e.target.checked
                        );
                      }}
                    />
                    <label
                      htmlFor={`program[${id}][${index}].name[${
                        question.name
                      }][${option.values + j}]`}
                      className="form-check-label"
                    >
                      {console.log(option.values, "values")}
                      {option.values}
                    </label>
                    {option.values === "Other" && (
                      <div
                        className={`mt-2 ${
                          isOtherTxt &&
                          isOtherTxt[
                            `program[${id}][${index}].name[${question.name}][${j}]`
                          ]
                            ? ""
                            : "d-none"
                        }`}
                      >
                        <input
                          {...register(
                            `program[${id}][${index}].name.othertext`
                          )}
                          defaultValue={
                            getValues() &&
                            getValues()[
                              `program[${id}][${index}].name.othertext`
                            ]
                              ? getValues()[
                                  `program[${id}][${index}].name.othertext`
                                ]
                              : ""
                          }
                          onChange={(e) => {
                            setOtherTxt((prevState) => ({
                              ...prevState,
                              [`program[${id}][${index}].name.othertext`]:
                                e.target.value,
                            }));
                          }}
                        />
                      </div>
                    )}
                  </div>
                ))}
              </>
            )}

          {question.type === "workhour_select_radio" && (
            <Table responsive="sm" className="hours-of-operations">
              <thead>
                <tr>
                  <th>{transProps.day}</th>
                  <th className="drop-down-menu">{transProps.startTime}</th>
                  <th className="drop-down-menu">{transProps.endTime}</th>
                  <th>{transProps.storeStatus}</th>
                </tr>
              </thead>
              <tbody>
                {field_item.name?.working_hours?.map((item, m) => (
                  <tr key={m}>
                    <td>
                      <div className="form-group form-check">
                        <label
                          className="form-check-label"
                          htmlFor={`day_1_${index}`}
                        >
                          {transProps[item?.day?.toLowerCase()]}
                        </label>
                        <input
                          type="hidden"
                          value={item?.day}
                          {...register(
                            `program[${id}][${index}].name[${question.name}][${m}].day`
                          )}
                          readOnly={onEdit}
                          onChange={(e) => {
                            setValue(
                              `program[${id}][${index}].name[${question.name}][${m}].day`,
                              e.target.value
                            );
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <Select
                        {...register(
                          `program[${id}][${index}].name[${question.name}][${m}].open_time`
                        )}
                        readOnly={onEdit}
                        defaultValue={item.open_time ? item.open_time : "12:00"}
                        className={`${
                          item.holiday && JSON.parse(item.holiday)
                            ? "pe-none "
                            : ""
                        } time-hours`}
                        onChange={(e) => {
                          setValue(
                            `program[${id}][${index}].name[${question.name}][${m}].open_time`,
                            e.target.value
                          );
                        }}
                      >
                        {question.workHourFields[0].open_time.map((data, l) => (
                          <MenuItem
                            key={`program${index}${l}`}
                            value={data.name}
                          >
                            {data.values}
                          </MenuItem>
                        ))}
                      </Select>
                      &nbsp;&nbsp;
                      <Select
                        {...register(
                          `program[${id}][${index}].name[${question.name}][${m}].open_time_meridian`
                        )}
                        readOnly={onEdit}
                        defaultValue={
                          item.open_time_meridian
                            ? item.open_time_meridian
                            : "am"
                        }
                        className={`${
                          item.holiday && JSON.parse(item.holiday)
                            ? "pe-none time-meridian-disabled "
                            : ""
                        }time-meridian`}
                        onChange={(e) => {
                          setValue(
                            `program[${id}][${index}].name[${question.name}][${m}].open_time_meridian`,
                            e.target.value
                          );
                        }}
                      >
                        {question.workHourFields[0].open_time_meridian.map(
                          (data, l) => (
                            <MenuItem
                              key={`open_time_meridian_${index}${l}`}
                              value={data.name}
                            >
                              {transProps[data.values]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </td>
                    <td>
                      <Select
                        {...register(
                          `program[${id}][${index}].name[${question.name}][${m}].close_time`
                        )}
                        readOnly={onEdit}
                        defaultValue={
                          item.close_time ? item.close_time : "12:00"
                        }
                        className={`${
                          item.holiday && JSON.parse(item.holiday)
                            ? "pe-none "
                            : ""
                        }time-hours`}
                        onChange={(e) => {
                          setValue(
                            `program[${id}][${index}].name[${question.name}][${m}].close_time`,
                            e.target.value
                          );
                        }}
                      >
                        {question.workHourFields[0].close_time.map(
                          (data, l) => (
                            <MenuItem
                              key={`close_time_${index}${l}`}
                              value={data.name}
                            >
                              {data.values}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      &nbsp;&nbsp;
                      <Select
                        {...register(
                          `program[${id}][${index}].name[${question.name}][${m}].close_time_meridian`
                        )}
                        readOnly={onEdit}
                        defaultValue={
                          item.close_time_meridian
                            ? item.close_time_meridian
                            : "pm"
                        }
                        className={`${
                          item.holiday && JSON.parse(item.holiday)
                            ? "pe-none time-meridian-disabled "
                            : ""
                        }time-meridian`}
                        onChange={(e) => {
                          setValue(
                            `program[${id}][${index}].name[${question.name}][${m}].close_time_meridian`,
                            e.target.value
                          );
                        }}
                      >
                        {question.workHourFields[0].close_time_meridian.map(
                          (data, l) => (
                            <MenuItem
                              key={`close_time_${item}${l}`}
                              value={data.name}
                            >
                              {transProps[data.values]}
                            </MenuItem>
                          )
                        )}
                      </Select>
                    </td>
                    <td>
                      <Form.Check
                        {...register(
                          `program[${id}][${index}].name[${question.name}][${m}].holiday`
                        )}
                        inline
                        label={transProps.open}
                        value={false}
                        readOnly={onEdit}
                        checked={
                          "holiday" in item && !JSON.parse(item?.holiday)
                        }
                        id={`program[${id}][${index}].name[${question.name}][${m}].holiday_false_${m}`}
                        type={"radio"}
                        onChange={(e) => {
                          item.holiday =
                            "holiday" in item && !JSON.parse(item?.holiday);
                          setValue(
                            `program[${id}][${index}].name[${question.name}][${m}].holiday`,
                            e.target.value
                          );
                        }}
                      />
                      <Form.Check
                        inline
                        {...register(
                          `program[${id}][${index}].name[${question.name}][${m}].holiday`
                        )}
                        label={transProps.close}
                        value={true}
                        readOnly={onEdit}
                        className="mt-input"
                        checked={"holiday" in item && JSON.parse(item?.holiday)}
                        name={`program[${id}][${index}].name[${question.name}][${m}].holiday`}
                        id={`program[${id}][${index}].name[${
                          question.name
                        }][${m}].holiday_true_${m + 1}`}
                        type={"radio"}
                        onChange={(e) => {
                          item.holiday =
                            "holiday" in item && !JSON.parse(item?.holiday);
                          setValue(
                            `program[${id}][${index}].name[${question.name}][${m}].holiday`,
                            e.target.value
                          );
                        }}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}

          {question.type === "single_checkbox" && (
            <div className="col-md-4 col-sm-12 col-lg-8">
              <div className="form-group form-check p-0 pl-1 mt-1">
                <input
                  {...register(
                    `program[${id}][${index}].name[${question.name}]`
                  )}
                  className="special-checkbox"
                  style={{
                    cursor: onEdit ? "not-allowed" : "pointer",
                    background: onEdit ? "#FAFAFA" : "inherit",
                    opacity: onEdit ? 0.5 : 1,
                  }}
                  type="checkbox"
                  readOnly={onEdit}
                  checked={
                    getValues()?.program?.[id]?.[index]?.name
                      ?.is_specialhours || false
                  }
                  onChange={(e) => {
                    if (!onEdit) {
                      setIsSpecialHours((prevState) => ({
                        ...prevState,
                        [`program[${id}][${index}].name[${question.name}]`]:
                          e.target.checked,
                      }));
                      setValue(
                        `program[${id}][${index}].name[${question.name}]`,
                        e.target.checked
                      );
                    }
                  }}
                />
              </div>
            </div>
          )}

          {question.type === "special_hoursOfOperation" &&
            getValues()?.program?.[id]?.[index]?.name?.is_specialhours && (
              <SignupNestedFieldArray
                nestIndex={index}
                id={id}
                days={days}
                months={months}
                field_item={field_item}
                clear={true}
                programData={programData}
                data={field_item}
                question={question}
                setSpecialHourData={setSpecialHourData}
                {...{ control, register, getValues, setValue }}
                // control={control}
                // register={register}
                // getValues={getValues}
                // setValue={setValue}
                onEdit={onEdit}
              />
            )}
        </div>
      )})}
    </div>
  );
}

SignupFieldArray.propTypes = {
  type: PropTypes.string,
  control: PropTypes.object,
  register: PropTypes.func,
  watch: PropTypes.any,
  setValue: PropTypes.func,
  getValues: PropTypes.func,
  errors: PropTypes.func,
  section: PropTypes.any,
  city: PropTypes.array,
  days: PropTypes.array,
  months: PropTypes.array,
  order_option: PropTypes.array,
  programData: PropTypes.any,
  farmermarkets: PropTypes.any,
  selectedCityList: PropTypes.func,
  setZipcodeList: PropTypes.func,
  setLatLngList: PropTypes.func,
  setFarmerMarketList: PropTypes.func,
  getVendorStoreId: PropTypes.func,
  refreshKey: PropTypes.number,
  deleteStore: PropTypes.func,
  programArray: PropTypes.object,
  user: PropTypes.any,
};
export default SignupFieldArray;
