import * as React from 'react';
import PropTypes from "prop-types";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {CustomButton} from "./customButton";

const CustomButtonDropDown = (props) => {
  
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  return (
    <React.Fragment>
      <CustomButton variant={props.variant}  onClick={handleToggle} ref={anchorRef}  endIcon={<ArrowDropDownIcon />}>{props.title}</CustomButton>
      <Popper
        sx={{
          zIndex: 1,
        }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {props.options.map((option) => (
                    <MenuItem
                      key={option}
                      onClick={() => { 
                        setOpen(false);
                        props.handleMenuItemClick(option)
                      }}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </React.Fragment>
  );
}

CustomButtonDropDown.propTypes = {
  variant:PropTypes.string,
  title:PropTypes.string,
  options:PropTypes.array,
  handleMenuItemClick: PropTypes.func,
}

export default CustomButtonDropDown;