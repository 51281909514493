import React, { useEffect, useState, useMemo } from "react";
import { Col, Row, Tab, Tabs, Button, Badge } from "react-bootstrap";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box, Select, MenuItem, FormHelperText } from "@mui/material";
import { map } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { AUTH_API, headers } from "../../config";
import axiosInstance from "../../redux/interceptor";
import { useFormik } from "formik";
import CommentBadge from "../mystore/tabs/commentBadge";
import useLoader from "../../hooks/useLoader";
import toast from "react-hot-toast";
import { pageRefresh } from "../../utils/globalMethods";
import ModalComponent from "../common/components/modal";
import CommentModal from "../mystore/commentModal";
import VerifyVendorComponent from "../mystore/verifyVendor";
import useLookup from "../../hooks/useLookup";
import * as Yup from "yup";
import AlertMarketCord from "../market/alertOverviewMarket/alertMarketCord";
import AlertMarketCordStaff from "./AlertMarketCordStaff";

function MarketDetails() {
  const [activeTab, setActiveTab] = useState("details");
  const { openLoader, closeLoader } = useLoader();
  const [showModal, setShowModal] = useState(false);
  const [showVerifyModal, setShowVerifyModal] = useState(false);
  const [showDenyModal, setShowDenyModal] = useState(false);
  const [vendorType, setVendorType] = useState("MRKCRD");
  const [isEdit, setIsEdit] = useState(false);
  const [isEditPublic, setIsEditPublic] = useState(false);
  const { id } = useParams();
  const [data, setData] = useState("");
  const role = "MRKCRD";

  const { getOptions } = useLookup("marketRegistration");
  const userData = JSON.parse(sessionStorage.getItem('userData'));

  const { stateOpts, bankingOpts } = useMemo(() => {
    return {
      stateOpts: getOptions("state"),
      bankingOpts: getOptions("bankingtype"),
    };
 }, [getOptions]);
 

  const getYourInfo = async () => {
    try {
      openLoader("get_details");
      const payLoad = {
        vendorType: "MRKCRD",
        lastUpdtUidNam: role + "_" + id,
        vendorId: id,
      };
      const res = await axiosInstance.post(
        AUTH_API + "/csa/marketCoordinator/info",
        payLoad
      );
      setData(res.data.data[0].hipInfo);
    } catch (error) {
      console.log(error);
    } finally {
      console.log("finally");
      closeLoader("get_details");
    }
  };

  useEffect(() => {
    getYourInfo();
  }, []);

  const validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    primaryEmail: Yup.string().required("Email is required"),
    marketName: Yup.string().required("Market Name is required"),
    marketAddress: Yup.string().required("Address is required"),
    city: Yup.string().required("City is required"),
    state: Yup.string().required("State is required"),
    county: Yup.string().required("County is required"),
    zipcode: Yup.string()
      .required("ZipCode is required")
      .matches(/^[0-9]{5}$/, "Must be exactly 5 digits"),
    sigma: Yup.string()
      .required("Sigma ID is required")
      .matches(/^[0-9]{9}$/, "Must be exactly 9 digits"),
    fed: Yup.string()
      .required("FED ID is required")
      .matches(/^[0-9]{9}$/, "Must be exactly 9 digits"),
    routingNo: Yup.string()
      .required("Routing Number is required")
      .matches(/^[0-9]{9}$/, "Must be exactly 9 digits"),
    bankName: Yup.string().required("Bank is required"),
    accountNo: Yup.string()
      .required("Account Number is required")
      .matches(/^[0-9]{12}$/, "Must be exactly 12 digits"),
    bankType: Yup.string().required("Bank Type is required"),
    farmers: Yup.string().required("Number of Farmers is required"),
    primaryMobile: Yup.string()
      .required("Phone is required")
      .matches(/^[0-9]{10}$/, "Must be exactly 10 digits"),
    marketPhone: Yup.string().min(10, "Must be exactly 10 digits"),
    eid: Yup.string()
      .required("EID number is required")
      .matches(/^[0-9]{9}$/, "Must be exactly 9 digits"),
    apptoApply: Yup.array()
      .min(1, "Please select at least one")
      .required("Application type is required"),
  });

   const formik = useFormik({
     initialValues: {
       firstName: data.firstName ?? "",
       lastName: data.lastName ?? "",
       primaryEmail: data.primaryEmail ?? "",
       primaryMobile: data.primaryMobile ?? "",
       //    password: data.password ?? "",
       //    confirm_password: data.confirm_password ?? "",
       businessDesc: "",
       products: "",
       publicEmail: "",
       publicMobile: "",
       publicWebsite: "",
       additionalContactInfo: "",
       //    publicPhoto: "",
       bankName: data.bankName ?? "",
       bankType: data.bankType ?? "",
       accountNo: data.accountNo ?? "",
       routingNo: data.routingNo ?? "",
       //    csaFnsNum: "",
       csaCommunicationEmail: "",
       vendorType: "MRKCRD",
       isHipAndCsaVdr: false,
       role: "MRKCRD",
       isVerified: "N",
       isActive: "N",
       forceLogin: "N",
       submitForm: "MRKCRD",
       middleName: data.middleName ?? "",
       marketName: data.marketName ?? "",
       marketAddress: data.marketAddress ?? "",
       marketphone: data.marketPhone ?? "",
       city: data.city ?? "",
       state: data.state ?? "",
       county: data.county ?? "",
       zipcode: data.zipcode ?? "",
       sigma: data.sigma ?? "",
       fed: data.fed ?? "",
       eid: data.eid ?? "",
       farmers: data.farmers ?? "",
       communicationEmail: data.communicationEmail ??  "",
       facebookPage: data.facebookPage ?? "",
       twitterPage: data.twitterPage ?? "",
       websiteAddr: data.websiteAddr ?? "",
       apptoApply: data.apptoApply ? data.apptoApply.split(", ") : [],
       notificationVia: data.notificationVia
         ? data.notificationVia.split(", ")
         : [],
       //    status: "PE",
     },
     validationSchema: activeTab === "details" ? validationSchema : null,
     onSubmit: async (values) => {
       await updateMarketCoordinator(values);
     },
     enableReinitialize: true,
   });


     const updateMarketCoordinator = async (values) => {
        let payload = {
          ...values,
          id: id,
          vendorId: id,
          userId: userData.id,
          loginId: userData.id,
          apptoApply: values.apptoApply ? values.apptoApply.join(", ") : "",
          notificationVia:
            values.notificationVia ? values.notificationVia.join(", ") : "",
          lastUpdtUidNam: `ADM_${userData.id}`,
        };
       try {
         openLoader("update");
          const url = `${AUTH_API}/public/update/marketInfo`;
           const res = await axiosInstance.post(
            url,
            payload
           );
           
         if (res.status === 200) {
          toast.success("Updated successfully");
          getYourInfo()
         }
       } catch (error) {
         console.log(error);
         toast.error("Update Failed");
       } finally {
         closeLoader("update");  
         setIsEdit(false);
         setIsEditPublic(false);
       }
     };

    const handleApprove = async (cbValue) => {
        let payload = {
          vendorId: id,
          vendorType: "MRKCRD",
          staffName: cbValue.staffName,
          vendorVerfNotes: cbValue.vendorVerfNotes,
          isVerified: "Y",
          isActive: "Y",
          status: "AP",
          mail_obj: {
            primaryEmail: data.primaryEmail ?? "",
            firstName: data.firstName ?? "",
            lastName: data.lastName ?? "",
          },
        };
    try {
      openLoader("approved");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/csa/verify/marketCoordinator`,
        payload
      );
      if (res.status === 200) {
        toast.success("Application Approved");
        getYourInfo();
        setShowVerifyModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed");
    } finally {
      closeLoader("approved");
    }
  };

  const handleDeny = async (cbValue) => {
    let payload = {
      vendorId: id,
      vendorType: "MRKCRD",
      staffName: cbValue.staffName,
      vendorVerfNotes: cbValue.vendorVerfNotes,
      isVerified: "Y",
      isActive: "Y",
      status: "DE",
      mail_obj: {
        primaryEmail: data.primaryEmail ?? "",
        firstName: data.firstName ?? "",
        lastName: data.lastName ?? "",
      },
    };
    try {
      openLoader("denied");
      const res = await axiosInstance.post(
        `${process.env.REACT_APP_BASE_URL}/csa/verify/marketCoordinator`,
        payload
      );
      if (res.status === 200) {
        toast.success("Application Denied");
        getYourInfo();
        setShowDenyModal(false);
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed");
    } finally {
      closeLoader("denied");
    }
  };

  const renderModal = () => {
    return (
      <CommentModal
        vendorId={id}
        vendorType={vendorType}
        setVendorType={setVendorType}
      />
    );
  };

  const renderVerifyModal = () => {
    return <VerifyVendorComponent verify verifyVendorSubmit={handleApprove} />;
  };

  const renderDenyModal = () => {
    return (
      <VerifyVendorComponent verifyVendorSubmit={handleDeny} btnName={true} />
    );
  };
  return (
    <>
      <Stack>
        <Row className="mx-0">
          <Col
            lg={6}
            md={12}
            sm={12}
            className="my-3 d-flex align-items-center"
          >
            <h2 className="resText vendor-details mr-3">
              Market Coordinator details
            </h2>
            <div>
              <Badge
                className={data.status === "AP" ? "bg-success" : "bg-danger"}
              >
                {data.status === "AP"
                  ? "Approved"
                  : data.status === "DE"
                  ? "Denied"
                  : "Pending"}
              </Badge>
              <div>Enrollment ID: {data.orgId}</div>
            </div>
          </Col>
          <Col
            lg={6}
            md={12}
            sm={12}
            className="d-flex align-items-center justify-content-md-end justify-content-sm-start gap-3"
          >
            <CommentBadge
              title={"Add Note"}
              onClick={() => setShowModal(true)}
            />
            {data.status !== "AP" && (
              <>
                <Button
                  // onClick={handleApprove}
                  onClick={() => setShowVerifyModal(true)}
                >
                  <i className="fa fa-check"></i>
                  Approve
                </Button>
                <Button
                  variant="danger"
                  //   onClick={handleDeny}
                  onClick={() => setShowDenyModal(true)}
                >
                  <i className="fa fa-close"></i>
                  Deny
                </Button>
              </>
            )}
            <Button variant="primary" onClick={pageRefresh}>
              <i className="fa fa-refresh"></i> Refresh
            </Button>
          </Col>
        </Row>
        <Tabs
          className="mx-2"
          id="controlled-tab"
          activeKey={activeTab}
          onSelect={setActiveTab}
          style={{ background: "#fff", paddingTop: "2rem" }}
        >
          <Tab
            eventKey="details"
            title={
              <div className="tab-header">
                <i className="fa fa-user"></i>
                <span className="m-l-5">Market Coordinator Details</span>
              </div>
            }
          >
            <div className="tab-content-wrapper mx-2">
              <Row>
                <Col>
                  <h3 className="resText"> Profile Details</h3>
                </Col>

                <Col className="text-right">
                  {isEdit ? (
                    <div>
                      <Button className="mr-1" onClick={formik.handleSubmit}>
                        <i className="fa fa-check"></i>
                        Update
                      </Button>
                      <Button onClick={() => setIsEdit(false)}>
                        <i className="fa fa-close"></i>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button onClick={() => setIsEdit(true)}>
                      <i className="fa fa-pencil"></i>
                      Edit
                    </Button>
                  )}
                </Col>
              </Row>

              <Grid container spacing={2} mt={4}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    First Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="firstName"
                    size="small"
                    value={formik.values.firstName}
                    disabled={!isEdit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.firstName && formik.errors.firstName}
                    helperText={
                      formik.touched.firstName && formik.errors.firstName
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Middle Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="middleName"
                    size="small"
                    value={formik.values.middleName}
                    disabled={!isEdit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.middleName && formik.errors.middleName
                    }
                    helperText={
                      formik.touched.middleName && formik.errors.middleName
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Last Name
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="lastName"
                    size="small"
                    value={formik.values.lastName}
                    disabled={!isEdit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.lastName && formik.errors.lastName}
                    helperText={
                      formik.touched.lastName && formik.errors.lastName
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Email Address
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="primaryEmail"
                    size="small"
                    value={formik.values.primaryEmail}
                    disabled
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.primaryEmail && formik.errors.primaryEmail
                    }
                    helperText={
                      formik.touched.primaryEmail && formik.errors.primaryEmail
                    }
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Phone Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="primaryMobile"
                    size="small"
                    value={formik.values.primaryMobile}
                    disabled={!isEdit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.primaryMobile &&
                      formik.errors.primaryMobile
                    }
                    helperText={
                      formik.touched.primaryMobile &&
                      formik.errors.primaryMobile
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                    disabled
                  >
                    Market Name
                  </Typography>
                  <TextField
                    fullWidth
                    name="marketName"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.marketName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.marketName && formik.errors.marketName
                    }
                    helperText={
                      formik.touched.marketName && formik.errors.marketName
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Market Address
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="marketAddress"
                    value={formik.values.marketAddress}
                    size="small"
                    disabled={!isEdit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.marketAddress &&
                      formik.errors.marketAddress
                    }
                    helperText={
                      formik.touched.marketAddress &&
                      formik.errors.marketAddress
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    City
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="city"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.city}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.city && formik.errors.city}
                    helperText={formik.touched.city && formik.errors.city}
                  ></TextField>
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    State
                  </Typography>
                  <Box width="100%" overflow="hidden">
                    <Select
                      fullWidth
                      size="small"
                      name="state"
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!isEdit}
                      helperText={formik.touched.state && formik.errors.state}
                    >
                      {map(stateOpts, (item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>{formik.errors.state}</FormHelperText>
                  </Box>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    County
                  </Typography>
                  <TextField
                    fullWidth
                    name="county"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.county}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.county && formik.errors.county}
                    helperText={formik.touched.county && formik.errors.county}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Zip Code
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="zipcode"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.zipcode}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.zipcode && formik.errors.zipcode}
                    helperText={formik.touched.zipcode && formik.errors.zipcode}
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    FED ID
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="fed"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.fed}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.fed && formik.errors.fed}
                    helperText={formik.touched.fed && formik.errors.fed}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    EIN Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="eid"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.eid}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.eid && formik.errors.eid}
                    helperText={formik.touched.eid && formik.errors.eid}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    SIGMA Account Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="sigma"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.sigma}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.sigma && formik.errors.sigma}
                    helperText={formik.touched.sigma && formik.errors.sigma}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    How many farmers do you represent
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="farmers"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.farmers}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.farmers && formik.errors.farmers}
                    helperText={formik.touched.farmers && formik.errors.farmers}
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Check all that apply
                  </Typography>
                  <FormControlLabel
                    // disabled
                    control={
                      <Checkbox
                        disabled={!isEdit}
                        value="market"
                        checked={formik.values.apptoApply.includes("market")}
                        name="apptoApply"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Market"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        value="road"
                        disabled={!isEdit}
                        checked={formik.values.apptoApply.includes("road")}
                        name="apptoApply"
                        onChange={formik.handleChange}
                      />
                    }
                    label="Roadside Stand"
                    labelPlacement="end"
                  />
                  {formik.touched.apptoApply && formik.errors.apptoApply && (
                    <div style={{ color: "red", fontSize: "1rem" }}>
                      {formik.errors.apptoApply}
                    </div>
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Routing Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="routingNo"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.routingNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.routingNo && formik.errors.routingNo}
                    helperText={
                      formik.touched.routingNo && formik.errors.bankNo
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    fontWeight="fontWeightBold"
                    className="typography"
                    variant="h6"
                  >
                    Banking Account Number
                  </Typography>
                  <TextField
                    fullWidth
                    name="accountNo"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.accountNo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.accountNo && formik.errors.accountNo}
                    helperText={
                      formik.touched.accountNo && formik.errors.accountNo
                    }
                  />
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Bank Name
                  </Typography>
                  <TextField
                    fullWidth
                    className="formControl"
                    name="bankName"
                    size="small"
                    disabled={!isEdit}
                    value={formik.values.bankName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.bankName && formik.errors.bankName}
                    helperText={
                      formik.touched.bankName && formik.errors.bankName
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={4} md={4} lg={3}>
                  <Typography
                    variant="h6"
                    fontWeight="fontWeightBold"
                    className="typography"
                  >
                    Bank Type
                  </Typography>
                  <Box width="100%" overflow="hidden">
                    <Select
                      fullWidth
                      size="small"
                      name="bankType"
                      value={formik.values.bankType}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      disabled={!isEdit}
                      helperText={
                        formik.touched.bankType && formik.errors.bankType
                      }
                    >
                      {map(bankingOpts, (item) => (
                        <MenuItem value={item.value}>{item.label}</MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error>
                      {formik.errors.bankType}
                    </FormHelperText>
                  </Box>
                </Grid>
              </Grid>
            </div>
          </Tab>
          <Tab
            eventKey="Public"
            title={
              <div className="tab-header">
                <i className="fa fa-eye"></i>
                <span className="m-l-5">Market Coordinator Public Info</span>
              </div>
            }
          >
            <div className="tab-content-wrapper mx-2">
              <Row style={{ marginBottom: "25px" }}>
                <Col>
                  <h3 className="resText"> Public Info</h3>
                </Col>

                <Col className="text-right">
                  {isEditPublic ? (
                    <div>
                      <Button className="mr-1" onClick={formik.handleSubmit}>
                        <i className="fa fa-check"></i>
                        Update
                      </Button>
                      <Button onClick={() => setIsEditPublic(false)}>
                        <i className="fa fa-close"></i>
                        Cancel
                      </Button>
                    </div>
                  ) : (
                    <Button onClick={() => setIsEditPublic(true)}>
                      <i className="fa fa-pencil"></i>
                      Edit
                    </Button>
                  )}
                </Col>
              </Row>

              <Grid container spacing={2}>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    variant="h6"
                    className="typography"
                    fontWeight="fontWeightBold"
                  >
                    Communication Email Address
                  </Typography>
                  <TextField
                    fullWidth
                    name="communicationEmail"
                    size="small"
                    placeholder={"Enter Email Address"}
                    value={formik.values.communicationEmail}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!isEditPublic}
                    error={
                      formik.touched.communicationEmail &&
                      formik.errors.communicationEmail
                    }
                    helperText={
                      formik.touched.communicationEmail &&
                      formik.errors.communicationEmail
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    className="typography"
                    variant="h6"
                    fontWeight="fontWeightBold"
                  >
                    Website Address
                  </Typography>
                  <TextField
                    fullWidth
                    name="websiteAddr"
                    value={formik.values.websiteAddr}
                    size="small"
                    placeholder={"Enter Website Address"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled={!isEditPublic}
                    error={
                      formik.touched.websiteAddr && formik.errors.websiteAddr
                    }
                    helperText={
                      formik.touched.websiteAddr && formik.errors.websiteAddr
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    variant="h6"
                    className="typography"
                    fontWeight="fontWeightBold"
                  >
                    Facebook Page
                  </Typography>
                  <TextField
                    fullWidth
                    name="facebookPage"
                    value={formik.values.facebookPage}
                    size="small"
                    disabled={!isEditPublic}
                    placeholder={"Enter Facebook Page"}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.facebookPage && formik.errors.facebookPage
                    }
                    helperText={
                      formik.touched.facebookPage && formik.errors.facebookPage
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography
                    className="typography"
                    variant="h6"
                    fontWeight="fontWeightBold"
                  >
                    Twitter
                  </Typography>
                  <TextField
                    fullWidth
                    name="twitterPage"
                    size="small"
                    disabled={!isEditPublic}
                    placeholder={"Enter Twitter Page"}
                    value={formik.values.twitterPage}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.twitterPage && formik.errors.twitterPage
                    }
                    helperText={
                      formik.touched.twitterPage && formik.errors.twitterPage
                    }
                  />
                </Grid>
                <Grid item xs={6} md={6} lg={6}>
                  <Typography variant="h6" fontWeight="fontWeightBold">
                    Notification VIA
                  </Typography>
                  <FormControlLabel
                    disabled={!isEditPublic}
                    label="Email"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        value="mail"
                        checked={formik.values.notificationVia.includes("mail")}
                        name="notificationVia"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                  <FormControlLabel
                    disabled={!isEditPublic}
                    label="USPS Mail"
                    labelPlacement="end"
                    control={
                      <Checkbox
                        value="uspsmail"
                        checked={formik.values.notificationVia.includes(
                          "uspsmail"
                        )}
                        name="notificationVia"
                        onChange={formik.handleChange}
                      />
                    }
                  />
                </Grid>
              </Grid>
            </div>
          </Tab>
          <Tab
            eventKey="alerts"
            title={
              <div className="tab-header">
                <i className="fa fa-bell"></i>
                <span className="m-l-5">Alert Overview</span>
              </div>
            }
          >
            <AlertMarketCordStaff userInfo={data} />
          </Tab>
        </Tabs>
      </Stack>
      {showModal && (
        <ModalComponent
          shown={showModal}
          close={() => {
            setShowModal(false);
          }}
          title={"NOTE(S)"}
          modalBody={renderModal}
          size={"lg"}
          id={"comment-modal"}
          label={"NOTE(S)"}
        />
      )}
      {showVerifyModal && (
        <ModalComponent
          shown={showVerifyModal}
          close={() => {
            setShowVerifyModal(false);
          }}
          title={"APPROVE MARKET CO-ORDINATOR"}
          modalBody={renderVerifyModal}
          size={"md"}
          label={"APPROVE MARKET CO-ORDINATOR"}
        />
      )}

      {showDenyModal && (
        <ModalComponent
          shown={showDenyModal}
          close={() => {
            setShowDenyModal(false);
          }}
          title={"DENY MARKET CO-ORDINATOR"}
          modalBody={renderDenyModal}
          size={"md"}
          label={"DENY MARKET CO-ORDINATOR"}
        />
      )}
    </>
  );
}

export default MarketDetails;
