import React,{useEffect,useState} from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment';
import { mapStateToProps } from "../../../redux/reducers";
import {
  Button,
  Grid,
  Typography,
  Stack
} from "@mui/material";
import { CustomButton } from '../../common/components/customButton';
import SearchComponent from "../../common/components/searchComponent";
import TableComponent from "../../common/components/table";
import * as actionTypes from "../../../redux/actionTypes/csaSignup";
import {useSearchParams } from "react-router-dom";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseIcon from '@mui/icons-material/Close';
import DatePickerComponent from "../../common/components/dateRangePicker";
import { CalculateUserTzToUTCTz, popUpAlert } from "../../../utils/globalMethods";
import SelectDropdown from "../../common/selectdropdown-component";
import ClientDetails from "./clientDetails";
import useLanguageCode from "../../../hooks/useLanguageCode";

function createData(
  id,
  setId,
  vendorId,
  chosenNam,
  shareName,
  cost,
  apprvlStatus,
  csaShareStatus,
  addressId,
  paymentType,
  startDate,
  endDate,
  submittedDate,
  agrmntAppId,
  clientEmail,
  lastUpdtUidNam,
  phoneNumber,
  dsc,
  address1,
  address2,
  city,
  state,
  zipcode,
  day,
  openTime,
  closeTime,
  shareId,
  clientNam,
  row
) {
  return {
    id,
    setId,
    vendorId,
    chosenNam,
    shareName,
    cost,
    apprvlStatus,
    csaShareStatus,
    addressId,
    paymentType,
    startDate,
    endDate,
    submittedDate,
    agrmntAppId,
    clientEmail,
    lastUpdtUidNam,
    phoneNumber,
    dsc,
    address1,
    address2,
    city,
    state,
    zipcode,
    day,
    openTime,
    closeTime,
    // Deliveryaddress1,
    // Deliveryaddress2,
    // Deliverycity,
    // Deliverystate,
    // Deliveryzipcode,
    clientNam,
    shareId,
    row,
  };
}

const ClientAgreementTab = (props) => {
  const languageCode = useLanguageCode();

  const { user } = props.userAuth;
  //const [vendorId,setVendorId] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [agreementListTotal, setAgreementListTotal] = useState([0]);
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [size, setSize] = useState(10);
  const [from, setFrom] = useState(0);
  const [searchParams] = useSearchParams();
  let vendorId = searchParams.get("vendor_id");
  const [search, setSearch] = useState("");
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [selectAll, setSelectAll] = React.useState(false);
  const [expand] = useState(false);
  const [tableSort, setTableSort] = useState({ lastUpdtTsSort: "desc" });
  const [status, setStatus] = useState("");
  const [selectedStatus, setSelectedStatus] = useState({
    name: "",
    values: "All Status",
    isActive: 1,
  });
  const [selectedLocation, setSelectLocation] = useState({
    name: "",
    values: "All ",
    isActive: 1,
  });
  const [statusList] = useState([
    { name: "", values: "All status", isActive: 1 },
    { name: "APPRVD", values: "Approved", isActive: 1 },
    { name: "CANCLD", values: "Cancelled", isActive: 1 },
    { name: "DRAFT", values: "Pending", isActive: 1 },
    { name: "REJCTD", values: "Rejected", isActive: 1 },
  ]);
  const [addressList, setAddressList] = useState();
  const [addressId, setAddressId] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [clientAgreementList, setClientAgreementList] = useState([]);
  const [startDate] = useState("");
  const [endDate] = useState("");
  const [resetDate, setResetDate] = useState(false);
  const [dateRangePickerLabel] = useState("Select Date");
  const [filterData, setFilterData] = useState({
    startDate: null,
    endDate: null,
  });

  const filterHandleChange = (date) => {
    setFilterData({
      ...filterData,
      endDate: date?.[0]?.endDate
        ? CalculateUserTzToUTCTz(date?.[0]?.endDate)
        : null,
      startDate: date?.[0]?.startDate
        ? CalculateUserTzToUTCTz(date?.[0]?.startDate)
        : null,
    });
  };

  if (!vendorId && props.userAuth.user.vendorId) {
    vendorId = props.userAuth.user.vendorId;
  }

  useEffect(() => {
    const payload = {
      vendorId: vendorId,
      shareId: 0,
      addressId: addressId,
      addressType: "pickup",
      status: status,
      nam: search,
      size: size,
      from: from,
      shareStartDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      shareEndDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      // "shareStartDate": moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      // "shareEndDate": moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      submittedDateSort:
        "submittedDate" in tableSort ? tableSort["submittedDate"] : "",
      shareNamSort: "shareName" in tableSort ? tableSort["shareName"] : "",
      apprvlStatusSort:
        "csaShareStatus" in tableSort ? tableSort["csaShareStatus"] : "",
      clientNamSort: "clientNam" in tableSort ? tableSort["clientNam"] : "",
    };
    props.getClientAgreementList(payload);
  }, [
    props?.clientId,
    props.csaSignup.refreshKey,
    from,
    search,
    tableSort,
    status,
    addressId,
    filterData,
  ]);

  useEffect(() => {
    const payload2 = {
      vendorId: vendorId,
      type: "client",
      startDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "", // moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      endDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "", //moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      size: 1000,
      from: 0,
    };
    props.getPickupLocationList(payload2);
  }, [props.csaSignup.refreshKey, filterData]);

  useEffect(() => {
    if (props.csaSignup?.clientAgreementList?.clientAgreementList?.length) {
      setClientAgreementList(
        props?.csaSignup?.clientAgreementList?.clientAgreementList
      );
    } else {
      setClientAgreementList([]);
    }
  }, [props?.csaSignup?.clientAgreementList?.clientAgreementList]);

  useEffect(() => {
    const { list } = props.csaSignup.pickupLocationList;
    let dropdownAddress = [{ name: "", values: "All", isActive: 1 }];

    list.forEach((item) => {
      let formattedAddress = "";
      let addressId = "";
      addressId = item.addressId;
      formattedAddress += item.addressLine1 ? item.addressLine1 + ", " : "";
      formattedAddress += item.addressLine2 ? item.addressLine2 + ", " : "";
      formattedAddress += item.city ? item.city + ", " : "";
      formattedAddress += item.state ? item.state + ", " : "";
      formattedAddress += item.zipcode ? item.zipcode : "";
      if (formattedAddress != "")
        dropdownAddress.push({
          name: addressId,
          values: formattedAddress,
          isActive: 1,
        });
    });
    setAddressList(dropdownAddress);
  }, [props.csaSignup.pickupLocationList]);

  useEffect(() => {
    const { list, total, loading } = props?.csaSignup?.clientAgreementList;
    let data = [];
    // setRows([]);
    setTimeout(() => {
      list.forEach((element) => {
        data.push(
          createData(
            element.id,
            element.setId,
            element.vendorId,
            element.chosenNam,
            element?.shareInfo?.shareNam,
            element?.shareInfo?.shareCost,
            element?.apprvlStatus,
            element?.shareInfo?.csaShareStatus,
            element?.shareInfo?.pickupInfo?.addressId,
            element?.paymentType,
            element?.shareInfo?.startDate,
            element?.shareInfo?.endDate,
            element?.submittedDate,
            element?.agrmntAppId,
            element?.clientEmail,
            element?.lastUpdtUidNam,
            element?.phoneNumber,
            element?.shareInfo?.dsc,
            element?.shareInfo?.pickupInfo?.address1
              ? element?.shareInfo?.pickupInfo?.address1
              : element?.shareInfo?.deliveryInfo?.address1,
            element?.shareInfo?.pickupInfo?.address2
              ? element?.shareInfo?.pickupInfo?.address2
              : element?.shareInfo?.deliveryInfo?.address2,
            element?.shareInfo?.pickupInfo?.city
              ? element?.shareInfo?.pickupInfo?.city
              : element?.shareInfo?.deliveryInfo?.city,
            element?.shareInfo?.pickupInfo?.state
              ? element?.shareInfo?.pickupInfo?.state
              : element?.shareInfo?.deliveryInfo?.state,
            element?.shareInfo?.pickupInfo?.zipcode
              ? element?.shareInfo?.pickupInfo?.zipcode
              : element?.shareInfo?.deliveryInfo?.zipcode,
            element?.shareInfo?.pickupInfo?.day,
            element?.shareInfo?.pickupInfo?.openTime,
            element?.shareInfo?.pickupInfo?.closeTime,
            element?.shareInfo?.id,
            element?.clientNam,
            element
          )
        );
      });
      setRows(data);
      handleColumns();
      setAgreementListTotal(total);
      setShowLoader(loading);
    }, 300);
  }, [props?.csaSignup?.clientAgreementList]);

  const handleRowClick = (e, row) => {
    var updatedSelectedRows = [];
    if (e.target.checked) {
      updatedSelectedRows = [...selectedRows, row];
    } else {
      updatedSelectedRows = selectedRows.filter((r) => r.id !== row.id);
    }
    setSelectAll(true);
    setSelectedRows(updatedSelectedRows);
  };

  const handleNext = (page, rowSize) => {
    size != rowSize && setSize(rowSize);
    setFrom(rowSize * page);
  };

  const handleSort = (item) => {
    let sortitem = {};
    if (item.sort == "" || item.sort == "DESC") {
      sortitem[item.id] = "ASC";
    } else {
      sortitem[item.id] = "DESC";
    }
    setTableSort(sortitem);
  };

  const handleSelectAll = (e) => {
    setSelectAll(e.target.checked);
    if (e.target.checked) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value.toLowerCase());
    setShowLoader(true);
    setFrom(0);
  };

  const handleReset = () => {
    console.log("BeforeReset", startDate, endDate);
    setFilterData("");
    setStatus(null);
    setSelectLocation({ name: "", values: "All ", isActive: 1 });
    setSelectedStatus({ name: "", values: "All Status", isActive: 1 });
    setSearch("");
    setTableSort({ lastUpdtTsSort: "desc" });
    setResetDate((preDate) => !preDate);
    setShowLoader(true);
  };

  const handleApproveStatus = (row) => {
    console.log(row);
    const payload = {
      id: row?.id,
      setId: row?.setId,
      shareId: row?.row?.shareInfo?.id,
      clientId: row?.row?.clientId,
      vendorId: vendorId,
      apprvlStatus: "APPRVD",
      paymentType: row?.paymentType,
      languageCode: languageCode,
    };
    props.updateApproveCsaSignupStatus(payload);
  };

  const handleApprove = (row) => {
    console.log(row);
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to approve this Client Agreement.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, approve it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleApproveStatus(row),
    });
  };
  const handleReject = (row) => {
    popUpAlert({
      title: "Are you sure?",
      text: "Do you want to reject this Client Agreement.",
      icon: "warning",
      showCancelButton: true,
      showConfirmButton: true,
      confirmButtonClass: "btn-danger",
      confirmButtonText: "Yes, reject it!",
      closeOnConfirm: false,
      onConfirmed: true,
      handleConfirm: () => handleRejectStatus(row),
    });
  };

  const handleRejectStatus = (row) => {
    const payload = {
      id: row?.id,
      setId: row?.setId,
      shareId: row?.row?.shareInfo?.id,
      clientId: row?.row?.clientId,
      vendorId: vendorId,
      apprvlStatus: "REJCTD",
      paymentType: row?.paymentType,
      languageCode: languageCode,
    };
    props.updateApproveCsaSignupStatus(payload);
  };

  const handleColumns = () => {
    const headers = [
      { id: "details", label: "Details" },
      {
        id: "clientNam",
        label: "Client Name",
        sort: "clientNam" in tableSort ? tableSort["clientNam"] : "",
        cell: (item) => {
          const fullName = item.clientNam || "";
          const nameArray = fullName.split(" ");
          const firstName = nameArray[0];
          let status = " ";
          // const chosenName = item?.chosenNam ? item.chosenNam : firstName;

          if (item.paymentType === "MNTHLY") {
            status = "Monthly";
            return (
              <>
                <p>{item.chosenNam || firstName}</p>
                {status && (
                  <span className="badge badge-primary">{status}</span>
                )}
              </>
            );
          } else {
            status = "Daily";
            return (
              <>
                <p>{item.chosenNam || firstName}</p>
                {status && <span className="badge badge-grey">{status}</span>}
              </>
            );
          }
        },
      },
      {
        id: "shareName",
        label: "Share Name",
        sort: "shareName" in tableSort ? tableSort["shareName"] : "",
        cell: (item) => {
          return (
            <>
              <div
                style={{
                  color: "#000",
                  fontSize: "20px",
                  textTransform: "capitalize",
                  fontFamily: "Texta-book",
                  variant: "text",
                }}
              >
                {item.row.shareNam}
              </div>
            </>
          );
        },
      },
      {
        id: "cost",
        label: "Amount",
        cell: (item) => {
          return (
            <>
              <div>{"$" + item.row.shareCost}</div>
            </>
          );
        },
      },
      {
        id: "csaShareStatus",
        label: "SPF Share Status",
        sort: "csaShareStatus" in tableSort ? tableSort["csaShareStatus"] : "",
        cell: (item) => {
          let status = "";
          if (item.apprvlStatus == "DRAFT") {
            status = "Pending ";
            return (
              <div
                style={{
                  color: "rgb(69, 111, 143)",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {status}
              </div>
            );
          } else if (item.apprvlStatus == "REJCTD") {
            status = "Rejected";
            return (
              <div
                style={{
                  color: "#dc3545",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "APPRVD") {
            status = "Approved";
            return (
              <div
                style={{
                  color: "#006e52",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                {status}{" "}
              </div>
            );
          } else if (item.apprvlStatus == "CANCLD") {
            status = "Cancelled";
            return (
              <div
                style={{
                  color: "#dc3545",
                  fontWeight: "bold",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {" "}
                {status}{" "}
              </div>
            );
          }
        },
      },
      {
        id: "startDate",
        label: "Share Start Date",
        cell: (item) => {
          const formattedDate = moment(item.startDate).format("MMMM YYYY"); //hh:mm A
          return <>{formattedDate}</>;
        },
      },
      {
        id: "endDate",
        label: "Share End Date",
        cell: (item) => {
          const formattedDate = moment(item.endDate).format("MMMM YYYY"); //hh:mm A
          return <>{formattedDate}</>;
        },
      },
      {
        id: "submittedDate",
        label: "Share Submitted Date",
        sort: "submittedDate" in tableSort ? tableSort["submittedDate"] : "",
        cell: (item) => {
          const formattedDate = moment(item.submittedDate).format(
            "MM/DD/YYYY "
          ); //hh:mm A
          return <>{formattedDate}</>;
        },
      },
      // {
      //   id: "action",
      //   label: "Action",
      //   cell: (item) => {
      //     if(item.apprvlStatus=="APPRVD"){

      //        return <div  style={{paddingLeft:"40px"}}> - </div>
      //        }
      //     if(item.apprvlStatus=="REJCTD" || item.apprvlStatus=="CANCLD" ){
      //       return <div style={{paddingLeft:"40px"}}>  - </div>  }
      //     else{
      //     return (
      //     <div style={{padding:"12px"}}>
      //        <div style={{margin:'8px 0px 8px 0px'}} >
      //         <Button variant="outlined" onClick={() => handleApprove(item)} color="success" startIcon={<DoneOutlinedIcon/>} style={{ textTransform: 'none', width:"100px"}}>Approve</Button>
      //       </div>
      //       <div style={{margin:'8px 0px 8px 0px'}}>
      //         <Button variant="outlined" onClick={() => handleReject(item)} color="error" startIcon={<CloseIcon/>} style={{ textTransform: 'none', width:"100px" }}>Reject</Button>
      //       </div>
      //     </div>
      //     )
      //     }
      //   }
      // }
    ];
    if (user?.Role !== "HIPCRD") {
      headers.push({
        id: "action",
        label: "Action",
        cell: (item) => {
          if (item.apprvlStatus == "APPRVD") {
            return <div style={{ paddingLeft: "40px" }}> - </div>;
          }
          if (item.apprvlStatus == "REJCTD" || item.apprvlStatus == "CANCLD") {
            return <div style={{ paddingLeft: "40px" }}> - </div>;
          } else {
            return (
              <div style={{ padding: "12px" }}>
                <div style={{ margin: "8px 0px 8px 0px" }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleApprove(item)}
                    color="success"
                    startIcon={<DoneOutlinedIcon />}
                    style={{ textTransform: "none", width: "100px" }}
                  >
                    Approve
                  </Button>
                </div>
                <div style={{ margin: "8px 0px 8px 0px" }}>
                  <Button
                    variant="outlined"
                    onClick={() => handleReject(item)}
                    color="error"
                    startIcon={<CloseIcon />}
                    style={{ textTransform: "none", width: "100px" }}
                  >
                    Reject
                  </Button>
                </div>
              </div>
            );
          }
        },
      });
    }
    setColumns(headers);
  };
  //   ];
  //     if( !user.Role === "HIPCRD"){
  //       headers.push ({
  //         id: "action",
  //         label: "Action",
  //         cell: (item) => {
  //           if(item.apprvlStatus=="APPRVD"){

  //             return <div  style={{paddingLeft:"40px"}}> - </div>
  //             }
  //           if(item.apprvlStatus=="REJCTD" || item.apprvlStatus=="CANCLD" ){
  //             return <div style={{paddingLeft:"40px"}}>  - </div>  }
  //           else{
  //           return (
  //           <div style={{padding:"12px"}}>
  //             <div style={{margin:'8px 0px 8px 0px'}} >
  //               <Button variant="outlined" onClick={() => handleApprove(item)} color="success" startIcon={<DoneOutlinedIcon/>} style={{ textTransform: 'none', width:"100px"}}>Approve</Button>
  //             </div>
  //             <div style={{margin:'8px 0px 8px 0px'}}>
  //               <Button variant="outlined" onClick={() => handleReject(item)} color="error" startIcon={<CloseIcon/>} style={{ textTransform: 'none', width:"100px" }}>Reject</Button>
  //             </div>
  //           </div>
  //           )
  //           }
  //         }
  //       });
  //     }
  // ];

  //   setColumns(headers);
  // };

  const renderDetails = (row) => {
    return <ClientDetails row={row} />;
  };
  const exportData = () => {
    const payload = {
      vendorId: vendorId,
      nam: search,
      shareId: 0,
      addressId: addressId,
      status: status,
      addressType: "pickup",
      // "shareStartDate": moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY"),
      // "shareEndDate": moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY"),
      shareStartDate: filterData.startDate
        ? moment(filterData.startDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      ShareEndDate: filterData.endDate
        ? moment(filterData.endDate.unix() * 1000).format("MM/DD/YYYY")
        : "",
      size: 1000,
      from: 0,
    };
    props.getExportClientAgreementList(payload);
  };

  return (
    <>
      <Grid container className="container-block" rowSpacing={2}>
        <Grid
          container
          xs={12}
          sx={{ backgroundColor: "#DBE8DF" }}
          direction={"row"}
        >
          <Grid container item columnSpacing={{ xs: 1, sm: 2, md: 3 }} p={2}>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
                By Start Date and End Date
              </Typography>
              <DatePickerComponent
                label={dateRangePickerLabel}
                selectedDateObj={(date) => {
                  filterHandleChange(date, "", "date");
                }}
                resetDate={resetDate}
              />
              {/* label={`${moment(CalculateUTCTzToUserTz(filterData.startDate)).format("MM-DD-YYYY")} - ${moment(CalculateUTCTzToUserTz(filterData.endDate)).format("MM-DD-YYYY")}`}  */}
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
                By Pickup Location
              </Typography>
              <SelectDropdown
                id="statusFilter"
                value={selectedLocation}
                isSearch={true}
                style={{ width: "75%" }}
                variant="outlined"
                options={addressList}
                onChange={(address) => {
                  setAddressId(address.name);
                  setSelectLocation(address);
                  setShowLoader(true);
                }}
              ></SelectDropdown>
            </Grid>
            <Grid item xs={6} md={3}>
              <Typography variant="subtitle1" sx={{ fontFamily: "texta-bold" }}>
                By Status
              </Typography>
              <SelectDropdown
                id="shareFilter"
                value={selectedStatus}
                isSearch={true}
                style={{ width: "75%" }}
                variant="outlined"
                options={statusList}
                onChange={(item) => {
                  setStatus(item.name);
                  setSelectedStatus(item);
                  setShowLoader(true);
                }}
              ></SelectDropdown>
            </Grid>
            <Grid container item xs="auto" p={3} alignItems={"center"}>
              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <Grid item>
                  <CustomButton
                    variant="outlined"
                    sx={{ fontWeight: "bold" }}
                    startIcon={
                      <i className="fa fa-refresh" aria-hidden="true"></i>
                    }
                    onClick={handleReset}
                  >
                    Reset
                  </CustomButton>
                </Grid>
                <Grid item>
                  <CustomButton
                    variant="outlined"
                    sx={{ fontWeight: "bold" }}
                    onClick={exportData}
                    startIcon={
                      <i className="fa fa-download" aria-hidden="true"></i>
                    }
                  >
                    Export
                  </CustomButton>
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} direction={"row"}>
          <Grid container item xs={6} alignItems={"center"}></Grid>
          <Grid item xs={6} p={2} direction={"row"} justifyContent={"flex-end"}>
            <SearchComponent
              placeholder={"Name,AgreementID"}
              handleChange={handleSearch}
              searchText={search}
            />
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <TableComponent
            columns={columns}
            rows={rows}
            showDetails={true}
            expand={expand}
            renderDetails={renderDetails}
            handlePagination={handleNext}
            total={agreementListTotal}
            selectAll={selectAll}
            handleSelectAll={handleSelectAll}
            handleRowClick={handleRowClick}
            selectedRows={selectedRows}
            handleSort={handleSort}
            dataLoading={showLoader}
            page={Math.floor(from / size)}
            rowsPerPage={size}
          />
        </Grid>
      </Grid>
    </>
  );
};

ClientAgreementTab.propTypes = {
  getClientAgreementList: PropTypes.func.isRequired,
  getPickupLocationList: PropTypes.func.isRequired,
  getExportClientAgreementList: PropTypes.func.isRequired,
  csaSignup: PropTypes.object,
  userAuth:PropTypes.object,
  clientId:PropTypes.func.isRequired,
  updateApproveCsaSignupStatus: PropTypes.func.isRequired,
  exportClientAgreement: PropTypes.func.isRequired
}

function mapDispatchToProps(dispatch) {
  return {
    getClientAgreementList: (payload) => dispatch(actionTypes.getClientAgreementList(payload)),
    getExportClientAgreementList: (payload) => dispatch(actionTypes.getExportClientAgreementList(payload)),
    updateApproveCsaSignupStatus: (payload) => dispatch(actionTypes.updateApproveCsaSignupStatus(payload)),
    getPickupLocationList: (payload) => dispatch(actionTypes.getPickupLocationList(payload)),

  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientAgreementTab);
