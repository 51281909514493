import React from "react";
import '../../styles/home.scss'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import { GOOGLE_KEY } from "../../config";

const MapComponent = () => {
  // eslint-disable-next-line no-unused-vars
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: GOOGLE_KEY
  })

  const renderMap = () => {
    return(<GoogleMap
      id="googleMap"
      mapContainerStyle={{
        width: "100%",
        height: "calc(100vh - 140px)",
        minHeight: "350px",
      }}
      zoom={8}
      center={{
        lat: parseFloat(42.336521358972206),
        lng: parseFloat(-73.48502720423188),
      }}
      options={{
        zoomControl: false,
        draggable: false,
        scrollwheel: false,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
    >
    </GoogleMap>);
  }

    return isLoaded ? renderMap() : "";
}
  
export default MapComponent;